import {
  Box,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Spacer,
  Stack,
  StackDivider,
  Text,
  Image,
} from "@chakra-ui/react";
import { twofix } from "../../../helpers/utils/Utils";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useParams,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosInput,
  PosLable,
  PosDropDown,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import { employeesClockInOut } from "./EmployeesService";

const EmployeesEdit = (props) => {
  const { id } = useParams();
  const stateData = useLocation(); // get param data
  const [paramData, setParamData] = useState(stateData.state);
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [allTouchedField, setAllTouchedField] = useState(false);
  const [oldEmail, setoldEmail] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [clockInOutButtonDisable, setClockInOutButtonDisable] = useState(false);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const [location, setLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [clockInFlag, setClockInFlag] = useState(true);
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const actionRun = useRef(false);

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setRoles(loaderResponse.data.data.role);
          setoldEmail(loaderResponse.data?.data?.user?.email);
          const oldRoles = loaderResponse.data.data.user.roles.map((role) => {
            const foundRole = loaderResponse.data.data.role.find(
              (r) => r.name === role.name
            );
            return {
              name: role.name,
              display_name: foundRole ? foundRole.display_name : "",
            };
          });
          myContext.handleLoading(false);
          setSelectedRoles(oldRoles);
          formik.setFieldValue("selectedRoles", oldRoles);
          setLocation(loaderResponse.data?.data?.locations);
          let flag =
            loaderResponse.data.data.user_clock_in_flag === 1 ? true : false;
          setClockInFlag(flag);
          if (
            loaderResponse.data.data.work_shift_data &&
            loaderResponse.data.data.work_shift_data.location_id
          ) {
            const selectedLocationId =
              loaderResponse.data.data.work_shift_data.location_id;
            const foundLocation = loaderResponse.data?.data?.locations.find(
              (loc) => loc.id === selectedLocationId
            );
            if (foundLocation) {
              setSelectedLocation(foundLocation);
            }
          } else {
            setSelectedLocation([]);
          }
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        if(null != id && "" != id && null != user?.id && "" != user?.id){
          if(user?.id == id){
          var rolesname = "";
            if (
              undefined !== actionResponse.data.data.userData &&
              null !== actionResponse.data.data.userData
            ) {
              if (actionResponse.data.data.userData.roles.length > 0) {
                actionResponse.data.data.userData.roles.map((role, i) => {
                  if (actionResponse.data.data.userData.roles.length - 1 === i) {
                    rolesname = rolesname + role.display_name;
                  } else {
                    rolesname = rolesname + role.display_name + ",";
                  }
                });
              }
              let obj = {
                userName: actionResponse.data.data.userData.name,
                userRoles: rolesname,
              };
              myContext.handleUserData(obj);
            }
          }
        }
        // // This change for update employee name then local storage update.
        // let user = JSON.parse(localStorage.getItem("currentUser"));
        // if(null != id && "" != id && null != user?.id && "" != user?.id){
        //   if(user?.id == id){
        //     localStorage.setItem(
        //       "currentUser",
        //       JSON.stringify(actionResponse.data?.data?.user)
        //     );
        //   }
        // }
      if(undefined != paramData.reportFlag && null != paramData.reportFlag && paramData.reportFlag == "variable_item_report"){
        props.navigate(Constants.HR_EMPLOYEE_PATH);
      }else if(undefined != paramData.reportFlag && null != paramData.reportFlag && paramData.reportFlag == "extra_item_report"){
        props.navigate(Constants.HR_EMPLOYEE_PATH);
      }else{
        props.navigate(Constants.HR_EMPLOYEE_PATH, {
          state: paramData,
        });
      }
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.EMPLOYEES_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse, roles]);

  const formik = useFormik({
    initialValues: {
      name: loaderResponse.data?.data?.user?.name,
      email: loaderResponse.data?.data?.user?.email,
      selectedRoles: [],

      wage_per_hour:
        undefined != loaderResponse?.data?.data?.user?.wage_per_hour &&
        null != loaderResponse?.data?.data?.user?.wage_per_hour
          ? loaderResponse?.data?.data?.user?.wage_per_hour
          : "",
      pin: loaderResponse.data?.data?.user?.pin,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .matches(Constants.EMAIL_REGEX, Constants.USER_EMAIL_NOT_VALID)
        .required(Constants.USER_EMAIL_REQUIRED)
        .email(Constants.USER_EMAIL_NOT_VALID)
        .max(254, Constants.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER),
      name: Yup.string()
        .required(Constants.EMPLOYEES_NAME_REQUIRED)
        .max(199, Constants.EMPLOYEES_NAME_LESS_THEN_OR_EQUAL_255_CHARACTER),
      selectedRoles: Yup.array().min(1, Constants.EMPLOYEES_ROLE_REQUIRED),
      pin: Yup.string()
        .required(Constants.EMPLOYYEES_PIN_REQUIRED)
        .matches(/^[0-9]{6}$/, Constants.EMPLOYEES_PIN_MUST_BE_SIX_DIGIT),
      wage_per_hour: Yup.number()
        .typeError(Constants.WAGE_PER_HOUR_NOT_VALID)
        .min(0.01, Constants.WAGE_PER_HOUR_MIN_VALUE)
        .max(999999.99, Constants.WAGE_PER_HOUR_MAX_VALUE)
        .nullable(),
    }),
    onSubmit: (values) => {
      setButtonDisable(true);
      myContext.handleLoading(true);
      const roleNames = selectedRoles.map((role) => role.name);
      const payload = {
        ...values,
        id: id,
        role: JSON.stringify(roleNames),
        oldemail: oldEmail,
      };
      let path = Constants.HR_EMPLOYEE_PATH + id;
      actionRun.current = true;
      setAllTouchedField(false);
      submit(payload, {
        method: Constants.PUT,
        path: path,
      });
    },
  });
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values.name.length <= 0 ||
        formik.errors.name ||
        formik.errors.email ||
        formik.errors.pin ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };
  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedRoles", e.value);
    setSelectedRoles(e.value);
  };

  const employeeClockInOut = async () => {
    setClockInOutButtonDisable(true);
    if (selectedLocation && Object.keys(selectedLocation).length > 0) {
      let data = {
        user_id: id,
        location_id: selectedLocation.id,
        clock_in_flag: clockInFlag === true ? 1 : 0,
      };
      try {
        employeesClockInOut(data)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              addToast({
                alertStatus: Constants.TOAST_TYPE_SUCESS,
                alertTitle: Constants.EMPLOYEES_MODULE,
                alertDescription: response.data.message,
              });
              myContext.handleLoading(false);
              if (response.data.data.user_clock_in_flag === 1) {
                setClockInFlag(true);
              } else {
                setClockInFlag(false);
              }
              if (
                response.data.data.work_shift_data != undefined &&
                response.data.data.work_shift_data != null &&
                response.data.data.work_shift_data != ""
              ) {
                const selectedLocationId =
                  response.data.data.work_shift_data.location_id;
                const foundLocation = location.find(
                  (loc) => loc.id === selectedLocationId
                );
                if (foundLocation) {
                  setSelectedLocation(foundLocation);
                }
              } else {
                setSelectedLocation([]);
              }
              setClockInOutButtonDisable(false);
            } else {
              myContext.handleLoading(false);
              let actionData = response;
              error({ actionData });
            }
          })
          .catch((err) => {
            myContext.handleLoading(false);
            let actionData = err;
            error({ actionData });
          });
      } catch (error) {
        myContext.handleLoading(false);
      }
    } else {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.EMPLOYEES_MODULE,
        alertDescription: Constants.PRODUCT_LOCATION_REQUIRED,
      });
    }
    setClockInOutButtonDisable(false);
  };
  const handleCancelClick = () => {
    if (
      paramData.reportFlag !== undefined &&
      paramData.reportFlag === "extra_item_report"
    ) {
      props.navigate(Constants.EXTRA_ITEM_ROUTE, {
        state: paramData,
      });
    } else if (
      paramData.reportFlag !== undefined &&
      paramData.reportFlag === "variable_item_report"
    ) {
      props.navigate(Constants.VARIABLE_ITEM_ROUTE, {
        state: paramData,
      });
    } else {
      props.navigate(Constants.EMPOYEES_LIST_ROUTE, {
        state: paramData,
      });
    }
  };
  return (
    <Box padding={4}>
      <Card
        sx={{
          boxShadow: "none",
        }}
      >
        <CardHeader mt={{ base: 0, md: 10 }}>
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={"flex-start"}
          >
            <PosBreadCrumb
              handleClick={(i) => {
                props.navigate(Constants.EMPOYEES_LIST_ROUTE, {
                  state: null,
                });
              }}
              breadCrumNames={["Employees", "Edit Employees"]}
              breadCrumTitle={"Edit Employees"}
            />
            <Spacer />
            <Stack direction={"row"} alignSelf={"flex-end"}>
              <ButtonGroup>
                <PosFormButton
                  onClick={handleCancelClick}
                  buttonText={"Cancel"}
                  CancelButton={true}
                  isDisabled={buttonDisable}
                />
                <PosFormButton
                  // isDisabled={
                  //   formik.errors.name ||
                  //   formik.errors.wage_per_hour ||
                  //   formik.errors.selectedRoles ||
                  //   formik.errors.email ||
                  //   formik.errors.pin ||
                  //   buttonDisable
                  //     ? true
                  //     : false
                  // }
                  isDisabled={buttonDisable}
                  buttonsubmit={"Submit"}
                  SubmitButton={true}
                  // onClick={formik.handleSubmit}
                  onClick={() => {
                    // submit time all validation fired and error massage display.
                    if (!formik.isValid) {
                      if (!allTouchedField) {
                        formik.handleSubmit();
                        setAllTouchedField(true);
                      }
                    } else {
                      formik.handleSubmit();
                    }
                  }}
                />
              </ButtonGroup>
            </Stack>
          </Flex>
        </CardHeader>
        <Divider borderColor="#E6E6E6" mt={{ base: 4, md: 8 }} />
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Box w={{ base: "100%", md: "35%" }} ml={{ base: 0, md: 6 }}>
                  <Heading
                    as="h3"
                    size="xs"
                    fontWeight="500"
                    fontSize="1.125rem"
                    lineHeight="1.40625rem"
                    letterSpacing="-0.016875rem"
                    color="#010048"
                  >
                    Employee Information
                  </Heading>
                  <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                    Add contact information to complete your employee details
                  </Text>
                </Box>
                <Box w={{ base: "100%", md: "55%" }} mt={{ base: 2, md: 0 }}>
                  <Text fontSize={"0.94rem"} fontWeight={500}>
                    Name
                    <Text as="span" color={Colors.errorColor}>
                      *
                    </Text>
                  </Text>
                  <PosInput
                    posInputGroup={true}
                    id={"name"}
                    inputType={"text"}
                    placeholder={"Joshua Thompson"}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.name}
                    handleBlur={(e) => {
                      formik.setFieldValue("name", formik.values.name.trim());
                      formik.handleBlur(e);
                    }}
                    onKeyDown={handleKeyDown}
                    inputError={formik.touched.name && formik.errors.name}
                  >
                    <DriveFileRenameOutlineIcon
                      style={{ fill: Colors.primeTaxInfo }}
                    />
                  </PosInput>
                  <Text
                    color={Colors.errorColor}
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    {formik.touched.name && formik.errors.name
                      ? formik.errors.name
                      : null}
                  </Text>
                  <PosLable
                    fontWeight={500}
                    name={"Email Address"}
                    requiredLabel={true}
                  ></PosLable>
                  <PosInput
                    posInputGroup={true}
                    id={"email"}
                    inputType={"email"}
                    placeholder={"Joshuathompson@example.com"}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.email}
                    handleBlur={(e) => {
                      formik.setFieldValue("email", formik.values.email.trim());
                      formik.handleBlur(e);
                    }}
                    inputError={formik.touched.email && formik.errors.email}
                  >
                    <DriveFileRenameOutlineIcon
                      style={{ fill: Colors.primeTaxInfo }}
                    />
                  </PosInput>
                  <Text
                    color={Colors.errorColor}
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    {formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : null}
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Box w={{ base: "100%", md: "35%" }} ml={{ base: 0, md: 6 }}>
                  <Heading
                    as="h3"
                    size="xs"
                    fontWeight="500"
                    fontSize="1.125rem"
                    lineHeight="1.41rem"
                    letterSpacing="-0.017rem"
                    color="#010048"
                  >
                    Role and Rates
                  </Heading>
                  <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                    Assign roles to define what members can do and include their
                    compensation
                  </Text>
                </Box>
                <Box w={{ base: "100%", md: "55%" }} mt={{ base: 2, md: 0 }}>
                  <Flex flexDirection={"row"}>
                    <Text fontSize={"0.94rem"} fontWeight={500}>
                      Role
                      <Text as="span" color={Colors.errorColor}>
                        *
                      </Text>
                    </Text>
                    <Tippy
                      content="These roles are made from HR-Roles. they set the parameters for the employee's access and powers."
                      interactive={true}
                      maxWidth={350}
                      placement="top"
                      animation="fade"
                      inertia={true}
                      moveTransition="transform 0.2s ease-out"
                      theme="tomato"
                    >
                      <Image
                        src={toolTip}
                        alt="Tooltip"
                        ml={{ base: "0rem", md: "0.3rem" }}
                      />
                    </Tippy>
                  </Flex>
                  {/* <Box className="card justify-content-center"> */}
                  <PosDropDown
                    multiSelect={true}
                    value={selectedRoles}
                    resetFilterOnHide={true}
                    // onChange={(e) => setSelectedRoles(e.value)}
                    onChange={handleOptionSelect}
                    onBlur={(e) => {
                      formik.setFieldTouched("selectedRoles");
                      formik.handleBlur(e);
                    }}
                    options={roles}
                    optionLabel="display_name"
                    placeholder="Select Roles"
                    width="100%"
                    className="w-full md:w-20rem"
                  />
                  <Text
                    color="red"
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    {formik.touched.selectedRoles && formik.errors.selectedRoles
                      ? formik.errors.selectedRoles
                      : null}
                  </Text>
                  <PosLable
                    fontWeight={500}
                    name={"Wages per hour"}
                    label={true}
                  ></PosLable>
                  <PosInput
                    posInput={true}
                    id={"wage_per_hour"}
                    inputType={"text"}
                    inputValue={formik.values.wage_per_hour}
                    handleBlur={(e) => {
                      formik.handleBlur(e);
                      formik.setFieldValue(
                        "wagesPerHour",
                        twofix(e.target.value)
                      );
                    }}
                    precision={2}
                    handleInputChange={formik.handleChange}
                    inputError={formik.errors.wage_per_hour}
                  ></PosInput>
                  <Text
                    color={Colors.errorColor}
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    {formik.errors.wage_per_hour
                      ? formik.errors.wage_per_hour
                      : null}
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Box w={{ base: "100%", md: "35%" }} ml={{ base: 0, md: 6 }}>
                  <Heading
                    as="h3"
                    size="xs"
                    fontWeight="500"
                    fontSize="1.125rem"
                    lineHeight="1.41rem"
                    letterSpacing="-0.016875rem"
                    color="#010048"
                  >
                    Security
                  </Heading>
                  <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                    Set a pin to enable your employees log into the POS and
                    manage clock in and clock out functions
                  </Text>
                </Box>
                <Box w={{ base: "100%", md: "55%" }} mt={{ base: 2, md: 0 }}>
                  <Flex flexDirection={"row"}>
                    <Text fontSize={"0.94rem"} fontWeight={500}>
                      Pin Code
                      <Text as="span" color={Colors.errorColor}>
                        *
                      </Text>
                    </Text>
                    <Tippy
                      content="This pin would be used to log in to the POS as well as for the employee's Clock in and clock-out functions."
                      interactive={true}
                      maxWidth={350}
                      placement="top"
                      animation="fade"
                      inertia={true}
                      moveTransition="transform 0.2s ease-out"
                      theme="tomato"
                    >
                      <Image
                        src={toolTip}
                        alt="Tooltip"
                        ml={{ base: "0rem", md: "0.3rem" }}
                      />
                    </Tippy>
                  </Flex>
                  <PosInput
                    posInput={true}
                    id={"pin"}
                    inputType={"text"}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.pin}
                    handleBlur={(e) => {
                      formik.setFieldValue("pin", formik.values.pin.trim());
                      formik.handleBlur(e);
                    }}
                    onKeyDown={handleKeyDown}
                    inputError={formik.touched.pin && formik.errors.pin}
                  ></PosInput>
                  <Text
                    color={Colors.errorColor}
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    {formik.touched.pin && formik.errors.pin
                      ? formik.errors.pin
                      : null}
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Box w={{ base: "100%", md: "35%" }} ml={{ base: 0, md: 6 }}>
                  <Heading
                    as="h3"
                    size="xs"
                    fontWeight="500"
                    fontSize="1.125rem"
                    lineHeight="1.41rem"
                    letterSpacing="-0.016875rem"
                    color={Colors.posTextInfo}
                  >
                    Clock In/Out
                  </Heading>
                  <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                  Manage clock in and clock out of your employee on specific location
                  </Text>
                </Box>
                <Box w={{ base: "100%", md: "55%" }} mt={{ base: 2, md: 0 }}>
                  <Text color={Colors.posTextInfo} fontWeight="500">
                    Location
                  </Text>
                  <PosDropDown
                    value={selectedLocation}
                    filter
                    onChange={(e) => {
                      if (undefined !== e.value) {
                        setSelectedLocation(e.value);
                        setButtonDisable(false);
                      }
                    }}
                    onBlur={() => formik.handleBlur("selectedLocation")}
                    options={location}
                    optionLabel={"name"}
                    className="w-full md:w-20rem"
                    width={"100%"}
                    placeholder={"Make a selection"}
                    disabled={!clockInFlag}
                  ></PosDropDown>
                  <Box mt={2}>
                    <PosFormButton
                      isDisabled={clockInOutButtonDisable ? true : false}
                      buttonsubmit={clockInFlag ? "Clock In" : "Clock Out"}
                      width={"7.37rem"}
                      onClick={employeeClockInOut}
                    />
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
};
export default WithRouter(EmployeesEdit);
