import { CheckIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Divider,
  Select,
} from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosCountryStateDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosListHeader,
  PosTab,
  PosTostMessage,
  PosDropDown,
  PosProgress,
  PosAlert,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import warnignIcon from "../../../assets/images/Ellipse3.svg";
import { ProfileEditPassword } from "../../../pages/core/index";
import {
  getAllCountriesState,
  getProfileDetails,
  getStateListForSelectedCountry,
} from "./ProfileService";

const ProfileEdit = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const [empId, setEmpId] = useState("");
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [countries, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [stateAvailable, setStatesAvailability] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [curruntTab, setCurruntTab] = useState(0);
  const [oldEmail, setoldEmail] = useState("");
  const tabNames = ["Personal Information", "Account Security"];
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [previousSelectedCountry, setPreviousSelectedCountry] = useState('');

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setEmpId(loaderResponse.data?.data?.employee?.id);
          const arrayCountries = Object.keys(
            loaderResponse.data.data.countries
          ).map((key) => ({
            label: loaderResponse.data.data.countries[key],
            value: key,
          }));
          setCountry(arrayCountries);
          if (
            loaderResponse?.data?.data?.employee?.country &&
            null != loaderResponse?.data?.data?.employee?.country
          ) {
            setSelectedCountry(loaderResponse?.data?.data?.employee?.country);
            setStatesAvailability(0);
          }
          if (
            undefined !== loaderResponse.data.data.states &&
            null !== loaderResponse.data.data.states &&
            "" !== loaderResponse.data.data.states
          ) {
            const arrayStates = Object.keys(
              loaderResponse.data.data.states
            ).map((key) => ({
              label: loaderResponse.data.data.states[key],
              value: key,
            }));
            setStates(arrayStates);
          }
          if (
            loaderResponse?.data?.data?.employee?.stateCode &&
            null != loaderResponse?.data?.data?.employee?.stateCode &&
            "N.A." != loaderResponse?.data?.data?.employee?.stateCode
          ) {
            setStatesAvailability(0);
            setSelectedState(loaderResponse?.data?.data?.employee?.stateCode);
          }
          myContext.handleLoading(false);
        }
      }
      effectRun.current = false;
    }
    
  }, [loaderResponse]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        // This change for update employee name then local storage update.
        let user = JSON.parse(localStorage.getItem("currentUser"));
        if(null != empId && "" != empId && null != user?.id && "" != user?.id){
          if(user?.id == empId){
            let obj = {
              userName: actionResponse.data?.data?.employee?.name,
              // userRoles: rolesname,
            };
            myContext.handleUserData(obj);
          }
        }
        // myContext.handleLoading(false);
        // // This change for update employee name then local storage update.
        // let user = JSON.parse(localStorage.getItem("currentUser"));
        // if(null != empId && "" != empId && null != user?.id && "" != user?.id){
        //   if(user?.id == empId){
        //     localStorage.setItem(
        //       "currentUser",
        //       JSON.stringify(actionResponse.data?.data?.employee)
        //     );
        //   }
        // }
        const state = actionResponse?.data?.data?.state;
        if (state === 'profile') {
          props.navigate(Constants.PROFILE_EDIT);
        } else if (state === 'password') {
          props.navigate(Constants.LOGOUT_PAGE);
        } else {
          console.error('Unknown state:', state);
        }

        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.PROFILE_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    effectRun.current = true;
    setLoading(true);
  };

  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      effectRun.current = true;
      setLoading(true);
    } else {
      effectRun.current = true;
      setLoading(true);
    }
  };
  
  useEffect(() => {
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        // getProfileDetails();
      } else {
      }
      effectRun.current = false;
    }
  });

  const formik = useFormik({
    initialValues: {
      id: loaderResponse?.data?.data?.employee?.id,
      name:
        undefined !== loaderResponse?.data?.data?.employee?.name &&
        null != loaderResponse?.data?.data?.employee?.name
          ? loaderResponse?.data?.data?.employee?.name
          : "",
      businessName:
        undefined !== loaderResponse?.data?.data?.employee?.business_name &&
        null != loaderResponse?.data?.data?.employee?.business_name
          ? loaderResponse?.data?.data?.employee?.business_name
          : "",
      phone:
        undefined !== loaderResponse?.data?.data?.employee?.phone &&
        null != loaderResponse?.data?.data?.employee?.phone
          ? loaderResponse?.data?.data?.employee?.phone
          : "",
      email:
        undefined !== loaderResponse?.data?.data?.employee?.email &&
        null != loaderResponse?.data?.data?.employee?.email
          ? loaderResponse?.data?.data?.employee?.email
          : "",
      address1:
        undefined !== loaderResponse?.data?.data?.employee?.address1 &&
        null != loaderResponse?.data?.data?.employee?.address1
          ? loaderResponse?.data?.data?.employee?.address1
          : "",
      address2:
        undefined !== loaderResponse?.data?.data?.employee?.address2 &&
        null != loaderResponse?.data?.data?.employee?.address2
          ? loaderResponse?.data?.data?.employee?.address2
          : "",
      city:
        undefined !== loaderResponse?.data?.data?.employee?.city &&
        null != loaderResponse?.data?.data?.employee?.city
          ? loaderResponse?.data?.data?.employee?.city
          : "",
      zipCode:
        undefined !== loaderResponse?.data?.data?.employee?.zip &&
        null != loaderResponse?.data?.data?.employee?.zip
          ? loaderResponse?.data?.data?.employee?.zip
          : "",
      // country: "",
      // state: "",
      country:
        undefined !== loaderResponse?.data?.data?.employee?.country &&
        null != loaderResponse?.data?.data?.employee?.country
          ? loaderResponse?.data?.data?.employee?.country
          : "",
      state:
        undefined !== loaderResponse?.data?.data?.employee?.state &&
        null != loaderResponse?.data?.data?.employee?.state
          ? loaderResponse?.data?.data?.employee?.state
          : "",

      country_code:
        undefined !== loaderResponse?.data?.data?.employee?.country_code &&
        null != loaderResponse?.data?.data?.employee?.country_code
          ? loaderResponse?.data?.data?.employee?.country_code
          : "",
      selectedState:
        undefined !== loaderResponse?.data?.data?.employee?.stateCode &&
        null != loaderResponse?.data?.data?.employee?.stateCode
          ? loaderResponse?.data?.data?.employee?.stateCode
          : "",
      selectedCountry:
        undefined !== loaderResponse?.data?.data?.employee?.country &&
        null != loaderResponse?.data?.data?.employee?.country
          ? loaderResponse?.data?.data?.employee?.country
          : "",
      crt_password: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(Constants.USER_NAME_REQUIRED)
        .max(191, Constants.USER_NAME_LENGTH_GREATER_THAN_191),
      businessName: Yup.string()
        // .required(Constants.USER_BUSINESS_NAME_REQUIRED)
        .max(191, Constants.USER_BUSINESS_NAME_LENGTH_GREATER_THAN_191),
      address1: Yup.string()
        .required(Constants.USER_ADDRESS_NAME_REQUIRED)
        .max(5000, Constants.USER_ADDRESS_LENGTH_GREATE_THEN_5000),
      address2: Yup.string().max(
        5000,
        Constants.USER_ADDRESS_LENGTH_GREATE_THEN_5000
      ),
      city: Yup.string()
        .required(Constants.USER_PLEASE_ENTER_A_CITY)
        .max(191, Constants.USER_CITY_LENGTH_GREATE_THEN_191),
      phone: Yup.string()
        .required(Constants.USER_PLEASE_ENTER_A_PHONE_NUMBER)
        .max(15, Constants.USER_PHONE_NUMBER_MUST_BE_LESS_THAN_15_DIGITS_LONG)
        .min(2, Constants.USER_PHONE_NUMBER_MUST_BE_GREATER_THAN_2_DIGITS_LONG)
        .matches(/^\d*$/, Constants.THIS_IS_NOT_A_VALID_PHONE_NUMBER),
      email: Yup.string().trim()
        .required(Constants.USER_EMAIL_REQUIRED)
        .email(Constants.USER_EMAIL_NOT_VALID)
        .max(191, Constants.CUSTOMER_EMAIL_LENGTH_GREATE_THEN_191)
        .matches(Constants.EMAIL_REGEX, Constants.VENDOR_EMAIL_NOT_VALID),
      zipCode: Yup.string()
        .required(Constants.USER_ZIP_CODE__REQUIRED)
        .min(2, Constants.USER_ZIP_CODE_MUST_BE_GREATER_THAN_2_DIGITS_LONG)
        .max(11, Constants.USER_ZIP_CODE_MUST_BE_LESS_THAN_11_DIGITS_LONG)
        .matches(/^\d*$/, Constants.THIS_IS_NOT_A_VALID_ZIPCODE),
      // country: Yup.string().required(
      //   Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY
      // ),
      // state: Yup.string().required(Constants.CUSTOMER_PLEASE_SELECT_A_STATE),
      country_code: Yup.string()
        .required(Constants.USER_COUNTRY_CODE__REQUIRED)
        .max(4, Constants.COUNTRY_CODE_MUST_BE_LESS_THAN_4_DIGITS_LONG)
        .matches(/^\d*$/, Constants.THIS_IS_NOT_A_VALID_COUNTRY_CODE),
      crt_password: Yup.string().min(
        8,
        Constants.PASSWORD_MUST_BE_8_CHARACHTER
      ),
      // .required(Constants.PASSWORD_FIELD_REQUIRED)
      // .oneOf(
      //   [Yup.ref(Constants.PASSWORD)],
      //   Constants.YOU_ENTERED_THE_WRONG_PASSWORD
      // )
      password: Yup.string()
        .min(8, Constants.PASSWORD_MUST_BE_8_CHARACHTER)
        // .required(Constants.PASSWORD_FIELD_REQUIRED)
        .oneOf(
          [Yup.ref(Constants.PASSWORD)],
          Constants.YOU_ENTERED_THE_WRONG_PASSWORD
        ),
      confirm_password: Yup.string()
        .min(8, Constants.PASSWORD_MUST_BE_8_CHARACHTER)
        // .required(Constants.PASSWORD_FIELD_REQUIRED)
        .oneOf(
          [Yup.ref(Constants.PASSWORD)],
          Constants.YOU_ENTERED_THE_WRONG_PASSWORD
        )
        .oneOf([Yup.ref("password")], Constants.SAME_PASSWORD),
      selectedCountry: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY
      ),
      selectedState: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_STATE
      ),
    }),
    onSubmit: (values) => {
      myContext.handleLoading(true);
      const employee = {
        id: formik.values.id,
        name: formik.values.name,
        business_name: formik.values.businessName,
        phone: formik.values.phone,
        email: formik.values.email,
        address1: formik.values.address1,
        address2: formik.values.address2,
        zip: formik.values.zipCode,
        city: formik.values.city,
        oldemail: oldEmail,
        country_code: formik.values.country_code,
        country: selectedCountry,
        state: selectedState,
        stateNotAvailable: stateAvailable,
        crt_password: formik.values.crt_password,
        password: formik.values.password,
        confirm_password: formik.values.confirm_password,
      };
      let payloadState = "profile";
      if (curruntTab === 1) {
        employee.crt_password = formik.values.crt_password;
        employee.password = formik.values.password;
        employee.confirm_password = formik.values.confirm_password;
        payloadState = "password";
      } else {
      }

      myContext.handleLoading(true);
      const payload = {
        id: formik.values.id,
        // employee: employee,
        employee: JSON.stringify(employee),
        states: payloadState,
        country: selectedCountry,
        state: selectedState,
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.PROFILE_DETAIL_API_ROUTE + Constants.USER_API_ID,
        payloadState: payloadState,
      });
    },
  });

  const handleSelectedState = (value) => {
    setSelectedState(value);
    if (value == "N.A") {
      setStatesAvailability(1);
    } else {
      setStatesAvailability(0);
    }
  };

  const handleSelectedCountry = (value) => {
    try {
      setSelectedCountry(value);
      setStates([]);
      setSelectedState(null);
      setStateError("");
      formik.setFieldValue("selectedState", null);

      myContext.handleLoading(true);
      getStateListForSelectedCountry(value)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            const statesData = response.data.data.states;
            const arrayStates = Object.keys(statesData).map((key) => ({
              label: statesData[key],
              value: key,
            }));
            setStates(arrayStates);
            const customerState =
              loaderResponse?.data?.data?.customerData?.state;
            if (
              customerState &&
              customerState !== "N.A" &&
              customerState !== null &&
              statesData[customerState]
            ) {
              if(loaderResponse?.data?.data?.employee?.country !== previousSelectedCountry){
                setSelectedState(customerState); 
              }else if (loaderResponse?.data?.data?.employee?.country === previousSelectedCountry){
                setSelectedState("");
              }
              setPreviousSelectedCountry(loaderResponse?.data?.data?.employee?.country);
              // setSelectedState(customerState);
              formik.setFieldValue("selectedState", customerState);
              setStatesAvailability(0);
            } else {
              // setSelectedState("N.A");
              // formik.setFieldValue("selectedState", "N.A");
              setStatesAvailability(1);
            }
          } else {
            setStates([]);
            setSelectedState("N.A");
            formik.setFieldValue("selectedState", "N.A");
            setStatesAvailability(1);
          }
          myContext.handleLoading(false);
        })
        .catch((err) => {
          setStates([]);
          setSelectedState("N.A");
          formik.setFieldValue("selectedState", "N.A");
          setStatesAvailability(1);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setStates([]);
      setSelectedState("N.A");
      formik.setFieldValue("selectedState", "N.A");
      setStatesAvailability(1);
      let actionData = error;
      error({ actionData });
      myContext.handleLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(formik.values.name.length <= 0 ||
      formik.errors.name ||
      formik.errors.phone ||
      formik.errors.email ||
      formik.errors.address1 ||
      formik.errors.city ||
      formik.errors.country ||
      formik.errors.state ||
      buttonDisable
        ? true
        : false)
    ) {
      formik.handleSubmit();
    }
  };

  const handleFormSubmission = () => {
    formik.setTouched({
     name: true,
     businessName: true,
     phone: true,
     email: true,
     address1: true,
     city: true,
     zipCode: true,
     country_code: true,
     selectedCountry: true,
     selectedState: true,
     crt_password: true,
     password: true,
     confirm_password: true,
    }, true);
    if(formik.isValid){
      formik.handleSubmit();
    }
  }
  return (
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      bg={"Colors.loginAuthBackground"}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={8}
      >
        <PosListHeader
          header={"My Account"}
          discription={
            "Manage your account personal information and security settings"
          }
        />
      </Flex>
      <Tabs onChange={(index) => handleTabChange(index)}>
        <PosTab tabNames={tabNames} />
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "stretch", md: "center" }}
          gap={{ base: 2, md: 0 }}
          mb={"1.31rem"}
          ml={4}
          mr={4}
        ></Flex>
        <TabPanels>
          <Box
            paddingRight={{ base: 4, sm: 6, md: 10, lg: "9.25rem" }}
            paddingLeft={{ base: 4, sm: 6, md: 10, lg: "2.75rem" }}
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
          >
            <TabPanel>
            {/* <Stack spacing={2}>
                <PosLable
                    fontWeight={500}
                    label={true}
                    name={"Upload Profile Picture"}
                    mt="0.31rem"
                  />
                  {!oldImageBase64 ? (
                    <PosImageCropper
                      parentCallback={handleCallback}
                      canvasWidth="118px"
                      canvasHeight="118px"
                      cropperWidth="118"
                      cropperHeight="118"
                      cropperMinWidth="100%" // Minimum width of the crop area Cropper hide na thay ena mate
                      cropperMinHeight="100%" // Minimum width of the crop area Cropper hide na thay ena mate
                    />
                  ) : (
                    <PosImageCropper
                      parentCallback={handleCallback}
                      oldImageBase64={oldImageBase64}
                      canvasWidth="7.375rem"
                      canvasHeight="7.375rem"
                      cropperWidth="100%"
                      cropperHeight="100%"
                      cropperMinWidth="118" // Minimum width of the crop area Cropper hide na thay ena mate
                      cropperMaxWidth="100%" // Maximum width of the crop area when edit open at time full cropper display
                      cropperMinHeight="118" // Minimum height of the crop area Cropper hide na thay ena mate
                      cropperMaxHeight="100%"
                    />
                  )}
              </Stack> */}
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
                spacing={1}
              >
                <Box w={{ base: "100%", md: "45%" }}>
                  <PosLable
                    name={"Contact Name"}
                    requiredLabel={true}
                    fontSize="0.94rem"
                    fontStyle="normal"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  />
                  <PosInput
                    id="name"
                    mt={"1rem"}
                    placeholder={"Name"}
                    color={Colors.posCommon}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.name}
                    handleBlur={formik.handleBlur}
                    onKeyDown={handleKeyDown}
                    posInputGroup={true}
                    disabled={loaderResponse?.data?.data?.employee?.role == Constants.SUPER_ADMIN_USER_ROLE}
                    inputType={"text"}
                    inputError={formik.touched.name && formik.errors.name}
                  >
                    <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
                  </PosInput>
                  <Text color={Colors.posCancleButtonRed}>
                    {formik.touched.name && formik.errors.name ? (
                      <span>{formik.errors.name}</span>
                    ) : null}
                  </Text>
                  <PosLable
                    name={"Email Address"}
                    requiredLabel={true}
                    fontStyle="normal"
                    fontSize="0.94rem"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  />
                  <PosInput
                    id="email"
                    mt={"1rem"}
                    placeholder={"email"}
                    color={Colors.posCommon}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.email}
                    handleBlur={formik.handleBlur}
                    onKeyDown={handleKeyDown}
                    posInputGroup={true}
                    inputType={"text"}
                    disabled={loaderResponse?.data?.data?.employee?.role == Constants.SUPER_ADMIN_USER_ROLE}
                    inputError={formik.touched.email && formik.errors.email}
                  >
                    <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
                  </PosInput>
                  <Text color={Colors.posCancleButtonRed}>
                    {formik.touched.email && formik.errors.email ? (
                      <span>{formik.errors.email}</span>
                    ) : null}
                  </Text>
                </Box>
                <Box w={{ base: "100%", md: "50%" }}>
                  {/* <PosLable
                    name={"Business Name"}
                    label={true}
                    fontSize="0.94rem"
                    fontStyle="normal"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  />
                  <PosInput
                    id="businessName"
                    mt={"1rem"}
                    placeholder={"Name"}
                    color={Colors.posCommon}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.businessName}
                    handleBlur={formik.handleBlur}
                    onKeyDown={handleKeyDown}
                    posInputGroup={true}
                    disabled={loaderResponse?.data?.data?.employee?.role == Constants.SUPER_ADMIN_USER_ROLE}
                    inputType={"text"}
                    inputError={
                      formik.touched.businessName && formik.errors.businessName
                    }
                  >
                    <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
                  </PosInput>
                  <Text color={Colors.posCancleButtonRed}>
                    {formik.touched.businessName &&
                    formik.errors.businessName ? (
                      <span>{formik.errors.businessName}</span>
                    ) : null}
                  </Text> */}
                  <PosLable
                    name={"Phone Number"}
                    requiredLabel={true}
                    fontSize="0.94rem"
                    fontStyle="normal"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  />
                  <PosInput
                    id="phone"
                    mt={"1rem"}
                    placeholder={"phone"}
                    color={Colors.posCommon}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.phone}
                    handleBlur={formik.handleBlur}
                    onKeyDown={handleKeyDown}
                    posInputGroup={true}
                    inputType={"text"}
                    disabled={loaderResponse?.data?.data?.employee?.role == Constants.SUPER_ADMIN_USER_ROLE}
                    inputError={formik.touched.phone && formik.errors.phone}
                  >
                    <PhoneIcon
                      color={Colors.posNavbarLink}
                      style={{ fill: "#5881FE" }}
                    />
                  </PosInput>
                  <Text color={Colors.posCancleButtonRed}>
                    {formik.touched.phone && formik.errors.phone ? (
                      <span>{formik.errors.phone}</span>
                    ) : null}
                  </Text>
                </Box>
              </Flex>
              <Box sx={{ paddingTop: "1.7rem", paddingBottom: "0.6rem" }}>
                <Divider />
              </Box>
              <Stack spacing={2}>
                <PosLable
                  name={"Address Line 1"}
                  requiredLabel={true}
                  fontStyle="normal"
                  fontSize="0.94rem"
                  fontWeight={500}
                  lineHeight="normal"
                  letterSpacing="-0.02rem"
                />
                <PosInput
                  id="address1"
                  mt={"1rem"}
                  placeholder={"Enter Address Line 1"}
                  color={Colors.posCommon}
                  handleInputChange={formik.handleChange}
                  inputValue={formik.values.address1}
                  handleBlur={formik.handleBlur}
                  onKeyDown={handleKeyDown}
                  posInputGroup={true}
                  inputType={"text"}
                  disabled={loaderResponse?.data?.data?.employee?.role == Constants.SUPER_ADMIN_USER_ROLE}
                  inputError={formik.touched.address1 && formik.errors.address1}
                >
                  <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
                </PosInput>
                <Text color={Colors.posCancleButtonRed}>
                  {formik.touched.address1 && formik.errors.address1 ? (
                    <span>{formik.errors.address1}</span>
                  ) : null}
                </Text>
              </Stack>
              <Stack spacing={2}>
                <PosLable
                  name={"Address Line 2"}
                  label={true}
                  fontStyle="normal"
                  fontSize="0.94rem"
                  fontWeight={500}
                  lineHeight="normal"
                  letterSpacing="-0.02rem"
                />
                <PosInput
                  id="address2"
                  mt={"1rem"}
                  placeholder={"Enter Address Line 2"}
                  color={Colors.posCommon}
                  handleInputChange={formik.handleChange}
                  inputValue={formik.values.address2}
                  handleBlur={formik.handleBlur}
                  onKeyDown={handleKeyDown}
                  posInputGroup={true}
                  inputType={"text"}
                  disabled={loaderResponse?.data?.data?.employee?.role == Constants.SUPER_ADMIN_USER_ROLE}
                  inputError={formik.touched.address2 && formik.errors.address2}
                >
                  <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
                </PosInput>
                <Text color={Colors.posCancleButtonRed}>
                  {formik.touched.address2 && formik.errors.address2 ? (
                    <span>{formik.errors.address2}</span>
                  ) : null}
                </Text>
              </Stack>
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
                spacing={1}
              >
                <Box w={{ base: "100%", md: "45%" }}>
                  <PosLable
                    name={"Country"}
                    requiredLabel={true}
                    fontStyle="normal"
                    fontSize="0.94rem"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  />
                  <PosCountryStateDropDown
                    options={countries}
                    value={formik.values.selectedCountry}
                    countriesSelect={true}
                    onChange={(e) => {
                      if(undefined != e.target.value && null != e.target.value && '' != e.target.value ){
                        handleSelectedCountry(e.target.value);
                        formik.setFieldValue("selectedCountry", e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      formik.setFieldTouched("selectedCountry", true);
                    }}
                    optionLabel="label"
                    placeholder="--Select Country--"
                    width={"100%"}
                    maxWidth={"42rem"}
                    height={"2.5rem"}
                    resetFilterOnHide={true}
                    className="w-full md:w-20rem"
                    lableAvailable={true}
                    disabled={loaderResponse?.data?.data?.employee?.role == Constants.SUPER_ADMIN_USER_ROLE}
                  />
                  <Text color={Colors.errorColor}>
                    {formik.touched.selectedCountry &&
                    countryError.length > 0 ? (
                      <span>{countryError}</span>
                    ) : null}
                  </Text>
                  <PosLable
                    name={"City"}
                    requiredLabel={true}
                    fontStyle="normal"
                    fontSize="0.94rem"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  />
                  <PosInput
                    id="city"
                    mt={"1rem"}
                    placeholder={"city"}
                    color={Colors.posCommon}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.city}
                    handleBlur={formik.handleBlur}
                    onKeyDown={handleKeyDown}
                    posInputGroup={true}
                    disabled={loaderResponse?.data?.data?.employee?.role == Constants.SUPER_ADMIN_USER_ROLE}
                    inputType={"text"}
                    inputError={formik.touched.city && formik.errors.city}
                  >
                    <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
                  </PosInput>
                  <Text color={Colors.posCancleButtonRed}>
                    {formik.touched.city && formik.errors.city ? (
                      <span>{formik.errors.city}</span>
                    ) : null}
                  </Text>
                </Box>
                <Box w={{ base: "100%", md: "50%" }}>
                  <PosLable
                    name={"State"}
                    requiredLabel={true}
                    fontStyle="normal"
                    fontSize="0.94rem"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                    pb={"0.31rem"}
                  />
                  {states.length === 0 ? (
                    <Select
                      id="state"
                      value={formik.values.selectedState || "N.A"}
                      sx={{
                        color: "gray",
                        fontSize: "0.94rem !important",
                        fontWeight: 300,
                        letterSpacing: "-0.01rem !important",
                        backgroundColor: "#5881fe0d",
                        borderRadius: "0.625rem",
                        height: "2.5rem",
                        border: "1px solid #b7b7b71f",
                        "&[disabled]": {
                          backgroundColor: "#5881fe0d !important",
                          opacity: 1,
                        },
                      }}
                      // onChange={(e) => {
                      //   handleSelectedState(e.target.value);
                      //   formik.setFieldValue("selectedState", e.target.value);
                      // }}
                      isDisabled
                      placeholder={
                        states.length > 0 ? "N.A" : "--Select State--"
                      }
                    >
                      <option value="N.A">N.A</option>
                    </Select>
                  ) : (
                    <PosCountryStateDropDown
                      options={states}
                      value={selectedState}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        if(undefined != e.value && null != e.value && '' != e.value){
                        handleSelectedState(selectedValue); 
                        formik.setFieldValue("selectedState", selectedValue);
                        formik.setFieldTouched("selectedState", true); 
                        if (!selectedValue) {
                          formik.setFieldError("selectedState", Constants.CUSTOMER_PLEASE_SELECT_A_STATE); 
                        } else {
                          formik.setFieldError("selectedState", ""); 
                        }
                      }
                    }}
                      onBlur={(e) => {
                        formik.setFieldTouched("selectedState", true);
                      }}
                      optionLabel="label"
                      placeholder="--Select State--"
                      width={"100%"}
                      maxWidth={"42rem"}
                      height={"2.5rem"}
                      resetFilterOnHide={true}
                      className="w-full md:w-20rem"
                      lableAvailable={true}
                      disabled={loaderResponse?.data?.data?.employee?.role == Constants.SUPER_ADMIN_USER_ROLE}
                    />
                  )}
                  {states.length > 0 && (
                    <Text color={Colors.errorColor}>
                      {formik.touched.selectedState && formik.errors.selectedState ? (
                        <span>{formik.errors.selectedState}</span>
                      ) : null}
                    </Text>
                  )}
                  <PosLable
                    name={"Zip Code"}
                    requiredLabel={true}
                    fontStyle="normal"
                    fontSize="0.94rem"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  />
                  <PosInput
                    id="zipCode"
                    mt={"1rem"}
                    placeholder={"zipCode"}
                    color={Colors.posCommon}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.zipCode}
                    handleBlur={formik.handleBlur}
                    onKeyDown={handleKeyDown}
                    posInputGroup={true}
                    inputType={"text"}
                    disabled={loaderResponse?.data?.data?.employee?.role == Constants.SUPER_ADMIN_USER_ROLE}
                    inputError={formik.touched.zipCode && formik.errors.zipCode}
                  >
                    <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
                  </PosInput>
                  <Text color={Colors.posCancleButtonRed}>
                    {formik.touched.zipCode && formik.errors.zipCode ? (
                      <span>{formik.errors.zipCode}</span>
                    ) : null}
                  </Text>
                </Box>
              </Flex>
              <Stack spacing={2}>
                <PosLable
                  name={"Country Code"}
                  requiredLabel={true}
                  fontStyle="normal"
                  fontSize="0.94rem"
                  fontWeight={500}
                  lineHeight="normal"
                  letterSpacing="-0.02rem"
                />
                <PosInput
                  id="country_code"
                  mt={"1rem"}
                  placeholder={"Enter Country Code"}
                  color={Colors.posCommon}
                  handleInputChange={formik.handleChange}
                  inputValue={formik.values.country_code}
                  handleBlur={formik.handleBlur}
                  onKeyDown={handleKeyDown}
                  posInputGroup={true}
                  inputType={"text"}
                  disabled={loaderResponse?.data?.data?.employee?.role == Constants.SUPER_ADMIN_USER_ROLE}
                  inputError={
                    formik.touched.country_code && formik.errors.country_code
                  }
                >
                  <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
                </PosInput>
                <Text color={Colors.posCancleButtonRed}>
                  {formik.touched.country_code && formik.errors.country_code ? (
                    <span>{formik.errors.country_code}</span>
                  ) : null}
                </Text>
              </Stack>
              <Spacer />
              {loaderResponse?.data?.data?.employee?.role != Constants.SUPER_ADMIN_USER_ROLE && (
              <Flex
                direction={{ base: "column", md: "row" }}
                alignItems={"flex-end"}
                justifyContent="flex-end"
                pt={{ base: "1.5rem", md: "3.06rem" }}
                pb={{ base: "1.5rem", md: "0" }}
                pl={{ base: "1rem", lg: "1.88rem" }}
                gap={2}
              >
                <Stack
                  direction={"row"}
                  alignSelf={"flex-end"}
                  ml={{ base: "1rem", md: "8rem" }} 
                >
                  <PosFormButton
                    // isDisabled={
                    //   formik.values.name.length <= 0 ||
                    //   formik.errors.name ||
                    //   formik.errors.address1 ||
                    //   formik.errors.city ||
                    //   formik.errors.zipCode ||
                    //   formik.errors.phone ||
                    //   formik.errors.country_code ||
                    //   selectedCountry == null ||
                    //   selectedCountry == "" ||
                    //   selectedState == "" ||
                    //   selectedState == null ||
                    //   buttonDisable
                    //     ? true
                    //     : false
                    // }
                    isDisabled={buttonDisable}
                    buttonsubmit={"Update Changes"}
                    onClick={handleFormSubmission}
                  />
                </Stack>
              </Flex>
              )}
            </TabPanel>
          </Box>
          <Box
            paddingRight={{ base: 4, sm: 6, md: 10, lg: "23.99rem" }}
            paddingLeft={{ base: 4, sm: 6, md: 10, lg: "2.75rem" }}
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
          >
            <TabPanel>
              <ProfileEditPassword />
            </TabPanel>
          </Box>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default WithRouter(ProfileEdit);
