import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Image,
  Spacer,
  Stack,
  StackDivider,
  Text,
  InputGroup,
  Input,
  InputRightElement,
  InputLeftElement,
} from "@chakra-ui/react";
import { twofix } from "../../../helpers/utils/Utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Tippy from "@tippyjs/react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
const EmployeesCreate = (props) => {
  const myContext = useContext(AppContext);
  const stateData = useLocation();
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const [state, setState] = useState({ formData: {} });
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [allTouchedField, setAllTouchedField] = useState(false);
  const [roles, setRoles] = useState([]);
  const effectRun = useRef(true);
  const loaderResponse = useLoaderData();
  const [showPassword, setPassword] = React.useState(false);
  const [showCnfPassword, setCnfPassword] = React.useState(false);
  const handleClickPassword = () => setPassword(!showPassword);
  const handleClickCnfPassword = () => setCnfPassword(!showCnfPassword);
  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      selectedRoles : [],
      wagesPerHour: "",
      pin: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .required(Constants.USER_EMAIL_REQUIRED)
        .matches(Constants.EMAIL_REGEX, Constants.USER_EMAIL_NOT_VALID)
        .email(Constants.USER_EMAIL_NOT_VALID)
        .max(254, Constants.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER),
      name: Yup.string()
        .required(Constants.EMPLOYEES_NAME_REQUIRED)
        .max(199, Constants.EMPLOYEES_NAME_LESS_THEN_OR_EQUAL_255_CHARACTER),
      selectedRoles : Yup.array().min(1, Constants.EMPLOYEES_ROLE_REQUIRED),  
      pin: Yup.string()
        .required(Constants.EMPLOYYEES_PIN_REQUIRED)
        .matches(/^[0-9]{6}$/, Constants.EMPLOYEES_PIN_MUST_BE_SIX_DIGIT),
      wagesPerHour: Yup.number()
        .nullable()
        // .integer(Constants.WAGE_PER_HOUR_NOT_VALID)
        .typeError(Constants.WAGE_PER_HOUR_NOT_VALID)
        .min(0.01, Constants.WAGE_PER_HOUR_MIN_VALUE)
        .max(999999.99, Constants.WAGE_PER_HOUR_MAX_VALUE),
      password: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
          Constants.VALID_PASSWORD
        )
        .required(Constants.PASSWORD_FIELD_REQUIRED),
      confirmPassword: Yup.string()
        .required(Constants.CONFIRM_PASSWORD_FIELD_REQUIRED)
        .oneOf([Yup.ref(Constants.PASSWORD)], Constants.SAME_PASSWORD),
    }),
    onSubmit: async (values) => {
      setState({ ...state, formData: values });
      myContext.handleLoading(true);
      const roleNames = selectedRoles.map((role) => role.name);
      const payload = {
        ...values,
        role: JSON.stringify(roleNames),
      };
      actionRun.current = true;
      setButtonDisable(true);
      setAllTouchedField(false);
      submit(payload, {
        method: Constants.POST,
        path: Constants.EMPLOYEES_CREATE_API_ROUTE,
      });
    },
  });
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values.name.length <= 0 ||
        formik.errors.name ||
        formik.errors.email ||
        formik.errors.pin ||
        formik.errors.password ||
        formik.errors.confirmPassword ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          setRoles(loaderResponse.data.data);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        props.navigate(Constants.HR_EMPLOYEE_PATH);
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.EMPLOYEES_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);
  const checkString = (str) => {
    const chars = Array.from(str);
    const hasEightCharacter = str.length >= 8 ? true : false;
    const hasUppercase = chars.some((char) => /[A-Z]/.test(char));
    const hasLowercase = chars.some((char) => /[a-z]/.test(char));
    const hasNumeric = chars.some((char) => !isNaN(char) && char !== " ");
    const hasSpecial = chars.some((char) =>
      /[!@#$%^&*(),.?":{}|<>]/.test(char)
    );

    return {
      hasEightCharacter,
      hasUppercase,
      hasLowercase,
      hasNumeric,
      hasSpecial,
    };
  };
  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedRoles", e.value);
    setSelectedRoles(e.value);
  };
  return (
    <Box padding={4}>
      <Card
        sx={{
          boxShadow: "none",
        }}
      >
        <CardHeader
          alignItems={{ base: "flex-start" }}
          mt={{ base: 0, md: 10 }}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.EMPOYEES_LIST_ROUTE, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Employees", "Create Employees"]}
            breadCrumTitle={"Create Employees"}
          />
        </CardHeader>
        <Divider borderColor="#E6E6E6" mt={{ base: 4, md: 8 }} />
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Box w={{ base: "100%", md: "35%" }} ml={{ base: 0, md: 6 }}>
                  <Heading
                    as="h3"
                    size="xs"
                    fontWeight="500"
                    fontSize="1.125rem"
                    lineHeight="1.40625rem"
                    letterSpacing="-0.016875rem"
                    color="#010048"
                  >
                    Employee Information
                  </Heading>
                  <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                    Add contact information to complete your employee details
                  </Text>
                </Box>
                <Box w={{ base: "100%", md: "55%" }} mt={{ base: 2, md: 0 }}>
                  <Text fontSize={"0.94rem"} fontWeight={500}>
                    Name
                    <Text as="span" color={Colors.errorColor}>
                      *
                    </Text>
                  </Text>
                  <PosInput
                    posInputGroup={true}
                    id={"name"}
                    inputType={"text"}
                    placeholder={"Joshua Thompson"}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.name}
                    handleBlur={(e) => {
                      formik.setFieldValue("name", formik.values.name.trim());
                      formik.handleBlur(e);
                    }}
                    onKeyDown={handleKeyDown}
                    inputError={formik.touched.name && formik.errors.name}
                  >
                    <DriveFileRenameOutlineIcon
                      style={{ fill: Colors.primeTaxInfo }}
                    />
                  </PosInput>
                  <Text
                    color="red"
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    {formik.touched.name && formik.errors.name
                      ? formik.errors.name
                      : null}
                  </Text>
                  <PosLable
                    fontWeight={500}
                    name={"Email Address"}
                    requiredLabel={true}
                  ></PosLable>
                  <PosInput
                    posInputGroup={true}
                    id={"email"}
                    inputType={"email"}
                    placeholder={"Joshuathompson@example.com"}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.email}
                    handleBlur={(e) => {
                      formik.setFieldValue("email", formik.values.email.trim());
                      formik.handleBlur(e);
                    }}
                    onKeyDown={handleKeyDown}
                    inputError={formik.touched.email && formik.errors.email}
                  >
                    <DriveFileRenameOutlineIcon
                      style={{ fill: Colors.primeTaxInfo }}
                    />
                  </PosInput>
                  <Text
                    color="red"
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    {formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : null}
                  </Text>
                  <PosLable
                    fontWeight={500}
                    name={"Password"}
                    requiredLabel={true}
                  ></PosLable>
                  {/* <PosInput
                    posInputGroup={true}
                    id={"password"}
                    inputType={"password"}
                    inputValue={formik.values.password}
                    handleBlur={(e) => {
                      formik.setFieldValue(
                        "password",
                        formik.values.password.trim()
                      );
                      formik.handleBlur(e);
                    }}
                    onKeyDown={handleKeyDown}
                    handleInputChange={formik.handleChange}
                  >
                    <DriveFileRenameOutlineIcon
                      style={{ fill: Colors.primeTaxInfo }}
                    />
                  </PosInput> */}
                  <InputGroup size="md">
                    <Input
                      name="password"
                      id="password"
                      variant={"posBasicInput"}
                      onChange={formik.handleChange}
                      onBlur={(e) => {
                        formik.setFieldValue(
                          "password",
                          formik.values.password.trim()
                        );
                        formik.handleBlur(e);
                      }}
                      value={formik.values.password}
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      inputerror={
                        formik.touched.password && formik.errors.password
                      }
                    />
                    <InputLeftElement>
                      <DriveFileRenameOutlineIcon
                        style={{ fill: Colors.primeTaxInfo }}
                      />
                    </InputLeftElement>
                    <InputRightElement mt={0.9} width="4.5rem">
                      {showPassword ? (
                        <VisibilityOffIcon
                          bg={Colors.posCancleButtonRed}
                          onClick={() => handleClickPassword()}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "0.31rem",
                            flexShrink: 0,
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <VisibilityIcon
                          bg={Colors.posCancleButtonRed}
                          onClick={() => handleClickPassword()}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "0.31rem",
                            flexShrink: 0,
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  <Text
                    fontWeight="500"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                    color="#010048"
                    mb={0.5}
                  >
                    Password must contain:
                  </Text>
                  <Text
                    color={
                      checkString(formik.values.password.trim())
                        .hasEightCharacter
                        ? "#09A222"
                        : "rgba(1, 0, 72, 0.2)"
                    }
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    <CheckIcon /> At least 8 characters
                  </Text>
                  <Text
                    color={
                      checkString(formik.values.password.trim()).hasUppercase
                        ? "#09A222"
                        : "rgba(1, 0, 72, 0.2)"
                    }
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    <CheckIcon /> One uppercase letter
                  </Text>
                  <Text
                    color={
                      checkString(formik.values.password.trim()).hasLowercase
                        ? "#09A222"
                        : "rgba(1, 0, 72, 0.2)"
                    }
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    <CheckIcon /> One lowercase letter
                  </Text>
                  <Text
                    color={
                      checkString(formik.values.password.trim()).hasNumeric
                        ? "#09A222"
                        : "rgba(1, 0, 72, 0.2)"
                    }
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    <CheckIcon /> One numeric digit
                  </Text>
                  <Text
                    color={
                      checkString(formik.values.password.trim()).hasSpecial
                        ? "#09A222"
                        : "rgba(1, 0, 72, 0.2)"
                    }
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    <CheckIcon /> One special character (e.g., @, #, $)
                  </Text>
                  <PosLable
                    fontWeight={500}
                    name={"Confirm Password"}
                    requiredLabel={true}
                  ></PosLable>
                  <InputGroup size="md">
                    <Input
                      // mt={2}
                      name="confirmPassword"
                      id="confirmPassword"
                      onChange={formik.handleChange}
                      variant={"posBasicInput"}
                      onBlur={(e) => {
                        formik.setFieldValue(
                          "confirmPassword",
                          formik.values.confirmPassword.trim()
                        );
                        formik.handleBlur(e);
                      }}
                      value={formik.values.confirmPassword}
                      type={showCnfPassword ? "text" : "password"}
                      placeholder="Enter Confirm Password"
                      inputerror={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                    />
                    <InputLeftElement>
                      <DriveFileRenameOutlineIcon
                        style={{ fill: Colors.primeTaxInfo }}
                      />
                    </InputLeftElement>
                    <InputRightElement mt={0.9} width="4.5rem">
                      {showCnfPassword ? (
                        <VisibilityOffIcon
                          bg={Colors.posCancleButtonRed}
                          onClick={() => handleClickCnfPassword()}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "0.31rem",
                            flexShrink: 0,
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <VisibilityIcon
                          bg={Colors.posCancleButtonRed}
                          onClick={() => handleClickCnfPassword()}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "0.31rem",
                            flexShrink: 0,
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  <Text
                    color="red"
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                      ? formik.errors.confirmPassword
                      : null}
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Box w={{ base: "100%", md: "35%" }} ml={{ base: 0, md: 6 }}>
                  <Heading
                    as="h3"
                    size="xs"
                    fontWeight="500"
                    fontSize="1.125rem"
                    lineHeight="1.40625rem"
                    letterSpacing="-0.016875rem"
                    color="#010048"
                  >
                    Role and Rates
                  </Heading>
                  <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                    Assign roles to define what members can do and include their
                    compensation
                  </Text>
                </Box>
                <Box w={{ base: "100%", md: "55%" }} mt={{ base: 2, md: 0 }}>
                  <Flex flexDirection={"row"}>
                    <Text fontSize={"0.94rem"} fontWeight={500}>
                      Role
                      <Text as="span" color={Colors.errorColor}>
                        *
                      </Text>
                    </Text>
                    <Tippy
                      content="These roles are made from HR-Roles. they set the parameters for the employee's access and powers."
                      interactive={true}
                      maxWidth={350}
                      placement="top"
                      animation="fade"
                      inertia={true}
                      moveTransition="transform 0.2s ease-out"
                      theme="tomato"
                    >
                      <Image
                        src={toolTip}
                        alt="Tooltip"
                        ml={{ base: "0rem", md: "0.3rem" }}
                      />
                    </Tippy>
                  </Flex>
                  <PosDropDown
                    multiSelect={true}
                    resetFilterOnHide={true}
                    // value={selectedRoles}
                    value={formik.values.selectedRoles}
                    // onChange={(e) => setSelectedRoles(e.value)}
                    onChange={handleOptionSelect}
                    onBlur={(e) => {
                      formik.setFieldTouched("selectedRoles");
                      formik.handleBlur(e);
                    }}
                    options={roles}
                    optionLabel="display_name"
                    placeholder="Select Roles"
                    width="100%"
                    className="w-full md:w-20rem"
                  />
                   <Text
                    color="red"
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    {formik.touched.selectedRoles && formik.errors.selectedRoles
                      ? formik.errors.selectedRoles
                      : null}
                  </Text>
                  <PosLable
                    fontWeight={500}
                    name={"Wages per hour"}
                    label={true}
                  ></PosLable>
                  <PosInput
                    posNumberInput={true}
                    id={"wagesPerHour"}
                    inputValue={formik.values.wagesPerHour}
                    handleBlur={(e) => {
                      formik.handleBlur(e);
                      formik.setFieldValue("wagesPerHour", twofix(e.target.value));
                      // setTimeout(() => {
                      // }, 1000);
                    }}
                    precision={2}
                    handleInputChange={formik.handleChange}
                  ></PosInput>
                  <Text
                    color="red"
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    {formik.touched.wagesPerHour && formik.errors.wagesPerHour
                      ? formik.errors.wagesPerHour
                      : null}
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Box w={{ base: "100%", md: "35%" }} ml={{ base: 0, md: 6 }}>
                  <Heading
                    as="h3"
                    size="xs"
                    fontWeight="500"
                    fontSize="1.125rem"
                    lineHeight="1.40625rem"
                    letterSpacing="-0.016875rem"
                    color="#010048"
                  >
                    Security
                  </Heading>
                  <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                    Set a pin to enable your employees log into the POS and
                    manage clock in and clock out functions
                  </Text>
                </Box>
                <Box w={{ base: "100%", md: "55%" }} mt={{ base: 2, md: 0 }}>
                  {/* <PosLable name={"Pin Code"} requiredLabel={true}></PosLable> */}
                  <Flex flexDirection={"row"}>
                    <Text fontSize={"0.94rem"} fontWeight={500}>
                      Pin Code
                      <Text as="span" color={Colors.errorColor}>
                        *
                      </Text>
                    </Text>
                    <Tippy
                      content="This pin would be used to log in to the POS as well as for the employee's Clock in and clock-out functions."
                      interactive={true}
                      maxWidth={350}
                      placement="top"
                      animation="fade"
                      inertia={true}
                      moveTransition="transform 0.2s ease-out"
                      theme="tomato"
                    >
                      <Image
                        src={toolTip}
                        alt="Tooltip"
                        ml={{ base: "0rem", md: "0.3rem" }}
                      />
                    </Tippy>
                  </Flex>
                  <PosInput
                    posNumberInput={true}
                    id={"pin"}
                    inputType={"number"}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.pin}
                    handleBlur={(e) => {
                      formik.setFieldValue("pin", formik.values.pin.trim());
                      formik.handleBlur(e);
                    }}
                    onKeyDown={handleKeyDown}
                    inputError={formik.touched.pin && formik.errors.pin}
                  ></PosInput>
                  <Text
                    color="red"
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    {formik.touched.pin && formik.errors.pin
                      ? formik.errors.pin
                      : null}
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Stack>
          <Flex
            minWidth="max-content"
            gap="2"
            mt={{ base: "2.36rem", md: "4.72rem" }}
          >
            <Spacer />
            <ButtonGroup>
              <PosFormButton
                onClick={() => {
                  props.navigate(Constants.EMPOYEES_LIST_ROUTE, {
                    state: stateData.state,
                  });
                }}
                buttonText={"Cancel"}
                isDisabled={buttonDisable}
                CancelButton={true}
              />
              <PosFormButton
                // isDisabled={
                //   !formik.isValid || !formik.dirty || buttonDisable
                //     ? // ||selectedRoles.length>0
                //       // selectedRoles.length === 0
                //       true
                //     : false
                // }
                isDisabled={buttonDisable}
                buttonsubmit={"Submit"}
                SubmitButton={true}
                onClick={() => {
                  // submit time all validation fired and error massage display.
                  if (!formik.isValid) {
                    if (!allTouchedField) {
                      formik.handleSubmit();
                      setAllTouchedField(true);
                    }
                  } else {
                    formik.handleSubmit();
                  }
                }}
                
              />
            </ButtonGroup>
          </Flex>
        </CardBody>
      </Card>
    </Box>
  );
};
export default WithRouter(EmployeesCreate);
