import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import { Chips } from "primereact/chips";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSpliteButton,
  PosTostMessage,
  SingleOrderView,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
  commonDateFormate,
  twofix,
} from "../../../../../helpers/utils/Utils";
// import { twofix, twofixs } from "../../../helpers/utils/Utils";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SendIcon from "@mui/icons-material/Send";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import {
  downloadPDF,
  getCustomerLatestBalance,
  payOrderUnpaidAmount,
  sendInvoiceToCustomer,
} from "./CustomerCreditAccountService";
const CustomerCreditAccount = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [creditCustomerBalanceData, setCreditCustomerBalanceData] = useState(
    []
  );
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isSmall] = useMediaQuery("(max-width: 1032px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 150 } : { flex: 0.7 };
  const actionColumnFlexDouble = isMobile ? { width: 200 } : { flex: 1.2 };
  const [totalDailySales, seltTotalDailySales] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [orderIdForView, setOrderIdForView] = useState("");
  const [locations, setLocations] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [customersCreditAmount, setCustomersCreditAmount] = useState([]);
  const [remainingCreditAmount, setRemainingCreditAmount] = useState(0);
  const [orderId, setOrderId] = useState(0);
  const [orderUnpaidAmount, setOrderUnpaidAmount] = useState([]);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [emailCustomer, setEmailCustomer] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [applyClicked, setApplyClicked] = useState(false);
  const pageRef = useRef(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "customer_name",
      sort: "desc",
    },
  ]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    total_cash_discount: false,
    total_unpaid_amount: false,
    total_tax_refunded: false,
    total_tip_refunded: false,
    total_amount_refunded: false,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPayOpen,
    onOpen: onPayOpen,
    onClose: onPayClose,
  } = useDisclosure();
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();
  const columnNames = [
    { field: "sr_no", headerName: "Sr No.", sortable: false, width: 90 },
    {
      field: "id",
      headerName: "Order Number  ",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "customer_name",
      headerName: "Business Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "location_name",
      headerName: "Location",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "balance",
      headerName: "Amount($)",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "day_difference",
      headerName: "Payment Due Days",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "order_date",
      headerName: "Order Date",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 115,
      renderCell: (params) => {
        const rowData = params.row;
        const handleViewPage = (item) => {
          myContext.handleLoading(true);
          handleDisplayOrderData(rowData.order_id);
        };

        const options = {
          buttonName: "Pay",
          buttonIcon: "",
          handleClick: (item) => handlePay(item),
          subButtons: [
            {
              name: "View",
              icon: (
                <RemoveRedEyeOutlinedIcon
                  style={{ color: Colors.posViewTextColor }}
                />
              ),
              onClick: (item) => handleViewPage(item),
            },
            {
              name: "Send Email",
              icon: <SendIcon style={{ color: Colors.posViewTextColor }} />,
              onClick: (item) => handleSendEmail(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];
  const handleSendEmail = (item) => {
    //  const  emailCustomer=[];
    setEmailCustomer([]);
    // setEmailCustomer(item.customer_email);
    // emailCustomer.push(item.customer_email);
    // setEmailCustomer(emailCustomer);
    openSendEmailModal(item);
    // onOpen();
  };
  const handleDisplayOrderData = useCallback(
    (id) => {
      setOrderIdForView(id);
      onDrawerOpen();
    },
    [orderIdForView]
  );
  const handlePay = (item) => {
    if (item.paid === 1 && item.unpaid_amount <= 0) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.TOAST_TYPE_WARNING,
        alertDescription: Constants.ORDER_ALREADY_PAID,
      });
    } else {
      myContext.handleLoading(true);
      try {
        getCustomerLatestBalance(item.customer_id)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              if (response.data.data.available_credit > 0) {
                setOrderId(item.order_id);
                setCustomersCreditAmount(response.data.data.available_credit);
                setRemainingCreditAmount(response.data.data.available_credit);
                setOrderUnpaidAmount(item.unpaid_amount);
                if (
                  Number(response.data.data.available_credit) >
                  Number(item.unpaid_amount)
                ) {
                  formik.setFieldValue("payment_amount", item.unpaid_amount);
                  let num =
                    Number(response.data.data.available_credit) -
                    Number(item.unpaid_amount);
                  setRemainingCreditAmount(num.toFixed(2));
                  // setRemainingCreditAmount(
                  //   Number(response.data.data.available_credit) -
                  //     Number(item.unpaid_amount)
                  // );
                } else {
                  formik.setFieldValue(
                    "payment_amount",
                    response.data.data.available_credit
                  );
                  formik.setFieldValue("external_reference", "");
                  setRemainingCreditAmount(0.0);
                }

                onPayOpen();
                myContext.handleLoading(false);
              } else {
                addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.TOAST_TYPE_WARNING,
                  alertDescription: Constants.CUSTOMER_NOT_HAVE_BALANCE,
                });
                myContext.handleLoading(false);
              }
            } else {
              setLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setLoading(false);
        myContext.handleLoading(false);
      }
    }
  };
  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const formik = useFormik({
    initialValues: {
      selectedLoactions:
        undefined !== loaderResponse?.data?.data?.location &&
          null !== loaderResponse?.data?.data?.location
          ? loaderResponse?.data?.data?.location
          : [],
      selectedCustomers: [],
      selectedPaymentType: [],
      fromDate: "",
      toDate: "",
      payment_amount: "",
      external_reference: "",
      //   email: emailReportingType.includes("DSR"),
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      // fromDate: Yup.string().when('toDate', {
      //     is: (toDate) => toDate !== "",
      //     then: (schema) =>
      //     schema
      //     .required(Constants.FORM_DATE_IS_REQUIRED),
      //     otherwise:
      //     Yup.string(),
      //   }),
      external_reference: Yup.string()
        // .trim()
        .max(191, Constants.EXT_REF_LENGTH_GREATE_THEN_191),
      payment_amount: Yup.number()
        .required(Constants.PAYMENT_AMOUNT_REQUIRED)
        .typeError(Constants.PAYMENT_AMOUNT_NOT_VALID)
        .min(0.01, Constants.PAYMENT_AMOUNT_MIN_VALUE)
        .max(999999.99, Constants.PAYMENT_AMOUNT_MAX_VALUE),
    }),
    onSubmit: async (values) => { },
  });
  const openSendEmailModal = (item) => {
    let ce = [];
    setOrderId(item.order_id);
    ce.push(item.customer_email);
    setEmailCustomer(ce);
    onOpen();
  };
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setOlColumnVisibilityModel((prev) => ({
              ...prev,
              location_name: false,
            }));
          }
          setLocations(loaderResponse?.data?.data?.location);
          setCustomers(loaderResponse?.data?.data?.customer);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);

  useEffect(() => {
    if (pageRef.current) {
      getCustomerCreditData();
    }
  }, [paginationModel, sortModel]);
  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Customer Invoices and Statement Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.CUST_CREDIT_AC_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.customerAccountDetails);
          setCreditCustomerBalanceData(
            actionResponse.data.data.allCustomerLatestCreditBalance
          );
          setTotalCount(actionResponse.data.data.totalItems);
          let data = [
            "Total Amount($)",
            actionResponse.data.data.totalAmount.toFixed(2),
          ];
          seltTotalDailySales(data);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);
  const handleExportReport = () => {
    if (formik.values.selectedLoactions.length > 0) {
      // const allCustomersData =
      //   formik.values.selectedCustomers.length > 0
      //     ? JSON.stringify(formik.values.selectedCustomers)
      //     : JSON.stringify(customers);
      if (
        (formik.values.fromDate === null || formik.values.fromDate === "") &&
        formik.values.toDate !== null &&
        formik.values.toDate !== ""
      ) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.REPORT_MODULE,
          alertDescription: Constants.CUSTOMER_CREDIT_ACCOUNT_REPORT_FROM_DATE_REQUIRED,
        });
      } else {
        myContext.handleLoading(true);
        let data = {
          getreport: false,
          type: "xls",
          location: JSON.stringify(formik.values.selectedLoactions),
          allCustomers: JSON.stringify(customers),
          customer: JSON.stringify(formik.values.selectedCustomers),
          paidTypes: JSON.stringify(formik.values.selectedPaymentType),
          zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          query: JSON.stringify({
            limit: paginationModel.pageSize,
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
          }),
        };
        if (formik.values.fromDate != null && formik.values.fromDate != "") {
          data.dateFrom = reportDateFormate(formik.values.fromDate);
          // data.dateTo = reportDateFormate(formik.values.toDate);
        }
        if (formik.values.toDate != null && formik.values.toDate != "") {
          // data.dateFrom = reportDateFormate(formik.values.fromDate);
          data.dateTo = reportDateFormate(formik.values.toDate);
        }
        submit(data, {
          method: Constants.POST,
          path: Constants.CUSTOMER_CREDIT_ACCOUNT_ROUTE,
        });
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };
  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getCustomerCreditData = () => {
    if (formik.values.selectedLoactions.length > 0) {
      if (
        (formik.values.fromDate === null || formik.values.fromDate === "") &&
        formik.values.toDate !== null &&
        formik.values.toDate !== ""
      ) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.REPORT_MODULE,
          alertDescription: Constants.CUSTOMER_CREDIT_ACCOUNT_REPORT_FROM_DATE_REQUIRED,
        });
      } else {
        setButtonDisable(true);
        setLoading(true);
        setShowTable(false);
        setApplyClicked(true);
        let data = {
          getreport: true,
          location: JSON.stringify(formik.values.selectedLoactions),
          allCustomers: JSON.stringify(customers),
          customer: JSON.stringify(formik.values.selectedCustomers),
          paidTypes: JSON.stringify(formik.values.selectedPaymentType),
          zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          query: JSON.stringify({
            limit: paginationModel.pageSize,
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
          }),
        };
        if (formik.values.fromDate != null && formik.values.fromDate != "") {
          data.dateFrom = reportDateFormate(formik.values.fromDate);
          // data.dateTo = reportDateFormate(formik.values.toDate);
        }
        if (formik.values.toDate != null && formik.values.toDate != "") {
          // data.dateFrom = reportDateFormate(formik.values.fromDate);
          data.dateTo = reportDateFormate(formik.values.toDate);
        }
        submit(data, {
          method: Constants.POST,
          path: Constants.CUSTOMER_CREDIT_ACCOUNT_ROUTE,
        });
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedLoactions", e.value);
  };

  const handleCustomerOptionSelect = (e) => {
    formik.setFieldValue("selectedCustomers", e.value);
  };
  const handlePaymentOptionSelect = (e) => {
    formik.setFieldValue("selectedPaymentType", e.value);
  };
  const payment_type = [
    {
      label: "PAID",
      value: 1,
    },
    {
      label: "UNPAID",
      value: 0,
    },
  ];
  const handleResetAllData = () => {
    pageRef.current = false;
    setSortModel([
      {
        field: "customer_name",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setApplyClicked(false);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const modifiedData = tableData.map((data) => ({
    order_date: commonDateFormate(data.created_at),
    id: data.local_id,
    order_id: data.id,
    sr_no: data.sr_no,
    customer_name: data.customer_name,
    customer_email: data.customer_email,
    customer_id: data.customer_id,
    day_difference: data.day_difference === null ? "N.A." : data.day_difference,
    location_name: data.location_name,
    paid: data.paid,
    status: data.status == "Unpaid $0.00" ? "Paid" :data.status, 
    unpaid_amount: data.unpaid_amount,
    balance: data.balance,
  }));
  const emailValue = (email, key) => {
    const re = new RegExp(Constants.EMAIL_REGEX);
    const testResult = re.test(String(email).trim().toLowerCase());
    if (!testResult) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [key]: email + ` is not a valid email address.`,
      }));
    } else {
      if (key == "dsr") {
        emailCustomer.push(email);
        setEmailCustomer([...emailCustomer]);
        setValidationErrors({});
      }
    }
  };

  const handleRemove = (chipToRemove, key) => {
    if (key === "dsr") {
      setEmailCustomer(emailCustomer.filter((chip) => chip != chipToRemove));
    }
  };
  const sendInvoiceEmail = () => {
    myContext.handleLoading(true);
    let data = {
      email: emailCustomer,
      order_id: orderId,
      skipEmailToValidate: 1,
    };
    try {
      sendInvoiceToCustomer(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.TOAST_HEADING_SUCCESS,
              alertDescription: Constants.INVOICE_EMAIL_SEND_SUCCESSFULLY,
            });
            onClose();
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const handlePdfReport = () => {
    if (formik.values.selectedLoactions.length > 0) {
      if (
        (formik.values.fromDate === null || formik.values.fromDate === "") &&
        formik.values.toDate !== null &&
        formik.values.toDate !== ""
      ) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.REPORT_MODULE,
          alertDescription: Constants.CUSTOMER_CREDIT_ACCOUNT_REPORT_FROM_DATE_REQUIRED,
        });
      } else {
        myContext.handleLoading(true);
        // if (formik.values.selectedLoactions.length > 0) {
        const allCustomersData =
          formik.values.selectedCustomers.length > 0
            ? formik.values.selectedCustomers
            : customers;
        let data = {
          location: formik.values.selectedLoactions,
          allCustomers: customers,
          customer: formik.values.selectedCustomers,
          paidTypes: formik.values.selectedPaymentType,
          zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          query: {
            limit: paginationModel.pageSize,
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
          },
        };
        // if (formik.values.fromDate && formik.values.toDate) {
        //   data.dateFrom = reportDateFormate(formik.values.fromDate);
        //   data.dateTo = reportDateFormate(formik.values.toDate);
        //   data.formatFromDate = reportDateFormate(formik.values.fromDate);
        //   data.formatToDate = reportDateFormate(formik.values.toDate);
        // } else {
        //   data.formatFromDate = reportDateFormate(new Date());
        //   data.formatToDate = reportDateFormate(new Date());
        // }
        if (formik.values.fromDate != null && formik.values.fromDate != "") {
          data.dateFrom = reportDateFormate(formik.values.fromDate);
          data.formatFromDate = reportDateFormate(formik.values.fromDate);
          // data.dateTo = reportDateFormate(formik.values.toDate);
        }
        if (formik.values.toDate != null && formik.values.toDate != "") {
          // data.dateFrom = reportDateFormate(formik.values.fromDate);
          data.dateTo = reportDateFormate(formik.values.toDate);
          data.formatToDate = reportDateFormate(formik.values.toDate);
        }
        try {
          downloadPDF(data)
            .then((response) => {
              if (
                undefined !== response.data.flag &&
                null !== response.data.flag &&
                response.data.flag == true
              ) {
                var url = Constants.REACT_APP_API_URL + response.data.data;
                // var url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
                window.open(url, "_blank");
                setLoading(false);
                myContext.handleLoading(false);
              } else {
                setLoading(false);
                let actionData = response;
                error({ actionData });
                myContext.handleLoading(false);
              }
            })
            .catch((err) => {
              setLoading(false);
              let actionData = err;
              error({ actionData });
              myContext.handleLoading(false);
            });
        } catch (error) {
          setLoading(false);
          myContext.handleLoading(false);
          // }
        }
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const handleOrderUnpaidAmount = () => {
    setButtonDisable(true);
    myContext.handleLoading(true);
    let data = {
      external_reference: formik.values.external_reference,
      order_id: orderId,
      payment_amount: formik.values.payment_amount,
    };
    try {
      payOrderUnpaidAmount(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.TOAST_HEADING_SUCCESS,
              alertDescription: response.data.message,
            });
            formik.setFieldValue("external_reference", "");
            onPayClose();
            getCustomerCreditData();
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const onCloseEmailModal = () => {
    onClose();
    setValidationErrors("");
  };
  const onPayCloseModal = () => {
    onPayClose();
    formik.setFieldValue("payment_amount", "");
    formik.setFieldValue("external_reference", "");
  };
  const onOrderDrawerClose = () => {
    onDrawerClose();
    setOrderIdForView("");
  };

  const renderReportLayout = () => {
    return (
      <>
        <Stack
          className="card justify-content-center"
          flexDirection={isSmall ? "column" : "row"}
          spacing={4}
          width={isSmall ? "100% !important" : "auto"}
          justifyContent={"space-between"}
        >
          <Box width={isSmall ? "100% !important" : "33% !important"}>
            <PosDropDown
              options={locations}
              value={formik.values.selectedLoactions}
              resetFilterOnHide={true}
              onChange={handleOptionSelect}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                    ? "17rem"
                    : isMeduim1
                      ? "13.32rem"
                      : "100%"
              }
              onBlur={(e) => {
                formik.setFieldTouched("selectedLoactions");
                formik.handleBlur(e);
              }}
              multiSelect={true}
              optionLabel="name"
              placeholder="Select Locations*"
              height={"2.5rem"}
              className="w-full md:w-20rem"
              maxSelectedLabels={2}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.selectedLoactions &&
                formik.errors.selectedLoactions ? (
                <span>{formik.errors.selectedLoactions}</span>
              ) : null}
            </Text>
          </Box>
          <Stack
            width={isSmall ? "100% !important" : "67% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"fromDate"}
                ref={calendarRef}
                value={formik.values.fromDate}
                label={"From Date"}
                onFocus={() => {
                  if (
                    null !== toDateCalendarRef.current &&
                    toDateCalendarRef.current
                  ) {
                    toDateCalendarRef.current.hide();
                  }
                  setToDateFocus(false);
                  setTimeout(() => {
                    setFromDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("fromDate", true);
                }}
                handleSelectData={hadleFromDateSelect}
                yearRange={"2000:2030"}
                width={
                  isExtraLarge
                    ? "20rem"
                    : isLarge
                      ? "17rem"
                      : isMeduim1
                        ? "13.32rem"
                        : "100%"
                }
                maxDate={dateTimeValue}
                viewDate={fromDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.fromDate && formik.errors.fromDate ? (
                  <span>{formik.errors.fromDate}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"toDate"}
                ref={toDateCalendarRef}
                value={formik.values.toDate}
                label={"To Date"}
                onFocus={() => {
                  if (null !== calendarRef.current && calendarRef.current) {
                    calendarRef.current.hide();
                  }
                  setFromDateFocus(false);
                  setTimeout(() => {
                    setToDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("toDate", true);
                }}
                handleSelectData={(e) => {
                  formik.setFieldValue("toDate", e.value);
                  setToDateView(e.value);
                }}
                yearRange={"2000:2030"}
                width={
                  isExtraLarge
                    ? "20rem"
                    : isLarge
                      ? "17rem"
                      : isMeduim1
                        ? "13.32rem"
                        : "100%"
                }
                minDate={
                  null !== formik.values.fromDate &&
                    "" !== formik.values.fromDate
                    ? formik.values.fromDate
                    : dateTimeValue
                }
                maxDate={dateTimeValue}
                viewDate={toDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.toDate && formik.errors.toDate ? (
                  <span>{formik.errors.toDate}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>
        </Stack>
        <Stack
          className="card justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          width={"100% !important"}
        >
          <Box width={isMeduim ? "100% !important" : "auto"}>
            <PosDropDown
              options={customers}
              value={formik.values.selectedCustomers}
              resetFilterOnHide={true}
              onChange={handleCustomerOptionSelect}
              onBlur={(e) => {
                formik.setFieldTouched("selectedCustomers");
                formik.handleBlur(e);
              }}
              multiSelect={true}
              optionLabel="name"
              placeholder="Select Customers"
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                    ? "17rem"
                    : isMeduim1
                      ? "13.32rem"
                      : "100%"
              }
              height={"2.5rem"}
              className="w-full md:w-20rem"
              maxSelectedLabels={2}
            />
          </Box>
          <Box width={isMeduim ? "100% !important" : "auto"}>
            <PosDropDown
              options={payment_type}
              value={formik.values.selectedPaymentType}
              onChange={handlePaymentOptionSelect}
              resetFilterOnHide={true}
              onBlur={(e) => {
                formik.setFieldTouched("selectedPaymentType");
                formik.handleBlur(e);
              }}
              multiSelect={true}
              optionLabel="label"
              placeholder="Payment Type"
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                    ? "17rem"
                    : isMeduim1
                      ? "13.32rem"
                      : "100%"
              }
              height={"2.5rem"}
              className="w-full md:w-20rem"
              maxSelectedLabels={2}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.selectedPaymentType &&
                formik.errors.selectedPaymentType ? (
                <span>{formik.errors.selectedPaymentType}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
      </>
    );
  };

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <SingleOrderView
        visible={isDrawerOpen}
        onHide={onOrderDrawerClose}
        position="right"
        showCloseIcon={true}
        orderId={orderIdForView}
        breadCrumNames={[]}
        handleClick={(i) => {
          // if (i == 0) {
          //   props.navigate(Constants.REPORT_PATH);
          // }
        }}
      />
      <Modal
        isOpen={isPayOpen}
        onClose={onPayCloseModal}
        isCentered
        size={"2xl"}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <PosListHeader
              header={"This will be paid using Credit on the Account"}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={"0.94rem"} fontWeight={500}>
              Credit Amount : ${customersCreditAmount}
            </Text>
            <Text mt={2} fontSize={"0.94rem"} fontWeight={500}>
              Unpaid Amount : ${orderUnpaidAmount}
            </Text>
            <Stack spacing={1}>
              <PosLable
                fontWeight={500}
                name={"Payment Amount"}
                requiredLabel={true}
              ></PosLable>
              {/* <PosInput
                posNumberInput={true}
                id={"payment_amount"}
                inputValue={formik.values.payment_amount}
                handleBlur={formik.handleBlur}
                // handleInputChange={formik.handleChange}
                handleInputChange={(e) => {
                  let newBalance = 0;
                  if (
                    undefined != e.target.value &&
                    null != e.target.value &&
                    "" != e.target.value
                  ) {
                    if (parseFloat(e.target.value) > customersCreditAmount) {
                      addToast({
                        alertStatus: Constants.TOAST_TYPE_WARNING,
                        alertTitle: Constants.TOAST_HEADING_WARNING,
                        alertDescription: `Payment amount must be less than or equal to the credit balance $${customersCreditAmount}.`,
                      });
                    } else if (parseFloat(e.target.value) > orderUnpaidAmount) {
                      addToast({
                        alertStatus: Constants.TOAST_TYPE_WARNING,
                        alertTitle: Constants.TOAST_HEADING_WARNING,
                        alertDescription: `Payment amount must be less than or equal to $${orderUnpaidAmount}.`,
                      });
                    } else {
                      newBalance =
                        customersCreditAmount - parseFloat(e.target.value);
                      setRemainingCreditAmount(newBalance.toFixed(2));
                      formik.handleChange(e);
                    }
                  } else {
                    formik.handleChange(e);
                    newBalance =
                      customersCreditAmount - formik.values.payment_amount;
                    setRemainingCreditAmount(parseFloat(newBalance));
                  }
                }}
              ></PosInput> */}

              <PosInput
                posNumberInput={true}
                id={"payment_amount"}
                inputValue={formik.values.payment_amount}
                handleBlur={(e) => {
                  // formik.setFieldValue("payment_amount", twofix(e.target.value));
                  const value = e.target.value;
                  let newBalance = 0;
                  // setButtonDisable(false)/

                  if (undefined !== value && null !== value && "" !== value) {
                    const parsedValue = parseFloat(value);
                    if (parsedValue > customersCreditAmount) {
                      addToast({
                        alertStatus: Constants.TOAST_TYPE_WARNING,
                        alertTitle: Constants.TOAST_HEADING_WARNING,
                        alertDescription: `Payment amount must be less than or equal to the credit balance $${customersCreditAmount}.`,
                      });
                    } else if (parsedValue > orderUnpaidAmount) {
                      addToast({
                        alertStatus: Constants.TOAST_TYPE_WARNING,
                        alertTitle: Constants.TOAST_HEADING_WARNING,
                        alertDescription: `Payment amount must be less than or equal to $${orderUnpaidAmount}.`,
                      });
                      // setButtonDisable(true)
                    } else {
                      newBalance = customersCreditAmount - parsedValue;
                      setRemainingCreditAmount(newBalance.toFixed(2));
                    }
                  } else {
                    newBalance =
                      customersCreditAmount - formik.values.payment_amount;
                    setRemainingCreditAmount(parseFloat(newBalance));
                  }
                  formik.handleBlur(e);
                }}
                handleInputChange={(e) => {
                  formik.handleChange(e);
                  const value = e.target.value;
                  if (undefined !== value && null !== value && "" !== value) {
                    const parsedValue = parseFloat(value);
                    setButtonDisable(false);
                    if (parsedValue > customersCreditAmount) {
                      setButtonDisable(true);
                    } else if (parsedValue > orderUnpaidAmount) {
                      setButtonDisable(true);
                    } else {
                      setButtonDisable(false);
                    }
                  } else {
                    setButtonDisable(false);
                  }
                }}
              ></PosInput>
              <Text color={Colors.errorColor}>
                {formik.touched.payment_amount &&
                  formik.errors.payment_amount ? (
                  <span>{formik.errors.payment_amount}</span>
                ) : null}
              </Text>
            </Stack>
            <Stack spacing={1}>
              <PosLable
                fontWeight={500}
                label={true}
                name={"External Reference"}
              />
              <PosInput
                id="external_reference"
                placeholder={"Enter External Reference"}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.external_reference}
                handleBlur={(e) => {
                  formik.setFieldValue(
                    "external_reference",
                    formik.values.external_reference.trim()
                  );
                  formik.handleBlur(e);
                }}
                posInput={true}
                inputType={"text"}
                inputError={
                  formik.touched.external_reference &&
                  formik.errors.external_reference
                }
              ></PosInput>
              <Text color={Colors.errorColor}>
                {formik.touched.external_reference &&
                  formik.errors.external_reference ? (
                  <span>{formik.errors.external_reference}</span>
                ) : null}
              </Text>
            </Stack>
            {formik.values.payment_amount > 0 && (
              <Text mt={2} style={{ color: "#008000" }}>
                Remaining Credit Amount:
                <Text as="span" fontWeight="bold">
                  ${remainingCreditAmount}
                </Text>{" "}
                .
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <PosFormButton
              buttonsubmit={"Submit"}
              SubmitButton={true}
              isDisabled={
                formik.errors.payment_amount || buttonDisable ? true : false
              }
              onClick={handleOrderUnpaidAmount}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpen}
        onClose={onCloseEmailModal}
        isCentered
        size={"2xl"}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <PosListHeader header={"Send Invoice Email"} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>
          <Heading
            ml={8}
            as="h3"
            size="xs"
            fontWeight="500"
            fontSize="1.125rem"
            lineHeight="1.40625rem"
            letterSpacing="-0.016875rem"
            color="#010048"
          >
            Send Email To
          </Heading>
          <Box ml={8} mr={8} mt={2}>
            <Chips
              style={{
                backgroundColor: Colors.posviewbgcolor,
                display: "flex",
                padding: "0.1rem",
                paddingLeft: "0.1rem",
                gap: "0.1rem",
                borderRadius: "0.1rem",
                border: "1px solid #E3E3E3",
              }}
              addOnTab={true}
              onAdd={(e) => {
                emailValue(e.value, "dsr");
              }}
              onRemove={(e) => {
                handleRemove(e.value, "dsr");
              }}
              addOnBlur={true}
              allowDuplicate={false}
              placeholder="Add Email Addresses"
              value={emailCustomer}
            />
            <Text color={Colors.errorColor}>{validationErrors["dsr"]}</Text>
            {/* <Text color={Colors.errorColor}>
              {formik.touched.email && formik.errors.email ? (
                <span>{formik.errors.email}</span>
              ) : null}
            </Text> */}
          </Box>
          <ModalFooter>
            <PosFormButton
              buttonsubmit={"Submit"}
              SubmitButton={true}
              isDisabled={emailCustomer.length === 0}
              onClick={sendInvoiceEmail}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Customer Invoices & Statement"]}
          breadCrumTitle={"Customer Invoices & Statement"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Export
              </MenuButton>
              <MenuList>
                <MenuItem onClick={handleExportReport}>Export Excel</MenuItem>
                <MenuItem onClick={handlePdfReport}>Export PDF</MenuItem>
              </MenuList>
            </Menu>
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isMeduim ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <Stack
            gap={4}
            flex={1}
            flexShrink={"0"}
            direction={"column"}
            m={4}
            alignItems={"flex-start"}
          >
            {renderReportLayout()}
          </Stack>
          <Stack
             direction={"row"}
             justifyContent={isMeduim ? "flex-start" : "flex-end"}
             alignItems={"flex-start"}
             pl={isLarge ? 4 : 4}
             pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              isDisabled={applyClicked ? false : true}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.selectedLoactions?.length === 0 ||
                //   formik.values.fromDate === "" ||
                //   formik.values.toDate === "" ||
                formik.errors.selectedLoactions ||
                formik.errors.fromDate ||
                formik.errors.toDate ||
                buttonDisable
              }
              onClick={getCustomerCreditData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                // noAction={true}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPageModel) => {
                  pageRef.current = true;
                  setPaginationModel(newPageModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  pageRef.current = true;
                  sortRef.current = sortModel;
                  setSortModel(newSortModel);
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.CUSTOMER_CREDIT_ACCOUNT_REPORT_CSV}
              />
              <Table>
                <TableHead sx={{ backgroundColor: Colors.modifierTable }}>
                  <TableRow>
                    {totalDailySales.map((column, index) => {
                      return (
                        <TableCell
                          key={index}
                          sx={{
                            fontWeight: 300,
                            fontSize: "1rem",
                            lineHeight: "1.18rem",
                            letterSpacing: "-1.5%",
                            color: Colors.posTextInfo,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {column}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              </Table>
            </Flex>
          ) : (
            <PosNoDataFound title={"There are no Customer Invoices and Statement."} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(CustomerCreditAccount);