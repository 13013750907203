import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useRadioGroup,
  useClipboard,
  useMediaQuery
} from "@chakra-ui/react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { useFormik } from "formik";
import { SelectButton } from "primereact/selectbutton";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSubmit,useLocation } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosDataGridTable,
  PosFormButton,
  PosIconButton,
  PosInput,
  PosLable,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTab,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getAllCds, sendingCdsCode } from "./CdsService";
import { GridToolbar } from "@mui/x-data-grid";

const CdsList = (props) => {
  const { addToast } = PosTostMessage();
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const [cdsData, setcdsData] = useState([]);
  // const [searchQuery, setSearchQuery] = useState("");
  // const [sortType, setSortType] = useState("desc");
  // const [sortColumn, setSortColumn] = useState("id");
  const [totalcount, setTotalCount] = useState(0);
  const [curruntTab, setCurruntTab] = useState(0);
  // const cancelRef = React.useRef();
  const [selectedCouponType, setSelectedCouponType] = useState("");
  const [cdsCode, setCdsCode] = useState("");
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const searchInputHideRef = useRef(false);
  const [allTouchedField, setAllTouchedField] = useState(false);

  const {
    isOpen: isRewardModalOpen,
    onOpen: onRewardModalOpen,
    onClose: onRewardModalClose,
  } = useDisclosure();
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
  });
  const formik = useFormik({
    initialValues: {
      cds_code: cdsCode,
      send_type: [],
      emailSelected: false,
      smsSelected: false,
      email: "",
      sms: "",
      modalOpen: isRewardModalOpen,
    },
    validationSchema: Yup.object({
      modalOpen: Yup.boolean(),
      cds_code: Yup.string().when("modalOpen", {
        is: true,
        then: (schema) =>
          schema
            .required(Constants.FIELD_NAME_REQUIRED)
            .max(191, Constants.FIELD_NAME_LENGTH_GREATER_THAN_191),
        otherwise: (schema) => schema.optional(),
      }),
      send_type: Yup.array().when("modalOpen", {
        is: true,
        then: (schema) => schema.min(1, Constants.SEND_TYPE_REQUIRED),
        otherwise: (schema) => schema.optional(),
      }),
      email: Yup.string().trim().when("emailSelected", {
        is: true,
        then: (schema) =>
          schema
            .required(Constants.VENDOR_EMAIL_REQUIRED)
            .email(Constants.VENDOR_EMAIL_NOT_VALID)
            .matches(Constants.EMAIL_REGEX, Constants.VENDOR_EMAIL_NOT_VALID)
            .max(191, Constants.VENDOR_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER),
        otherwise: (schema) => schema.optional(),
      }),
      sms: Yup.number().when("smsSelected", {
        is: true,
        then: (schema) =>
          schema
            .required(Constants.CDS_PHONE_REQUIRED)
            .integer(Constants.VENDOR_PHONE_NOT_VALID)
            .typeError(Constants.VENDOR_PHONE_NOT_VALID)
            .min(10000, Constants.CDS_PHONE_MUST_BE_5_DIGITS)
            .max(99999999999999, Constants.CDS_PHONE_LESS_THAN_15_DIGITS),
        otherwise: (schema) => schema.optional(),
      }),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      let payload = {
        ...values,
        code: formik.values.cds_code,
      };
      setButtonDisable(true);
      handleCloseDialog();
      myContext.handleLoading(true);
      setAllTouchedField(false);
      sendingCdsCode(payload)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.CDS_MODULE,
              alertDescription: response.data.message,
            });
            setButtonDisable(false);
            myContext.handleLoading(false);
            handleClose();
            onRewardModalClose();
          } else {
            myContext.handleLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    },
  });
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };

  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    { field: "name", headerName: "Name", sortable: true, ...actionColumn },
    { field: "codeChanged", headerName: "Code", sortable: false, ...actionColumn },
    { field: "location", headerName: "Location", sortable: false, ...actionColumn },
    { field: "pos_station", headerName: "POS Station", sortable: false, ...actionColumn },
    {
      field: "actions",
      headerName: "Actions",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width:150,
      renderCell: (params) => {
        const rowData = params.row;
        const sendCode = () => {
          setCdsCode(
            rowData.code
              .replace(/(.{4})/g, "$1 ")
              .toUpperCase()
              .trim()
          );
          onRewardModalOpen();
        };
        return (
          <Text
            onClick={sendCode}
            cursor="pointer"
            color={Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration="underline"
            fontWeight={600}
          >
            Send Code
          </Text>
        );
      },
    },
  ];

  const handleClose = () => {
    formik.resetForm();
    if (props.onRewardClose) {
      props.onRewardClose();
    }
  };
  const stateData = useLocation(); // get backraking data as param
  const [paginationModel, setPaginationModel] = useState({
    page: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.page:0,
    pageSize: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.limit:25,
  });
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [sortType, setSortType] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type
      : "desc"
  );
  const [sortModel, setSortModel] = useState([
    {
      field: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order:"id",
      sort: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type:"desc",
    },
  ]);
  const [sortColumn, setSortColumn] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order
      : "id"
  );
  const sortRef = useRef(sortModel);

  const tabNames = ["Active"];
  const [loading, setLoading] = useState(true);
  const [topLoading, setTopLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const effectRun = useRef(true);

  const handlePageChange = (newPage) => {
    effectRun.current = true;
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    effectRun.current = true;
    setRowsPerPage(newRowsPerPage);
  };
  const handleSortColumn = (column, sort) => {
    setLoading(true);
    setSortColumn(column.sortName);
    effectRun.current = true;
    setSortType(sort);
  };
  const handleCloseDialog = () => {
    onRewardModalClose();
  };
  const fetchData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllCds(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            myContext.handleLoading(false);
            setcdsData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
          } else {
            myContext.handleLoading(false);
            setcdsData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          myContext.handleLoading(false);
          setcdsData([]);
          setLoading(false);
          setTotalCount(0);
          let actionData = err;
          error({ actionData });
        });
    } catch (error) {
      myContext.handleLoading(false);
      setcdsData([]);
      setTotalCount(0);
    }
  };
  useEffect(() => {
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      }
      effectRun.current = false;
    }
  }, [paginationModel, sortModel, page, loading,searchQuery]);

  const handleTabChange = (index) => {
    // tab change time set currunt tab and pagination related clear all data
    setCurruntTab(index);
    setLoading(true);
    setSearchQuery("");
    setPaginationModel({
      page: 0,
      pageSize:25,
    });
    setSortModel([
      {
        field:"id",
        sort: "desc",
      },
    ]);
    effectRun.current = true;
    searchInputHideRef.current = false;
  };
  const handleSearchList = () => {
    props.navigate(Constants.CDS_LIST);
    paginationModel.page = 0;
    // search time get active and deactive list
    if (curruntTab == 0) {
      fetchData();
    }
  };
  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.CDS_LIST);
  }

  const modifiedData = cdsData.map((cds) => ({
    id: cds.id,
    name: cds.name,
    codeChanged: cds.code
      .replace(/(.{4})/g, "$1 ")
      .toUpperCase()
      .trim(),
    location: cds.location.name,
    pos_station: cds.pos_station.name,
    code: cds.code,
  }));
  const CardRadio = ({ children, isChecked, ...props }) => {
    return (
      <Box>
        {/* <input type="radio" {...props} style={{ display: "none" }} /> */}
        <Box
          cursor="pointer"
          // borderRadius="100rem"
          h={"2.125rem"}
          w={"5.125rem"}
          // boxShadow="md"
          // color={"#5881FE"}
          // borderColor={ "gray.300"}
          // _hover={{
          //   bg: isChecked ? "messenger" : "#934CE5.100",
          // }}
          _checked={{
            bg: "teal.600",
            // color: "white",
            borderColor: "#5881FE",
          }}
          px={1}
          py={1}
        >
          {children}
        </Box>
      </Box>
    );
  };
  const handleCouponTypeChange = (value) => {
    setSelectedCouponType(value);
    formik.setFieldValue("send_type", value);
  };
  const {
    getRootProps: getCouponTypeRootProps,
    getRadioProps: getCouponTypeRadioProps,
  } = useRadioGroup({
    name: "value",
    defaultValue: "",
    onChange: handleCouponTypeChange,
  });
  const couponTypeOptions = [
    {
      title: "Email",
      value: "email",
      icon: (
        <EmailIcon color={Colors.taxInfo} style={{ fill: Colors.taxInfo }} />
      ),
    },
    {
      title: "SMS",
      value: "sms",
      icon: <TextsmsIcon style={{ fill: Colors.taxInfo }} />,
    },
  ];
  const itemTemplate = (option, index) => {
    const radio = getCouponTypeRadioProps({
      value: option.value,
    });
    return (
      <CardRadio
        key={index}
        {...radio}
        isChecked={formik.values.send_type.includes(option.value)}
      >
        <Flex
          // color={
          //   formik.values.send_type.includes(option.value)
          //     ? "white"
          //     : Colors.posTextInfo
          // }
          flexDirection="row"
          alignItems="left"
          gap={{ base: "1rem", md: 1 }}
        >
          {option.icon}
          <Text
            fontFamily={"Lexend, sans-serif;"}
            fontWeight="bold"
            // color={
            //   formik.values.send_type.includes(option.value)
            //     ? "white"
            //     : Colors.posTextInfo
            // }
          >
            {option.title}
          </Text>
        </Flex>
        <Text
          fontFamily={"Lexend, sans-serif;"}
          textAlign={"left"}
          mt={"0.63rem"}
          // color={
          //   formik.values.send_type.includes(option.value)
          //     ? "white"
          //     : Colors.posTextInfo
          // }
        ></Text>
      </CardRadio>
    );
  };
  // const handleCopyClick = () => {
  //   navigator.clipboard.writeText(cdsCode);
  //   addToast({
  //     alertStatus: Constants.TOAST_TYPE_SUCESS,
  //     alertTitle: Constants.CDS_MODULE,
  //     alertDescription: Constants.CDS_COPIED,
  //   });
  // };
  const { onCopy, hasCopied } = useClipboard(cdsCode);
  // const handleCopyClick = () => {
  //   const textArea = document.createElement('textarea');
  //   textArea.value = cdsCode;
  //   textArea.style.position = 'fixed';
  //   document.body.appendChild(textArea);
  //   textArea.focus();
  //   textArea.select();
  //   try {
  //     document.execCommand('copy');
  //     addToast({
  //       alertStatus: Constants.TOAST_TYPE_SUCESS,
  //       alertTitle: Constants.CDS_MODULE,
  //       alertDescription: Constants.CDS_COPIED,
  //     });
  //   } catch (err) {
  //     addToast({
  //       alertStatus: Constants.TOAST_TYPE_WARNING,
  //       alertTitle: Constants.CDS_MODULE,
  //       alertDescription: 'Failed to copy text to clipboard.',
  //     });
  //   }
  // };
  const copyClick = () => {
    onCopy();
    addToast({
      alertStatus: Constants.TOAST_TYPE_SUCESS,
      alertTitle: Constants.CDS_MODULE,
      alertDescription: Constants.CDS_COPIED,
    });
  }
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"Customer Display Screens"}
          discription={"Manage Your Customer Display Screens"}
        />
        {/* <Spacer /> */}
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              myContext.handleLoading(true);
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page,
                order:
                  sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
                filter: searchQuery,
              }
              props.navigate(Constants.CDS_CREATE,{
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {/* <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            gap={{ base: 2, md: 0 }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              onChange={(e) => {
                let value =searchQuery.length > 0 ? 
                e.target.value: e.target.value.trim();
                setSearchQuery(value);
              }}
              onSearch={handleSearchList}
              handleClear={handleClear} 
            />
            <Spacer />
          </Flex> */}
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          <TabPanels>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                <PosDataGridTable
                  columns={columnNames}
                  rows={modifiedData}
                  totalcount={totalcount}
                  paginationModel={paginationModel}
                  paginationMode="server"
                  sortingMode="server"
                  onPaginationModelChange={(newPaginationModel) => {
                    effectRun.current = true;
                    setPaginationModel(newPaginationModel);
                  }}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  columnVisibilityModel={olColumnVisibilityModel}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => {
                    sortRef.current = sortModel;
                    effectRun.current = true;
                    setSortModel(newSortModel);
                  }}
                  fileName={Constants.ACTIVE_CDS_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound title={Constants.CDS_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel></TabPanel>
          </TabPanels>
        </Tabs>
        <>
          <Modal
            isOpen={isRewardModalOpen}
            // onClose={onRewardModalClose}
            size={"lg"}
            scrollBehavior={"inside"}
          >
            <ModalOverlay />
            <ModalContent backgroundColor={Colors.loginAuthBackground}>
              <ModalHeader>Send Your CDS Code</ModalHeader>
              {/* <ModalCloseButton /> */}
              <ModalBody>
                <PosLable
                  fontWeight={500}
                  name={"Your Customer Display Code"}
                  label={true}
                />
                <InputGroup size="md">
                  <Input
                    disabled
                    variant="filled"
                    pr="6rem"
                    value={formik.values.cds_code}
                    type={"text"}
                  />
                  <InputRightElement width="3rem" gap={1}>
                    <Button h="2.1rem" size="md" onClick={copyClick}>
                      <ContentCopyIcon />
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <PosLable
                  label={true}
                  fontWeight={500}
                  requiredLabel={true}
                  name={"Send Code To"}
                />
                <Stack mt={2} direction={{ base: "column", md: "row" }}>
                  <SelectButton
                    id="send_type"
                    name="send_type"
                    value={formik.values.send_type}
                    options={couponTypeOptions}
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (
                        e.value.includes("email") &&
                        e.value.includes("sms")
                      ) {
                        if (!formik.values.emailSelected) {
                          formik.setFieldValue("emailSelected", true);
                          formik.setFieldValue('email','');
                        } else if (!formik.values.smsSelected) {
                          formik.setFieldValue("smsSelected", true);
                          formik.setFieldValue('sms','');
                        }
                      } else if (e.value.includes("email")) {
                        if (!formik.values.emailSelected) {
                          formik.setFieldValue("emailSelected", true);
                          formik.setFieldValue('email','');
                        } else if (formik.values.smsSelected) {
                          formik.setFieldValue("smsSelected", false);
                          formik.setFieldValue('sms','');
                        }
                      } else if (e.value.includes("sms")) {
                        if (formik.values.emailSelected) {
                          formik.setFieldValue("emailSelected", false);
                          formik.setFieldValue('email','');
                        } else if (!formik.values.smsSelected) {
                          formik.setFieldValue("smsSelected", true);
                          formik.setFieldValue('sms','');
                        }
                      } else {
                        if (formik.values.emailSelected) {
                          formik.setFieldValue("emailSelected", false);
                          formik.setFieldValue('email','');
                        } else if (formik.values.smsSelected) {
                          formik.setFieldValue("smsSelected", false);
                          formik.setFieldValue('sms','');
                        }
                      }
                      if (e.value.length > 0) {
                        formik.setFieldError('send_type', undefined);
                        formik.setFieldTouched('send_type', false);
                      }
                    }}
                    optionLabel="title"
                    optionValue="value"
                    multiple={true}
                    itemTemplate={itemTemplate}
                  />
                </Stack>
                <Text color={Colors.errorColor}>
                  {formik.touched.send_type && formik.errors.send_type ? (
                    <span>{formik.errors.send_type}</span>
                  ) : null}
                </Text>

                {formik.values.send_type.includes("email") && (
                  <Stack spacing={1} mt="0.31rem">
                    <PosLable
                      fontWeight={"500"}
                      requiredLabel={true}
                      name={"Email Address"}
                    />
                    <PosInput
                      id="email"
                      placeholder={"Enter Email Address"}
                      handleInputChange={formik.handleChange}
                      inputValue={formik.values.email}
                      handleBlur={formik.handleBlur}
                      posInput={true}
                      inputType={"text"}
                      inputError={formik.touched.email && formik.errors.email}
                    />
                    <Text color={Colors.errorColor}>
                      {formik.touched.email && formik.errors.email ? (
                        <span>{formik.errors.email}</span>
                      ) : null}
                    </Text>
                  </Stack>
                )}
                {formik.values.send_type.includes("sms") && (
                  <Stack spacing={1} mt="0.31rem">
                    <PosLable
                      fontWeight={"500"}
                      requiredLabel={true}
                      name={"Phone Number"}
                      label={true}
                    ></PosLable>
                    <PosInput
                      id="sms"
                      posNumberInput={true}
                      // posInput={true}
                      inputType={"number"}
                      placeholder={"Enter Phone Number"}
                      handleInputChange={formik.handleChange}
                      inputValue={formik.values.sms}
                      handleBlur={formik.handleBlur}
                      inputError={formik.touched.sms && formik.errors.sms}
                    />
                    <Text color={Colors.errorColor}>
                      {formik.touched.sms && formik.errors.sms ? (
                        <span>{formik.errors.sms}</span>
                      ) : null}
                    </Text>
                  </Stack>
                )}
              </ModalBody>
              <ModalFooter>
                <Flex mb={"2rem"}>
                  <PosFormButton
                    buttonText={"Cancel"}
                    CancelButton={true}
                    // ref={cancelRef}
                    onClick={handleCloseDialog}
                  />
                  <Button
                    colorScheme="messenger"
                    ml={3}
                    onClick={() => {
                      if (!formik.isValid) {
                        formik.setTouched({
                          send_type: true,
                          email: formik.values.emailSelected,
                          sms: formik.values.smsSelected,
                          cds_code: true
                        });
                        
                        if (!allTouchedField) {
                          formik.handleSubmit();
                          setAllTouchedField(true);
                        }
                      } else {
                        formik.handleSubmit();
                      }
                    }}
                    // onClick={formik.handleSubmit}
                    // isDisabled={
                    //   formik.values.cds_code.length <= 0 ||
                    //   formik.errors.cds_code ||
                    //   formik.errors.send_type ||
                    //   formik.values.send_type.length <= 0 ||
                    //   buttonDisable ||
                    //   (formik.values.emailSelected == true &&
                    //     (formik.values.email == null || formik.values.email == "" || formik.errors.email)) ||
                    //   (formik.values.smsSelected == true &&
                    //     (formik.values.sms == null || formik.values.sms == "" || formik.errors.sms))
                    //     ? true
                    //     : false
                    // }
                    isDisabled={buttonDisable}
                  >
                    {"Send Code"}
                  </Button>
                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      </Box>
    </Box>
  );
};
export default WithRouter(CdsList);
