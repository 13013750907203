import {
  Box,
  ButtonGroup,
  ChakraProvider,
  Divider,
  Flex,
  Heading,
  Image,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Checkbox, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider } from "@mui/material/styles";
import { GridToolbar } from "@mui/x-data-grid";
import Tippy from "@tippyjs/react";
import _ from "lodash";
import moment from "moment";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import Colors from "../../../assets/colors/Colors";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { Mtheme, theme } from "../../../theme/index";
import {
  createSegmentRewardCard,
  getFiltersList,
  getRewardCardList,
  showUsers,
} from "./SegmentService";

const RadioCard = ({ children, ...props }) => {
  const { getInputProps, getRadioProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getRadioProps();
  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius={props.borderRadius}
        boxShadow="md"
        _checked={{
          // bg: 'teal.600',
          borderWidth: "2px",
          color: "white",
          borderColor: "#5881FE",
          borderRadius: props.borderRadius,
        }}
        _focus={
          {
            // boxShadow: 'outline',
          }
        }
        maxW={props.maxWidth}
        flexDirection={"column"}
      >
        {children}
      </Box>
    </Box>
  );
};

const SegmentCreate = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const stateData = useLocation();
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const submit = useSubmit();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const effectRun = useRef(true);
  const effectPaginationRun = useRef(false);
  const [selectUsersFromDialogError, setSelectUsersFromDialogError] = useState(
    null
  );
  const [flag, setFlag] = useState(true);
  const [name, setName] = useState(null);

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedSegmentType, setSelectedSegmentType] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortColumn, setSortColumn] = useState("id");
  const [allRewardCards, setAllRewardCards] = useState([]);
  const [totalcount, setTotalCount] = useState(0);

  const [filterRewardCards, setFilterRewardCards] = useState([]);
  const [totalFilterCount, setFilterTotalCount] = useState(0);

  const [loading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsersData] = useState([]);
  const [newData, setNewData] = useState(false);
  // Smart Group
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  const filterType = [
    { name: "Predefined Filter", code: "PRE" },
    { name: "Custom Filter", code: "CUST" },
  ];
  const [selectedCustomFilters, setSelectedCustomFilters] = useState(null);
  const [customFilters, setCustomFilters] = useState(null);
  const [selectedPredefinedFilters, setSelectedPredefinedFilters] = useState(
    null
  );
  const [predefinedFilters, setPredefinedFilters] = useState(null);
  const [categoryFilters, setCategoryFilters] = useState(null);

  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectCatFilter, setSelectCatFilter] = useState(null);
  const [selectedPurchaseDates, setSelectedPurchaseDates] = useState(null);
  const [selectedPurchaseDate, setSelectedPurchaseDate] = useState(null);
  const [selectedDateRanges, setSelectedDateRanges] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedDates, setSelectedDates] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const calendarRef = useRef(Calendar);
  const fromCalendarRef = useRef(Calendar);
  const toCalendarRef = useRef(Calendar);
  const birthFromCalendarRef = useRef(Calendar);
  const birthToCalendarRef = useRef(Calendar);

  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [onDateView, setOnDateView] = useState(null);

  const [startFromBetweenDate, setFromDate] = useState(null);
  const [startToBetweenDate, setToDate] = useState(null);

  const [birthStartFromBetweenDate, setBirthFromDate] = useState(null);
  const [birthStartToBetweenDate, setBirthToDate] = useState(null);

  const [purchaseNumber, setPurchaseNumber] = useState(null);

  const [selectItemFilters, setSelectItemFilters] = useState(null);
  const [selectItemFilter, setSelectItemFilter] = useState(null);

  const [selectedVisitDates, setSelectedVisitDates] = useState(null);
  const [selectedVisitDate, setSelectedVisitDate] = useState(null);
  const [visitNumber, setVisitNumber] = useState(null);
  const [lastVisitNumber, setLastVisitNumber] = useState(null);

  const [selectSpentFilters, setSelectSpentFilters] = useState(null);
  const [selectSpent, setSelectSpent] = useState(null);
  const [spentGreaterNumber, setSpentGreaterNumber] = useState(null);
  const [spentLessNumber, setSpentLessNumber] = useState(null);
  const [spentEqualNumber, setSpentEqualNumber] = useState(null);
  const [spentStartNumber, setSpentStartNumber] = useState(null);
  const [spentEndNumber, setSpentEndNumber] = useState(null);

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);

  const [showTable, setShowTable] = useState(true);
  const [showUserTable, setShowUserTable] = useState(false);

  const [dates, setDates] = useState(null);

  const [errors, setErrors] = useState({
    name: "",
    purchaseNumber: "",
    visitNumber: "",
    lastVisitNumber: "",
    spentGreaterNumber: "",
    spentLessNumber: "",
    spentEqualNumber: "",
    spentStartNumber: "",
    spentEndNumber: "",
  });

  const [checkedItems, setCheckedItems] = React.useState([]);
  const [viewSelectedRewardCards, setViewSelectedRewardCards] = React.useState(
    []
  );
  const checkRef = useRef(checkedItems);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const sortRef = useRef(sortModel);

  const [paginationShowUserModel, setPaginationShowUserModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortShowUserModel, setSortShowUserModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const sortShowUserRef = useRef(sortShowUserModel);

  const handleSegmentTypeChange = (value) => {
    setSelectedSegmentType(value);
  };

  const {
    getRootProps: getSegmentTypeRootProps,
    getRadioProps: getSegmentTypeRadioProps,
    setValue: setSegmentTypeValue,
  } = useRadioGroup({
    name: "type",
    defaultValue: "",
    onChange: (value) => {
      handleSegmentTypeChange(value);
      handleRadioSelect(value);
    },
  });
  const segmentTypeGroup = getSegmentTypeRootProps();

  const segmentTypeOptions = [
    {
      title: "Manual",
      body: "Option to use manually add people to the segment",
      type: "MANUAL",
      icon: <EditIcon style={{ fill: Colors.primeTaxInfo }} />,
      value: "Select Manual",
    },
    {
      title: "Smart Group",
      body: "Option to use the automatic feature powered by AI",
      type: "GROUP",
      icon: <EditIcon style={{ fill: Colors.primeTaxInfo }} />,
      value: "Select Smart Group",
    },
  ];
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const columnProductViewNames = [
    {
      columnNames: "Id",
      sortName: "",
      width: "",
    },
    {
      columnNames: "First Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Last Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Email Address",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Phone Number",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Action",
      sortName: "",
      width: "",
    },
  ];

  const columnNames = [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      width: 90,
    },
    {
      headerName: "First Name",
      field: "first_name",
      sortable: true,
      ...actionColumn,
    },
    {
      headerName: "Last Name",
      field: "last_name",
      sortable: true,
      ...actionColumn,
    },
    {
      headerName: "Email Address",
      field: "email",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      headerName: "Phone Number",
      field: "phone",
      sortable: true,
      ...actionColumn,
    },
  ];

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          setLocations(loaderResponse.data.data?.locations);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        props.navigate(Constants.SEGMENT_LIST, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.SEGMENT_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse]);

  useEffect(() => {
    handleSelectedRewardCardDisplay();
  }, [newData]);

  const handleDateTypeChange = (e) => {
    setSelectedDateRange(e.value);
    setPurchaseNumber("");
    setSelectedDate(null);
    setOnDateView("");
    setFromDate("");
    setToDate("");
  };
  const hadleOnDateSelect = (e) => {
    setOnDateView(e.value);
  };

  const hadleStartDateSelect = (e) => {
    if (
      null !== startToBetweenDate &&
      null != startToBetweenDate &&
      "" !== startToBetweenDate
    ) {
      if (moment(startToBetweenDate).isBefore(e.value)) {
        setFromDate(e.value);
        setToDate(e.value);
      } else {
        setFromDate(e.value);
      }
    } else {
      setFromDate(e.value);
    }
  };
  const hadleToDateSelect = (e) => {
    setToDate(e.value);
  };

  const hadleBirthStartDateSelect = (e) => {
    if (
      null !== birthStartToBetweenDate &&
      null != birthStartToBetweenDate &&
      "" !== birthStartToBetweenDate
    ) {
      if (moment(birthStartToBetweenDate).isBefore(e.value)) {
        setBirthFromDate(e.value);
        setBirthToDate(e.value);
      } else {
        setBirthFromDate(e.value);
      }
    } else {
      setBirthFromDate(e.value);
    }
  };

  const hadleBirthToDateSelect = (e) => {
    setBirthToDate(e.value);
  };

  const rewardCardColumnNames = [
    {
      field: "selection",
      headerName: "",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 90,
      renderCell: (params) => (
        <IconButton aria-label="select" sx={{ color: "#5881FE" }}>
          <Checkbox
            // checked={selectedUsers.some((user) => user.id === params.row.id)}
            checked={checkedItems.includes(params.row.id)}
            onChange={() => handleCheckboxChange(params)}
          />
        </IconButton>
      ),
    },
    {
      field: "first_name",
      headerName: "First Name",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "last_name",
      headerName: "Last name",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "email",
      headerName: "Email Address",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "last_shopped",
      headerName: "Last Shopped",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "total_spend",
      headerName: "Total Spend($)",
      sortable: true,
      ...actionColumn,
    },
  ];

  const modifiedData = allRewardCards.map((data) => ({
    id: data.id,
    first_name:
      data.first_name == null || data.first_name == ""
        ? "N.A."
        : data.first_name,
    last_name:
      data.last_name == null || data.last_name == "" ? "N.A." : data.last_name,
    email: data.email == null || data.email == "" ? "N.A." : data.email,
    phone: data.phone,
    last_shopped:
      data.last_shopped == null || data.last_shopped == ""
        ? data.last_shopped == 0
          ? 0 + " Day(s) Ago"
          : "N.A."
        : data.last_shopped + " Day(s) Ago",
    total_spend:
      data.total_spend == null || data.total_spend == ""
        ? 0.0
        : data.total_spend,
  }));

  const modifiedViewData = viewSelectedRewardCards.map((data) => ({
    id: data.id,
    first_name:
      data.first_name == null || data.first_name == ""
        ? "N.A."
        : data.first_name,
    last_name:
      data.last_name == null || data.last_name == "" ? "N.A." : data.last_name,
    email: data.email == null || data.email == "" ? "N.A." : data.email,
    phone: data.phone,
  }));

  const modifiedShowUserData = filterRewardCards.map((data) => ({
    id: data.id,
    first_name:
      data.first_name == null || data.first_name == ""
        ? "N.A."
        : data.first_name,
    last_name:
      data.last_name == null || data.last_name == "" ? "N.A." : data.last_name,
    email: data.email == null || data.email == "" ? "N.A." : data.email,
    phone: data.phone,
  }));

  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    {}
  );

  const handleCheckboxChange = (params) => {
    const rowId = params.id;
    let data = [];
    if (checkedItems.includes(rowId)) {
      let index = checkedItems.findIndex((x) => x === rowId);
      if (index > -1) {
        data = [
          ...checkedItems.slice(0, index),
          ...checkedItems.slice(index + 1),
        ];
        setCheckedItems(data);
      }
    } else {
      data = [...checkedItems, rowId];
      setCheckedItems(data);
    }

    let selectedData = [];
    let index = selectedUsers.findIndex((x) => x?.id === rowId);
    if (index > -1) {
      // let index = selectedUsers.findIndex((x) => x.id === rowId);
      // if (index > -1) {
      //   selectedData = [
      //     ...selectedUsers.slice(0, index),
      //     ...selectedUsers.slice(index + 1),
      //   ];
      //   setSelectedUsersData(selectedData);
      // }
    } else {
      selectedData = [...selectedUsers, params.row];
      setSelectedUsersData(selectedData);
    }
  };

  const handleSelectedRewardCardDelete = (id) => {
    const updatedCheckedItems = checkedItems.filter((itemId) => itemId !== id);
    setCheckedItems(updatedCheckedItems);
    setNewData(!newData);
  };

  const handleSelectedRewardCardDisplay = () => {
    if (checkedItems.length == 0) {
      setSelectUsersFromDialogError({
        message: "Please select at least one user.",
      });
    } else {
      setSelectUsersFromDialogError(null);
      onClose();
    }
    checkRef.current = checkedItems;
    // onClose();
    const selectedRewardCards = selectedUsers.filter((users) =>
      checkedItems.includes(users.id)
    );
    setSearchQuery("");
    setViewSelectedRewardCards(selectedRewardCards);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
  };

  const handleClear = () => {
    // clear input value and get all data
    // effectRun.current = true;
    setSearchQuery("");
    paginationModel.page = 0;
    effectPaginationRun.current = true;
  };

  const handleSearchList = () => {
    paginationModel.page = 0;
    getRewardCard();
  };

  const getRewardCard = async () => {
    let data = {
      segment_type: selectedSegmentType,
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
      location_id: selectedLocation?.id,
    };
    setLoading(true);
    try {
      getRewardCardList(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setLoading(false);
            setAllRewardCards(response.data.data.data);
            setTotalCount(response.data?.data?.totalItems);
            onOpen();
            myContext.handleLoading(false);
          } else {
            setAllRewardCards([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setAllRewardCards([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setAllRewardCards([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (effectPaginationRun.current === true) {
      getRewardCard();
      effectPaginationRun.current = false;
    }
  }, [paginationModel, sortModel, searchQuery]);

  const handleRadioSelect = (value) => {
    setSelectedFilterType("");
    setSelectedCustomFilters("");
    setSelectedPredefinedFilters("");
    setSelectedPurchaseDate("");
    setSelectedDateRange([]);
    setPurchaseNumber("");
    setSelectedDate("");
    setOnDateView("");
    setFromDate("");
    setToDate("");
    setBirthFromDate("");
    setBirthToDate("");
    setSelectCatFilter("");
    setSelectedCategory([]);
    setSelectItemFilter("");
    setSelectedProducts([]);
    setVisitNumber("");
    setLastVisitNumber("");
    setSelectedVisitDate([]);
    setSelectSpent("");
    setSpentGreaterNumber("");
    setSpentLessNumber("");
    setSpentStartNumber("");
    setSpentEndNumber("");
    setSpentEqualNumber("");
    setShowUserTable(false);
    setCheckedItems([]);
    setViewSelectedRewardCards([]);
    setSearchQuery("");
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setPaginationShowUserModel({
      page: 0,
      pageSize: 25,
    });
    setSortShowUserModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    if (null != value && "" != value && value == "GROUP") {
      // setCheckedItems([]);
      // setViewSelectedRewardCards([]);

      let payload = {
        type: selectedSegmentType,
        locationID: selectedLocation?.id,
      };
      try {
        getFiltersList(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              setLoading(false);
              let allFilters = response?.data?.data;

              setCustomFilters(allFilters?.customFilters);
              setPredefinedFilters(allFilters?.predefinedFilters);

              setCategoryFilters(allFilters?.categoryFilter);
              setCategories(allFilters?.category);
              setSelectedPurchaseDates(allFilters?.purchaseDate);
              setSelectedDateRanges(allFilters?.dateRange);
              setSelectedDates(allFilters?.date);
              setSelectItemFilters(allFilters?.item);
              setSelectedVisitDates(allFilters?.visitDateAll);
              setSelectSpentFilters(allFilters?.spent);
              setProducts(allFilters?.product);

              myContext.handleLoading(false);
            } else {
              setLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setLoading(false);
        myContext.handleLoading(false);
      }
    }
    // else if (null != value && "" != value && value == "MANUAL") {
    //   setSelectedFilterType("");
    //   setSelectedCustomFilters("");
    //   setSelectedPredefinedFilters("");
    //   setSelectedPurchaseDate("");
    //   setSelectedDateRange([]);
    //   setPurchaseNumber("");
    //   setSelectedDate("");
    //   setOnDateView("");
    //   setFromDate("");
    //   setToDate("");
    //   setSelectCatFilter("");
    //   setSelectedCategory([]);
    //   setSelectItemFilter("");
    //   setSelectedProducts([]);
    //   setVisitNumber("");
    //   setLastVisitNumber("");
    //   setSelectedVisitDate([]);
    //   setSelectSpent("");
    //   setSpentGreaterNumber("");
    //   setSpentLessNumber("");
    //   setSpentStartNumber("");
    //   setSpentEndNumber("");
    //   setSpentEqualNumber("");
    // }
  };

  const search = (event) => {
    setTimeout(() => {
      let _filteredProducts;
      if (!event.query.trim().length) {
        _filteredProducts = [...products];
      } else {
        _filteredProducts = products.filter((product) => {
          return product.name
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredProducts(_filteredProducts);
    }, 250);
  };

  const handleChangeFilterTypes = (e) => {
    setSelectedFilterType(e.value);
    setSelectedCustomFilters("");
    setSelectedPredefinedFilters("");
    setSelectedPurchaseDate("");
    setSelectedDateRange([]);
    setPurchaseNumber("");
    setSelectedDate("");
    setOnDateView("");
    setFromDate("");
    setToDate("");
    setSelectCatFilter("");
    setSelectedCategory([]);
    setSelectItemFilter("");
    setSelectedProducts([]);
    setVisitNumber("");
    setLastVisitNumber("");
    setSelectedVisitDate([]);
    setSelectSpent("");
    setSpentGreaterNumber("");
    setSpentLessNumber("");
    setSpentStartNumber("");
    setSpentEndNumber("");
    setSpentEqualNumber("");
    setBirthFromDate("");
    setBirthToDate("");
    setShowUserTable(false);
  };

  const handleAdvanceFilterChange = (e) => {
    setSelectedCustomFilters(e.value);
    const hasCat = e.value.some((item) => item.name === "category");
    const hasDate = e.value.some((item) => item.name === "date");
    const hasItem = e.value.some((item) => item.name === "item");
    const hasVisit = e.value.some((item) => item.name === "visit");
    const hasSpent = e.value.some((item) => item.name === "spent");
    const hasBirth = e.value.some((item) => item.name === "birth");

    if (!hasCat) {
      setSelectCatFilter("");
      setSelectedCategory([]);
    }

    if (!hasDate) {
      setSelectedPurchaseDate("");
      setSelectedDateRange([]);
      setPurchaseNumber("");
      setSelectedDate("");
      setOnDateView("");
      setFromDate("");
      setToDate("");
    }

    if (!hasItem) {
      setSelectItemFilter("");
      setSelectedProducts([]);
    }

    if (!hasVisit) {
      setVisitNumber("");
      setLastVisitNumber("");
      setSelectedVisitDate([]);
    }

    if (!hasSpent) {
      setSelectSpent("");
      setSpentGreaterNumber("");
      setSpentLessNumber("");
      setSpentStartNumber("");
      setSpentEndNumber("");
      setSpentEqualNumber("");
    }

    if (!hasBirth) {
      setBirthFromDate("");
      setBirthToDate("");
    }

    setShowUserTable(false);
  };

  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const handleAllInputValueChangeValidation = (event) => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    const { value } = event.target;
    let data = errors;
    switch (event.target.id) {
      case "name":
        setName(value);
        data.name =
          !value || value.length === 0
            ? Constants.SEGMENT_NAME_FIELD_REQUIRED
            : value.length > 191
            ? Constants.SEGMENT_NAME_MAX_VALUE
            : "";
        break;
      case "purchaseNumber":
        setPurchaseNumber(value);
        data.purchaseNumber =
          !value || value.length === 0
            ? Constants.SEGMENT_RANGE_NUMBER_FIELD_REQUIRED
            : !isPositiveInteger(value)
            ? Constants.SEGMENT_RANGE_VALID_NUMBER
            : value < 1
            ? Constants.SEGMENT_RANGE_NUMBER_MIN_ERROR
            : value > 999999
            ? Constants.SEGMENT_RANGE_NUMBER_MAX_ERROR
            : "";
        break;
      case "visitNumber":
        setVisitNumber(value);
        data.visitNumber =
          !value || value.length === 0
            ? Constants.SEGMENT_MIN_VISIT_NUMBER_ERROR
            : !isPositiveInteger(value)
            ? Constants.SEGMENT_MIN_VISIT_NOT_VALID_NUMBER_ERROR
            : value < 1
            ? Constants.SEGMENT_MIN_VISIT_NUMBER_MIN_ERROR
            : value > 999999
            ? Constants.SEGMENT_MIN_VISIT_NUMBER_MAX_ERROR
            : "";
        break;
      case "lastVisitNumber":
        setLastVisitNumber(value);
        data.lastVisitNumber =
          !value || value.length === 0
            ? Constants.SEGMENT_LAT_VISIT_NUMBER_FIELD_REQUIRED
            : !isPositiveInteger(value)
            ? Constants.SEGMENT_LAT_VISIT_NOT_VALID_NUMBER
            : value < 1
            ? Constants.SEGMENT_LAT_VISIT_NUMBER_MIN_ERROR
            : value > 999999
            ? Constants.SEGMENT_LAT_VISIT_NUMBER_MAX_ERROR
            : "";
        break;
      case "spentEqualNumber":
        // setSpentEqualNumber(value);
        data.spentEqualNumber =
          !value || value.length === 0
            ? Constants.SEGMENT_SPENT_AMOUNT_FIELD_REQUIRED
            : isNaN(twofix(value))
            ? Constants.SEGMENT_SPENT_NOT_VALID_AMOUNT
            : twofix(value) < 0.01
            ? Constants.SEGMENT_SPENT_AMOUNT_MIN_ERROR
            : twofix(value) > 999999.99
            ? Constants.SEGMENT_SPENT_AMOUNT_MAX_ERROR
            : "";
        break;
      case "spentGreaterNumber":
        // setSpentGreaterNumber(value);
        data.spentGreaterNumber =
          !value || value.length === 0
            ? Constants.SEGMENT_SPENT_AMOUNT_FIELD_REQUIRED
            : isNaN(twofix(value))
            ? Constants.SEGMENT_SPENT_NOT_VALID_AMOUNT
            : twofix(value) < 0.01
            ? Constants.SEGMENT_SPENT_AMOUNT_MIN_ERROR
            : twofix(value) > 999999.99
            ? Constants.SEGMENT_SPENT_AMOUNT_MAX_ERROR
            : "";
        break;
      case "spentLessNumber":
        // setSpentLessNumber(value);
        data.spentLessNumber =
          !value || value.length === 0
            ? Constants.SEGMENT_SPENT_AMOUNT_FIELD_REQUIRED
            : isNaN(twofix(value))
            ? Constants.SEGMENT_SPENT_NOT_VALID_AMOUNT
            : twofix(value) < 0.01
            ? Constants.SEGMENT_SPENT_AMOUNT_MIN_ERROR
            : twofix(value) > 999999.99
            ? Constants.SEGMENT_SPENT_AMOUNT_MAX_ERROR
            : "";
        break;
      case "spentStartNumber":
        // setSpentStartNumber(value);
        let startValidation =
          !value || value.length === 0
            ? Constants.SEGMENT_SPENT_AMOUNT_FIELD_REQUIRED
            : isNaN(twofix(value))
            ? Constants.SEGMENT_SPENT_NOT_VALID_AMOUNT
            : twofix(value) < 0.01
            ? Constants.SEGMENT_SPENT_AMOUNT_MIN_ERROR
            : twofix(value) > 999999.99
            ? Constants.SEGMENT_SPENT_AMOUNT_MAX_ERROR
            : "";

        // Then check relationship with end number if both exist
        if (!startValidation && spentEndNumber && spentEndNumber.length > 0) {
          if (parseFloat(twofix(value)) > parseFloat(twofix(spentEndNumber))) {
            startValidation =
              Constants.SEGMENT_YOUR_MINIMUM_AMOUNT_CAN_NOT_EXCEED_MAXIMUM_AMOUNT;
          }
        }

        data.spentStartNumber = startValidation;

        // Update end number validation if it exists
        if (spentEndNumber && spentEndNumber.length > 0) {
          data.spentEndNumber =
            !spentEndNumber || spentEndNumber.length === 0
              ? Constants.SEGMENT_SPENT_AMOUNT_FIELD_REQUIRED
              : isNaN(twofix(spentEndNumber))
              ? Constants.SEGMENT_SPENT_NOT_VALID_AMOUNT
              : twofix(spentEndNumber) < 0.01
              ? Constants.SEGMENT_SPENT_AMOUNT_MIN_ERROR
              : twofix(spentEndNumber) > 999999.99
              ? Constants.SEGMENT_SPENT_AMOUNT_MAX_ERROR
              : parseFloat(twofix(spentEndNumber)) < parseFloat(twofix(value))
              ? Constants.SEGMENT_YOUR_MAXIMUM_AMOUNT_CAN_NOT_LESS_THAN_MINIMUM_AMOUNT
              : "";
        }
        break;
      case "spentEndNumber":
        // setSpentEndNumber(value);
        let endValidation =
          !value || value.length === 0
            ? Constants.SEGMENT_SPENT_AMOUNT_FIELD_REQUIRED
            : isNaN(twofix(value))
            ? Constants.SEGMENT_SPENT_NOT_VALID_AMOUNT
            : twofix(value) < 0.01
            ? Constants.SEGMENT_SPENT_AMOUNT_MIN_ERROR
            : twofix(value) > 999999.99
            ? Constants.SEGMENT_SPENT_AMOUNT_MAX_ERROR
            : "";
        // Then check relationship with start number if both exist
        if (!endValidation && spentStartNumber && spentStartNumber.length > 0) {
          if (
            parseFloat(twofix(value)) < parseFloat(twofix(spentStartNumber))
          ) {
            endValidation =
              Constants.SEGMENT_YOUR_MAXIMUM_AMOUNT_CAN_NOT_LESS_THAN_MINIMUM_AMOUNT;
          }
        }

        data.spentEndNumber = endValidation;

        // Update start number validation if it exists
        if (spentStartNumber && spentStartNumber.length > 0) {
          data.spentStartNumber =
            !spentStartNumber || spentStartNumber.length === 0
              ? Constants.SEGMENT_SPENT_AMOUNT_FIELD_REQUIRED
              : isNaN(twofix(spentStartNumber))
              ? Constants.SEGMENT_SPENT_NOT_VALID_AMOUNT
              : twofix(spentStartNumber) < 0.01
              ? Constants.SEGMENT_SPENT_AMOUNT_MIN_ERROR
              : twofix(spentStartNumber) > 999999.99
              ? Constants.SEGMENT_SPENT_AMOUNT_MAX_ERROR
              : parseFloat(twofix(spentStartNumber)) > parseFloat(twofix(value))
              ? Constants.SEGMENT_YOUR_MINIMUM_AMOUNT_CAN_NOT_EXCEED_MAXIMUM_AMOUNT
              : "";
        }
        break;
      default:
        break;
    }
    setErrors(data);
    setFlag(!flag);
  };

  const handleAllDropdownValueChangeValidation = (event, id) => {
    const { value } = event.target;
    let data = errors;
    switch (id) {
      case "selectedLocation":
        data.selectedLocation =
          !value || undefined === value
            ? Constants.SEGMENT_LOCATION_FIELD_REQUIRED
            : value?.length <= 0
            ? Constants.SEGMENT_LOCATION_FIELD_REQUIRED
            : "";
        break;
      case "selectedFilterType":
        data.selectedFilterType =
          !value || undefined === value
            ? Constants.SEGMENT_FILTER_TYPE_FIELD_REQUIRED
            : value?.length <= 0
            ? Constants.SEGMENT_FILTER_TYPE_FIELD_REQUIRED
            : "";
        break;
      case "selectedCustomFilters":
        data.selectedCustomFilters =
          !value || undefined === value
            ? Constants.SEGMENT_CUSTOM_FILTER_FIELD_REQUIRED
            : value?.length <= 0
            ? Constants.SEGMENT_CUSTOM_FILTER_FIELD_REQUIRED
            : "";
        break;
      case "selectedPredefinedFilters":
        data.selectedPredefinedFilters =
          !value || undefined === value
            ? Constants.SEGMENT_PREDEFINED_FILTER_FIELD_REQUIRED
            : value?.length <= 0
            ? Constants.SEGMENT_PREDEFINED_FILTER_FIELD_REQUIRED
            : "";
        break;
      case "selectCatFilter":
        data.selectCatFilter =
          !value || undefined === value
            ? Constants.SEGMENT_FILTER_FIELD_REQUIRED
            : value?.length <= 0
            ? Constants.SEGMENT_FILTER_FIELD_REQUIRED
            : "";
        break;
      case "selectedCategory":
        data.selectedCategory =
          !value || undefined === value
            ? Constants.SEGMENT_CATEGORIES_REQUIRED
            : value?.length <= 0
            ? Constants.SEGMENT_CATEGORIES_REQUIRED
            : "";
        break;
      case "selectedPurchaseDate":
        data.selectedPurchaseDate =
          !value || undefined === value
            ? Constants.SEGMENT_FILTER_FIELD_REQUIRED
            : value?.length <= 0
            ? Constants.SEGMENT_FILTER_FIELD_REQUIRED
            : "";
        break;
      case "selectedDateRange":
        data.selectedDateRange =
          !value || undefined === value
            ? Constants.SEGMENT_FILTER_FIELD_REQUIRED
            : value?.length <= 0
            ? Constants.SEGMENT_FILTER_FIELD_REQUIRED
            : "";
        break;
      case "selectedDate":
        data.selectedDate =
          !value || undefined === value
            ? Constants.SEGMENT_FILTER_FIELD_REQUIRED
            : value?.length <= 0
            ? Constants.SEGMENT_FILTER_FIELD_REQUIRED
            : "";
        break;
      case "selectItemFilter":
        data.selectItemFilter =
          !value || undefined === value
            ? Constants.SEGMENT_FILTER_FIELD_REQUIRED
            : value?.length <= 0
            ? Constants.SEGMENT_FILTER_FIELD_REQUIRED
            : "";
        break;
      case "selectedProducts":
        data.selectedProducts =
          selectedProducts?.length <= 0 ? "You must select a item." : "";
        break;
      case "selectedVisitDate":
        data.selectedVisitDate =
          !value || undefined === value
            ? Constants.SEGMENT_FILTER_FIELD_REQUIRED
            : value?.length <= 0
            ? Constants.SEGMENT_FILTER_FIELD_REQUIRED
            : "";
        break;
      case "selectSpent":
        data.selectSpent =
          !value || undefined === value
            ? Constants.SEGMENT_FILTER_FIELD_REQUIRED
            : value?.length <= 0
            ? Constants.SEGMENT_FILTER_FIELD_REQUIRED
            : "";
        break;
      default:
        break;
    }
    setErrors(data);
    setFlag(!flag);
  };

  const handleDeleteFilters = (name) => {
    // Filter out the object with the matching category
    const updatedFilters = selectedCustomFilters.filter(
      (filter) => filter.name !== name
    );

    // Set the updated filters using setSelectedCustomFilters
    setSelectedCustomFilters(updatedFilters);

    if (name == "category") {
      setSelectCatFilter("");
      setSelectedCategory([]);
    }

    if (name == "date") {
      setSelectedPurchaseDate("");
      setSelectedDateRange([]);
      setPurchaseNumber("");
      setSelectedDate("");
      setOnDateView("");
      setFromDate("");
      setToDate("");
    }

    if (name == "item") {
      setSelectItemFilter("");
      setSelectedProducts([]);
    }

    if (name == "visit") {
      setVisitNumber("");
      setLastVisitNumber("");
      setSelectedVisitDate([]);
    }

    if (name == "spent") {
      setSelectSpent("");
      setSpentGreaterNumber("");
      setSpentLessNumber("");
      setSpentStartNumber("");
      setSpentEndNumber("");
      setSpentEqualNumber("");
    }

    if (name == "birth") {
      setBirthFromDate("");
      setBirthToDate("");
    }
  };

  const handleShowUsers = () => {
    let showRewardCardFlag = 1;
    handleSubmit(showRewardCardFlag);
  };

  const handleSubmit = (showRewardCardFlag) => {
    // Trigger all validations first
    const nameValidation = {
      target: {
        id: "name",
        value: name || "",
      },
    };
    handleAllInputValueChangeValidation(nameValidation);

    const locationValidation = {
      target: {
        value: selectedLocation,
      },
    };
    handleAllDropdownValueChangeValidation(
      locationValidation,
      "selectedLocation"
    );

    // if (null == name || "" == name || name.length == 0) {
    //   addToast({
    //     alertStatus: Constants.TOAST_TYPE_WARNING,
    //     alertTitle: Constants.TOAST_HEADING_WARNING,
    //     alertDescription: Constants.SEGMENT_NAME_FIELD_REQUIRED,
    //   });
    // } else if (
    //   null == selectedLocation ||
    //   "" == selectedLocation ||
    //   selectedLocation.length == 0
    // ) {
    //   addToast({
    //     alertStatus: Constants.TOAST_TYPE_WARNING,
    //     alertTitle: Constants.TOAST_HEADING_WARNING,
    //     alertDescription: Constants.SEGMENT_LOCATION_FIELD_REQUIRED,
    //   });
    // } else {
    if (null != selectedSegmentType && "" != selectedSegmentType) {
      if (selectedSegmentType == "MANUAL") {
        if (
          null == viewSelectedRewardCards ||
          "" == viewSelectedRewardCards ||
          viewSelectedRewardCards.length == 0
        ) {
          // addToast({
          //   alertStatus: Constants.TOAST_TYPE_WARNING,
          //   alertTitle: Constants.TOAST_HEADING_WARNING,
          //   alertDescription: Constants.SEGMENT_MANUAL_USERS_REQUIRED,
          // });
        } else {
          const userIds = viewSelectedRewardCards?.map((user) => user.id);
          const matchedOption = segmentTypeOptions.find((option) => {
            return option.type === selectedSegmentType;
          });
          let payload = {
            name: name,
            type: {
              key: matchedOption?.type,
              value: matchedOption?.value,
            },
            location: {
              key: selectedLocation?.id,
              value: selectedLocation?.name,
            },
            fromCreateSegment: 1,
            pre: 0,
            userId: userIds,
          };
          try {
            setLoading(true);
            setButtonDisable(true);
            createSegmentRewardCard(payload)
              .then((response) => {
                if (
                  undefined !== response.data.flag &&
                  null !== response.data.flag &&
                  response.data.flag == true
                ) {
                  setLoading(false);
                  props.navigate(Constants.SEGMENT_LIST, {
                    state: stateData.state,
                  });
                  addToast({
                    alertStatus: Constants.TOAST_TYPE_SUCESS,
                    alertTitle: Constants.SEGMENT_MODULE,
                    alertDescription: response.data.message,
                  });
                  setButtonDisable(false);
                  myContext.handleLoading(false);
                } else {
                  setLoading(false);
                  setButtonDisable(false);
                  let actionData = response;
                  error({ actionData });
                  myContext.handleLoading(false);
                }
              })
              .catch((err) => {
                setLoading(false);
                setButtonDisable(false);
                let actionData = err;
                error({ actionData });
                myContext.handleLoading(false);
              });
          } catch (error) {
            setLoading(false);
            setButtonDisable(false);
            myContext.handleLoading(false);
          }
        }
      } else if (selectedSegmentType == "GROUP") {
        let submitDone = true;

        if (null != selectedFilterType && "" != selectedFilterType) {
          if (selectedFilterType?.code == "PRE") {
            if (
              null != selectedPredefinedFilters &&
              "" != selectedPredefinedFilters &&
              selectedPredefinedFilters.hasOwnProperty("name")
            ) {
              const matchedOption = segmentTypeOptions.find((option) => {
                return option.type === selectedSegmentType;
              });
              let payload = {
                name: name,
                type: {
                  key: matchedOption?.type,
                  value: matchedOption?.value,
                },
                location: {
                  key: selectedLocation?.id,
                  value: selectedLocation?.name,
                },
                filterType: {
                  name: selectedFilterType?.name,
                  value: selectedFilterType?.code,
                },
                fromCreateSegment: 1,
                pre: 1,
                predefinedFilter: {
                  label: selectedPredefinedFilters?.display_name,
                  value: selectedPredefinedFilters?.name,
                },
                customFilter: "",
              };
              if (showRewardCardFlag == 0) {
                if (submitDone) {
                  create(payload);
                }
              } else {
                if (submitDone) {
                  getSegmentRewardCard(payload);
                }
              }
            } else {
              submitDone = false;
              const predefinedFilterValidation = {
                target: {
                  value: selectedPredefinedFilters,
                },
              };
              handleAllDropdownValueChangeValidation(
                predefinedFilterValidation,
                "selectedPredefinedFilters"
              );
              // addToast({
              //   alertStatus: Constants.TOAST_TYPE_WARNING,
              //   alertTitle: Constants.TOAST_HEADING_WARNING,
              //   alertDescription:
              //     Constants.SEGMENT_PREDEFINED_FILTER_REQUIRED,
              // });
            }
          } else if (selectedFilterType?.code == "CUST") {
            if (
              null != selectedCustomFilters &&
              "" != selectedCustomFilters &&
              selectedCustomFilters.length > 0
            ) {
              // submitDone = false;
              const matchedOption = segmentTypeOptions.find((option) => {
                return option.type === selectedSegmentType;
              });
              // Common Payload for custom filter.
              let payload = {
                name: name,
                type: {
                  key: matchedOption?.type,
                  value: matchedOption?.value,
                },
                location: {
                  key: selectedLocation?.id,
                  value: selectedLocation?.name,
                },
                filterType: {
                  name: selectedFilterType?.name,
                  value: selectedFilterType?.code,
                },
                fromCreateSegment: 1,
                pre: 0,
                predefinedFilter: "",
                customFilter: selectedCustomFilters, //change display_name to label and name to value remaining
              };

              const hasCat = selectedCustomFilters.some(
                (item) => item.name === "category"
              );
              if (hasCat) {
                if (
                  selectCatFilter != null &&
                  selectCatFilter != "" &&
                  selectCatFilter.hasOwnProperty("name")
                ) {
                  if (selectedCategory.length > 0) {
                    // submitDone = true;
                    payload.selectCatFilter = {
                      label: selectCatFilter?.display_name,
                      value: selectCatFilter?.name,
                    };
                    // selectCatFilter; //change display_name to label and name to value remaining
                    payload.selectedCategory = selectedCategory;
                    payload.createCategoryString = selectCatFilter?.name;
                    payload.selectedCategoryNames = selectedCategory?.map(
                      (category) => category.name
                    );
                    // create(payload);
                  } else {
                    submitDone = false;
                    const categoryValidation = {
                      target: {
                        value: selectedCategory,
                      },
                    };
                    handleAllDropdownValueChangeValidation(
                      categoryValidation,
                      "selectedCategory"
                    );
                    // addToast({
                    //   alertStatus: Constants.TOAST_TYPE_WARNING,
                    //   alertTitle: Constants.TOAST_HEADING_WARNING,
                    //   alertDescription:
                    //     Constants.SEGMENT_SELECT_CATEGORY_EMPTY,
                    // });
                  }
                } else {
                  submitDone = false;
                  const catFilterValidation = {
                    target: {
                      value: selectCatFilter,
                    },
                  };
                  handleAllDropdownValueChangeValidation(
                    catFilterValidation,
                    "selectCatFilter"
                  );
                  // addToast({
                  //   alertStatus: Constants.TOAST_TYPE_WARNING,
                  //   alertTitle: Constants.TOAST_HEADING_WARNING,
                  //   alertDescription: Constants.SEGMENT_CAT_FILTER_REQUIRED,
                  // });
                }
              }

              const hasDate = selectedCustomFilters.some(
                (item) => item.name === "date"
              );

              if (hasDate) {
                if (
                  selectedPurchaseDate != null &&
                  selectedPurchaseDate != "" &&
                  selectedPurchaseDate.hasOwnProperty("name")
                ) {
                  payload.selectedPurchaseDate = {
                    label: selectedPurchaseDate?.display_name,
                    value: selectedPurchaseDate?.name,
                  };
                  if (
                    selectedDateRange != null &&
                    selectedDateRange != "" &&
                    selectedDateRange.hasOwnProperty("name")
                  ) {
                    payload.selectedDateRange = {
                      label: selectedDateRange?.display_name,
                      value: selectedDateRange?.name,
                    };
                    if (selectedDateRange.name == "date_range") {
                      if (
                        null != purchaseNumber &&
                        purchaseNumber.length > 0 &&
                        selectedDate != null &&
                        selectedDate != "" &&
                        selectedDate.hasOwnProperty("name")
                      ) {
                        if (purchaseNumber && errors.purchaseNumber) {
                          // Set submitDone based on validation results
                          submitDone = false;
                        } else {
                          if (isPositiveInteger(purchaseNumber)) {
                            payload.purchaseNumber = purchaseNumber;
                            payload.selectedDate = {
                              label: selectedDate?.display_name,
                              value: selectedDate?.name,
                            };
                            payload.createPurchaseStringString =
                              selectedPurchaseDate?.name;
                            payload.selectDateRangeCreateString =
                              selectedDateRange?.name;
                            payload.selectPurchaseDayCreateString =
                              selectedDate?.name;
                            payload.createFinalStringForPurchaseDate =
                              selectedPurchaseDate?.name +
                              "." +
                              selectedDateRange?.name +
                              "." +
                              selectedDate?.name;
                          } else {
                            submitDone = false;
                            const purchaseNumberValidation = {
                              target: {
                                id: "purchaseNumber",
                                value: purchaseNumber,
                              },
                            };
                            handleAllInputValueChangeValidation(
                              purchaseNumberValidation,
                              "purchaseNumber"
                            );
                            // addToast({
                            //   alertStatus: Constants.TOAST_TYPE_WARNING,
                            //   alertTitle: Constants.TOAST_HEADING_WARNING,
                            //   alertDescription:
                            //     Constants.SEGMENT_RANGE_VALID_NUMBER,
                            // });
                          }
                        }
                      } else {
                        submitDone = false;

                        const purchaseNumberValidation = {
                          target: {
                            id: "purchaseNumber",
                            value: purchaseNumber,
                          },
                        };
                        handleAllInputValueChangeValidation(
                          purchaseNumberValidation,
                          "purchaseNumber"
                        );

                        const selectedDateValidation = {
                          target: {
                            value: selectedDate,
                          },
                        };
                        handleAllDropdownValueChangeValidation(
                          selectedDateValidation,
                          "selectedDate"
                        );
                        // addToast({
                        //   alertStatus: Constants.TOAST_TYPE_WARNING,
                        //   alertTitle: Constants.TOAST_HEADING_WARNING,
                        //   alertDescription:
                        //     Constants.SEGMENT_ENTER_RANGE_NUMBER_AND_SELECT_DURATION,
                        // });
                      }
                    } else if (selectedDateRange.name == "on_date") {
                      if (null != onDateView && "" != onDateView) {
                        payload.start_date = onDateView;

                        var month = onDateView.getMonth() + 1;
                        var startDate =
                          onDateView.getFullYear() +
                          "-" +
                          month +
                          "-" +
                          onDateView.getDate();
                        payload.startDate = startDate;
                        payload.createPurchaseStringString =
                          selectedPurchaseDate?.name;
                        payload.selectDateRangeCreateString =
                          selectedDateRange?.name;
                        payload.createFinalStringForPurchaseDate =
                          selectedPurchaseDate?.name +
                          "." +
                          selectedDateRange?.name;
                      } else {
                        submitDone = false;
                        if (
                          null == onDateView ||
                          "" == onDateView ||
                          undefined == onDateView
                        ) {
                          setOnDateView("");
                        }
                        // addToast({
                        //   alertStatus: Constants.TOAST_TYPE_WARNING,
                        //   alertTitle: Constants.TOAST_HEADING_WARNING,
                        //   alertDescription:
                        //     Constants.SEGMENT_ON_DATE_REQUIRED,
                        // });
                      }
                    } else if (selectedDateRange.name == "between_date") {
                      if (
                        null != startFromBetweenDate &&
                        "" != startFromBetweenDate &&
                        null != startToBetweenDate &&
                        "" != startToBetweenDate
                      ) {
                        // submitDone = true;
                        payload.between_start_date = startFromBetweenDate;
                        payload.between_end_date = startToBetweenDate;

                        payload.createPurchaseStringString =
                          selectedPurchaseDate?.name;
                        payload.selectDateRangeCreateString =
                          selectedDateRange?.name;
                        payload.createFinalStringForPurchaseDate = "";
                        payload.createFinalStringForPurchaseDateStart =
                          selectedPurchaseDate?.name +
                          "." +
                          "between_start_date";
                        payload.createFinalStringForPurchaseDateEnd =
                          selectedPurchaseDate?.name + "." + "between_end_date";
                      } else {
                        submitDone = false;
                        if (
                          null == startFromBetweenDate ||
                          "" == startFromBetweenDate ||
                          undefined == startFromBetweenDate
                        ) {
                          setFromDate("");
                        }
                        if (
                          null == startToBetweenDate ||
                          "" == startToBetweenDate ||
                          undefined == startToBetweenDate
                        ) {
                          setToDate("");
                        }
                        // addToast({
                        //   alertStatus: Constants.TOAST_TYPE_WARNING,
                        //   alertTitle: Constants.TOAST_HEADING_WARNING,
                        //   alertDescription:
                        //     Constants.SEGMENT_FROM_DATE_AND_TO_DATE_REQUIRED,
                        // });
                      }
                    }
                  } else {
                    submitDone = false;
                    const dateTypeFilterValidation = {
                      target: {
                        value: selectedDateRange,
                      },
                    };
                    handleAllDropdownValueChangeValidation(
                      dateTypeFilterValidation,
                      "selectedDateRange"
                    );
                    // addToast({
                    //   alertStatus: Constants.TOAST_TYPE_WARNING,
                    //   alertTitle: Constants.TOAST_HEADING_WARNING,
                    //   alertDescription:
                    //     Constants.SEGMENT_DATE_TYPE_FILTER_REQUIRED,
                    // });
                  }
                } else {
                  submitDone = false;
                  const dateFilterValidation = {
                    target: {
                      value: selectedPurchaseDate,
                    },
                  };
                  handleAllDropdownValueChangeValidation(
                    dateFilterValidation,
                    "selectedPurchaseDate"
                  );
                  // addToast({
                  //   alertStatus: Constants.TOAST_TYPE_WARNING,
                  //   alertTitle: Constants.TOAST_HEADING_WARNING,
                  //   alertDescription: Constants.SEGMENT_FILTER_FIELD_REQUIRED,
                  // });
                }
              }

              const hasItem = selectedCustomFilters.some(
                (item) => item.name === "item"
              );

              if (hasItem) {
                if (
                  selectItemFilter != null &&
                  selectItemFilter != "" &&
                  selectItemFilter.hasOwnProperty("name")
                ) {
                  if (selectedProducts.length > 0) {
                    payload.selectItemFilter = {
                      label: selectItemFilter?.display_name,
                      value: selectItemFilter?.name,
                    };
                    payload.selectProduct = selectedProducts;
                    payload.createItemString = selectItemFilter?.name;
                    payload.selectedItemsNames = selectedProducts?.map(
                      (prod) => prod.name
                    );
                  } else {
                    submitDone = false;
                    const selectedProductsValidation = {
                      target: {
                        value: selectedProducts,
                      },
                    };
                    handleAllDropdownValueChangeValidation(
                      selectedProductsValidation,
                      "selectedProducts"
                    );
                    // addToast({
                    //   alertStatus: Constants.TOAST_TYPE_WARNING,
                    //   alertTitle: Constants.TOAST_HEADING_WARNING,
                    //   alertDescription: Constants.SEGMENT_SELECT_ITEM_EMPTY,
                    // });
                  }
                } else {
                  submitDone = false;
                  const itemFilterValidation = {
                    target: {
                      value: selectItemFilter,
                    },
                  };
                  handleAllDropdownValueChangeValidation(
                    itemFilterValidation,
                    "selectItemFilter"
                  );
                  // addToast({
                  //   alertStatus: Constants.TOAST_TYPE_WARNING,
                  //   alertTitle: Constants.TOAST_HEADING_WARNING,
                  //   alertDescription: Constants.SEGMENT_ITEM_FILTER_REQUIRED,
                  // });
                }
              }

              const hasVisit = selectedCustomFilters.some(
                (item) => item.name === "visit"
              );

              if (hasVisit) {
                // if (
                //   null != visitNumber &&
                //   "" != visitNumber &&
                //   null != lastVisitNumber &&
                //   "" != lastVisitNumber
                // ) {
                if (
                  null != visitNumber &&
                  "" != visitNumber &&
                  null != lastVisitNumber &&
                  "" != lastVisitNumber &&
                  selectedVisitDate?.hasOwnProperty("name")
                ) {
                  payload.selectedVisitDate = {
                    label: selectedVisitDate?.display_name,
                    value: selectedVisitDate?.name,
                  };

                  if (visitNumber || lastVisitNumber) {
                    // Set submitDone based on validation results
                    if (errors.visitNumber || errors.lastVisitNumber) {
                      submitDone = false;
                    }
                  }

                  if (!isPositiveInteger(visitNumber)) {
                    submitDone = false;
                    // addToast({
                    //   alertStatus: Constants.TOAST_TYPE_WARNING,
                    //   alertTitle: Constants.TOAST_HEADING_WARNING,
                    //   alertDescription:
                    //     Constants.SEGMENT_MIN_VISIT_NOT_VALID_NUMBER_ERROR,
                    // });
                  } else if (!isPositiveInteger(lastVisitNumber)) {
                    submitDone = false;
                    // addToast({
                    //   alertStatus: Constants.TOAST_TYPE_WARNING,
                    //   alertTitle: Constants.TOAST_HEADING_WARNING,
                    //   alertDescription:
                    //     Constants.SEGMENT_LAT_VISIT_NOT_VALID_NUMBER,
                    // });
                  } else {
                    payload.totalVisitNumber = visitNumber;
                    payload.totalLastVisitNumber = lastVisitNumber;
                    payload.selectedDayForVisit = selectedVisitDate?.name;
                  }
                } else {
                  submitDone = false;

                  const visitNumberValidation = {
                    target: {
                      id: "visitNumber",
                      value: visitNumber,
                    },
                  };
                  handleAllInputValueChangeValidation(
                    visitNumberValidation,
                    "visitNumber"
                  );

                  const lastVisitNumberValidation = {
                    target: {
                      id: "lastVisitNumber",
                      value: lastVisitNumber,
                    },
                  };
                  handleAllInputValueChangeValidation(
                    lastVisitNumberValidation,
                    "lastVisitNumber"
                  );

                  const selectedVisitDateValidation = {
                    target: {
                      value: selectedVisitDate,
                    },
                  };
                  handleAllDropdownValueChangeValidation(
                    selectedVisitDateValidation,
                    "selectedVisitDate"
                  );
                  // addToast({
                  //   alertStatus: Constants.TOAST_TYPE_WARNING,
                  //   alertTitle: Constants.TOAST_HEADING_WARNING,
                  //   alertDescription:
                  //     Constants.SEGMENT_DURATION_FILTER_REQUIRED,
                  // });
                }
                // } else {
                //   submitDone = false;
                //   // addToast({
                //   //   alertStatus: Constants.TOAST_TYPE_WARNING,
                //   //   alertTitle: Constants.TOAST_HEADING_WARNING,
                //   //   alertDescription:
                //   //     Constants.SEGMENT_MINIMUM_VIST_AND_LAST_VISIT_REQUIRED,
                //   // });
                // }
              }

              const hasSpent = selectedCustomFilters.some(
                (item) => item.name === "spent"
              );

              if (hasSpent) {
                if (
                  selectSpent != null &&
                  selectSpent != "" &&
                  selectSpent.hasOwnProperty("name")
                ) {
                  payload.selectSpent = {
                    label: selectSpent?.display_name,
                    value: selectSpent?.name,
                  };
                  if (selectSpent?.name == "greater_than") {
                    if (
                      null != spentGreaterNumber &&
                      "" != spentGreaterNumber
                    ) {
                      if (errors.spentGreaterNumber) {
                        submitDone = false;
                      } else {
                        payload.spentGreaterNumber = spentGreaterNumber;
                        payload.spentRecord = "spent_greater_than";
                        payload.finalSpentData = selectSpent?.name;
                        payload.finalSpentAmount = spentGreaterNumber;
                      }
                    } else {
                      submitDone = false;
                      const spentGreaterNumberValidation = {
                        target: {
                          id: "spentGreaterNumber",
                          value: spentGreaterNumber,
                        },
                      };
                      handleAllInputValueChangeValidation(
                        spentGreaterNumberValidation,
                        "spentGreaterNumber"
                      );
                      // addToast({
                      //   alertStatus: Constants.TOAST_TYPE_WARNING,
                      //   alertTitle: Constants.TOAST_HEADING_WARNING,
                      //   alertDescription:
                      //     Constants.SEGMENT_SPENT_AMOUNT_FIELD_REQUIRED,
                      // });
                    }
                  } else if (selectSpent?.name == "less_than") {
                    if (null != spentLessNumber && "" != spentLessNumber) {
                      if (errors.spentLessNumber) {
                        submitDone = false;
                      } else {
                        payload.spentLessNumber = spentLessNumber;
                        payload.spentRecord = "spent_less_than";
                        payload.finalSpentData = selectSpent?.name;
                        payload.finalSpentAmount = spentLessNumber;
                      }
                    } else {
                      submitDone = false;
                      const spentLessNumberValidation = {
                        target: {
                          id: "spentLessNumber",
                          value: spentLessNumber,
                        },
                      };
                      handleAllInputValueChangeValidation(
                        spentLessNumberValidation,
                        "spentLessNumber"
                      );
                      // addToast({
                      //   alertStatus: Constants.TOAST_TYPE_WARNING,
                      //   alertTitle: Constants.TOAST_HEADING_WARNING,
                      //   alertDescription:
                      //     Constants.SEGMENT_SPENT_AMOUNT_FIELD_REQUIRED,
                      // });
                    }
                  } else if (selectSpent?.name == "equal_to") {
                    if (null != spentEqualNumber && "" != spentEqualNumber) {
                      if (errors.spentEqualNumber) {
                        submitDone = false;
                      } else {
                        payload.spentEqualNumber = spentEqualNumber;
                        payload.spentRecord = "spent_equal_to";
                        payload.finalSpentData = selectSpent?.name;
                        payload.finalSpentAmount = spentEqualNumber;
                      }
                    } else {
                      submitDone = false;
                      const spentEqualNumberValidation = {
                        target: {
                          id: "spentEqualNumber",
                          value: spentEqualNumber,
                        },
                      };
                      handleAllInputValueChangeValidation(
                        spentEqualNumberValidation,
                        "spentEqualNumber"
                      );
                      // addToast({
                      //   alertStatus: Constants.TOAST_TYPE_WARNING,
                      //   alertTitle: Constants.TOAST_HEADING_WARNING,
                      //   alertDescription:
                      //     Constants.SEGMENT_SPENT_AMOUNT_FIELD_REQUIRED,
                      // });
                    }
                  } else if (selectSpent?.name == "between") {
                    if (
                      null != spentStartNumber &&
                      "" != spentStartNumber &&
                      null != spentEndNumber &&
                      "" != spentEndNumber
                    ) {
                      if (
                        parseFloat(spentStartNumber) >
                        parseFloat(spentEndNumber)
                      ) {
                        submitDone = false;
                        const spentStartNumberValidation = {
                          target: {
                            id: "spentStartNumber",
                            value: spentStartNumber,
                          },
                        };
                        handleAllInputValueChangeValidation(
                          spentStartNumberValidation,
                          "spentStartNumber"
                        );

                        const spentEndNumberValidation = {
                          target: {
                            id: "spentEndNumber",
                            value: spentEndNumber,
                          },
                        };
                        handleAllInputValueChangeValidation(
                          spentEndNumberValidation,
                          "spentEndNumber"
                        );
                        // addToast({
                        //   alertStatus: Constants.TOAST_TYPE_WARNING,
                        //   alertTitle: Constants.TOAST_HEADING_WARNING,
                        //   alertDescription:
                        //     Constants.SEGMENT_YOUR_ENDING_AMOUNT_CAN_NOT_EXCEED_STARTING_AMOUNT,
                        // });
                      } else {
                        if (errors.spentStartNumber || errors.spentEndNumber) {
                          submitDone = false;
                        } else {
                          payload.spentStartNumber = spentStartNumber;
                          payload.spentEndNumber = spentEndNumber;

                          payload.spentBetweenMinRecord = "spent_between_min";
                          payload.spentBetweenMaxRecord = "spent_between_max";
                          payload.spentRecord = "";
                          payload.finalSpentData = selectSpent?.name;
                          payload.finalSpentAmount = "";
                          payload.finalSpentStartAmount = spentStartNumber;
                          payload.finalSpentEndAmount = spentEndNumber;
                        }
                      }
                    } else {
                      submitDone = false;
                      const spentStartNumberValidation = {
                        target: {
                          id: "spentStartNumber",
                          value: spentStartNumber,
                        },
                      };
                      handleAllInputValueChangeValidation(
                        spentStartNumberValidation,
                        "spentStartNumber"
                      );

                      const spentEndNumberValidation = {
                        target: {
                          id: "spentEndNumber",
                          value: spentEndNumber,
                        },
                      };
                      handleAllInputValueChangeValidation(
                        spentEndNumberValidation,
                        "spentEndNumber"
                      );
                      // addToast({
                      //   alertStatus: Constants.TOAST_TYPE_WARNING,
                      //   alertTitle: Constants.TOAST_HEADING_WARNING,
                      //   alertDescription:
                      //     Constants.SEGMENT_MIN_AMOUNT_AND_MAX_AMOUNT_REQUIRED,
                      // });
                    }
                  }
                } else {
                  submitDone = false;
                  const spentFilterValidation = {
                    target: {
                      value: selectSpent,
                    },
                  };
                  handleAllDropdownValueChangeValidation(
                    spentFilterValidation,
                    "selectSpent"
                  );
                  // addToast({
                  //   alertStatus: Constants.TOAST_TYPE_WARNING,
                  //   alertTitle: Constants.TOAST_HEADING_WARNING,
                  //   alertDescription: Constants.SEGMENT_SPENT_FILTER_REQUIRED,
                  // });
                }
              }

              const hasBirth = selectedCustomFilters.some(
                (item) => item.name === "birth"
              );

              if (hasBirth) {
                if (
                  null != birthStartFromBetweenDate &&
                  "" != birthStartFromBetweenDate &&
                  null != birthStartToBetweenDate &&
                  "" != birthStartToBetweenDate
                ) {
                  // // submitDone = true;
                  var month = birthStartFromBetweenDate.getMonth() + 1;
                  var startDate =
                    // birthStartFromBetweenDate.getFullYear() +
                    // "-" +
                    month + "-" + birthStartFromBetweenDate.getDate();
                  payload.birth_between_start_date = startDate;

                  var month = birthStartToBetweenDate.getMonth() + 1;
                  var endDate =
                    // birthStartToBetweenDate.getFullYear() +
                    // "-" +
                    month + "-" + birthStartToBetweenDate.getDate();
                  payload.birth_between_end_date = endDate;

                  payload.createFinalStringForBirthDate = "";
                  payload.createFinalStringForBirthDateStart =
                    "birth_between_start_date";
                  payload.createFinalStringForBirthDateEnd =
                    "birth_between_end_date";
                } else {
                  submitDone = false;
                  if (
                    null == birthStartFromBetweenDate ||
                    "" == birthStartFromBetweenDate ||
                    undefined == birthStartFromBetweenDate
                  ) {
                    setBirthFromDate("");
                  }
                  if (
                    null == birthStartToBetweenDate ||
                    "" == birthStartToBetweenDate ||
                    undefined == birthStartToBetweenDate
                  ) {
                    setBirthToDate("");
                  }
                  // addToast({
                  //   alertStatus: Constants.TOAST_TYPE_WARNING,
                  //   alertTitle: Constants.TOAST_HEADING_WARNING,
                  //   alertDescription:
                  //     Constants.SEGMENT_BIRTH_FROM_DATE_AND_BIRTH_TO_DATE_REQUIRED,
                  // });
                }
              }

              if (showRewardCardFlag == 0) {
                if (submitDone) {
                  create(payload);
                }
              } else {
                if (submitDone) {
                  getSegmentRewardCard(payload);
                }
              }
            } else {
              submitDone = false;
              const customFilterValidation = {
                target: {
                  value: selectedCustomFilters,
                },
              };
              handleAllDropdownValueChangeValidation(
                customFilterValidation,
                "selectedCustomFilters"
              );
              // addToast({
              //   alertStatus: Constants.TOAST_TYPE_WARNING,
              //   alertTitle: Constants.TOAST_HEADING_WARNING,
              //   alertDescription: Constants.SEGMENT_CUSTOM_FILTER_REQUIRED,
              // });
            }
          } else {
            submitDone = false;
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.TOAST_HEADING_WARNING,
              alertDescription: Constants.SEGMENT_SELECT_FILTER_FIELD_REQUIRED,
            });
          }
        } else {
          submitDone = false;

          const filterTypeValidation = {
            target: {
              value: selectedFilterType,
            },
          };
          handleAllDropdownValueChangeValidation(
            filterTypeValidation,
            "selectedFilterType"
          );

          // addToast({
          //   alertStatus: Constants.TOAST_TYPE_WARNING,
          //   alertTitle: Constants.TOAST_HEADING_WARNING,
          //   alertDescription: Constants.SEGMENT_SELECT_FILTER_TYPE_REQUIRED,
          // });
        }
      } else {
        //Segment type not manual and not group
      }
    } else {
      // addToast({
      //   alertStatus: Constants.TOAST_TYPE_WARNING,
      //   alertTitle: Constants.TOAST_HEADING_WARNING,
      //   alertDescription: Constants.SEGMENT_SELECT_SEGMENT_TYPE_REQUIRED,
      // });
    }
    // }
  };

  const create = (payload) => {
    try {
      myContext.handleLoading(true);
      setButtonDisable(true);
      createSegmentRewardCard(payload)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setLoading(false);
            props.navigate(Constants.SEGMENT_LIST, {
              state: stateData.state,
            });
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.SEGMENT_MODULE,
              alertDescription: response.data.message,
            });
            setButtonDisable(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setButtonDisable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setButtonDisable(false);
      myContext.handleLoading(false);
    }
  };

  const getSegmentRewardCard = (payload) => {
    let data = {
      limit: paginationShowUserModel.pageSize,
      page: paginationShowUserModel.page + 1,
      order:
        sortShowUserModel.length > 0
          ? sortShowUserModel[0].field
          : sortShowUserRef.current[0].field,
      order_type:
        sortShowUserModel.length > 0
          ? sortShowUserModel[0].sort
          : sortShowUserRef.current[0].sort,
      filter: searchQuery,
      data: payload,
    };
    setShowTable(false);
    setShowUserTable(true);
    setLoading(true);
    try {
      showUsers(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setLoading(false);
            setFilterRewardCards(response.data.data.data);
            setFilterTotalCount(response.data.data.totalCount);
            myContext.handleLoading(false);
          } else {
            setFilterRewardCards([]);
            setFilterTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setFilterRewardCards([]);
          setFilterTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setFilterRewardCards([]);
      setFilterTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (filterRewardCards.length > 0) {
      handleShowUsers();
    }
  }, [paginationShowUserModel, sortShowUserModel]);

  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" w={isMobile ? "100vw" : "calc(100vw - 263.2px)"}>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              // pass param data in tax list page
              props.navigate(Constants.SEGMENT_LIST, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Segments", "Create a Segment"]}
            breadCrumTitle={"Create Segment"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                // pass param data in tax list page
                props.navigate(Constants.SEGMENT_LIST, {
                  state: stateData.state,
                });
              }}
              buttonText={"Cancel"}
              CancelButton={true}
              isDisabled={buttonDisable}
            />
            <PosFormButton
              isDisabled={buttonDisable ? true : false}
              // isDisabled={
              //   name?.length <= 0 ||
              //   errors?.name?.length > 0 ||
              //   selectedLocation?.length == "" ||
              //   errors?.selectedLocation?.length > 0 ||
              //   selectedSegmentType?.length == "" ||
              //   errors?.selectedSegmentType?.length > 0 ||
              //   buttonDisable
              //     ? true
              //     : false
              // }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={() => handleSubmit(0)}
              // onClick={() => {
              //   // submit time all validation fired and error massage display.
              //   // if (!formik.isValid) {
              //     if (!allTouchedField) {
              //       handleSubmit(0);
              //       setAllTouchedField(true);
              //     // }
              //     // addToast({
              //     //   alertStatus: Constants.TOAST_TYPE_ERROR,
              //     //   alertTitle: Constants.SUBMIT_TIME_FORM_NOT_VALID_TITLE,
              //     //   alertDescription: Constants.SUBMIT_TIME_FORM_NOT_VALID,
              //     // });
              //   } else {
              //     handleSubmit(0);
              //   }
              // }}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
          p="8"
        >
          <Text
            fontSize="1.125rem"
            w="10.5rem"
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Basic Details
          </Text>
          <Box className="card justify-content-center">
            <PosLable requiredLabel={true} name={"Name"} />

            <PosInput
              id="name"
              placeholder={""}
              handleInputChange={handleAllInputValueChangeValidation}
              inputValue={name || ""}
              handleBlur={handleAllInputValueChangeValidation}
              posInputGroup={true}
              inputType={"text"}
            >
              <DriveFileRenameOutlineIcon
                sx={{
                  height: "1.21rem",
                  width: "1.21rem",
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
            </PosInput>
            {errors.name.length > 0 ? (
              <Text color={Colors.errorColor}>{errors.name}</Text>
            ) : null}
          </Box>
          <Box className="card justify-content-center">
            <PosLable requiredLabel={true} name={"Location"} />
            <PosDropDown
              id="selectedLocation"
              value={selectedLocation}
              onChange={(e) => {
                setSelectedLocation(e.value);
                setSelectedSegmentType("");
                setSegmentTypeValue("");
                // Add validation on select
                handleAllDropdownValueChangeValidation(e, "selectedLocation");
              }}
              onBlur={(e) =>
                handleAllDropdownValueChangeValidation(e, "selectedLocation")
              }
              options={locations}
              optionLabel={"name"}
              className="w-full md:w-20rem"
              width={"100%"}
              placeholder={"Select  a Location"}
            ></PosDropDown>
            {errors?.selectedLocation?.length > 0 ? (
              <Text color={Colors.errorColor}>{errors?.selectedLocation}</Text>
            ) : null}
          </Box>
          {null != selectedLocation && "" != selectedLocation ? (
            <>
              <Box pt={"2rem"}>
                <Divider h={"0.05rem"} />
              </Box>
              <Flex flexDirection={"row"}>
                <Heading
                  mt={8}
                  fontWeight="500"
                  fontSize="1.13rem"
                  color="#010048"
                >
                  Segment Type*
                </Heading>
                <Tippy
                  content="Select from the two options. Option to manually add people to the segment or use the Automatic feature powered by AI."
                  interactive={true}
                  maxWidth={390}
                  placement="top"
                  animation="fade"
                  inertia={true}
                  moveTransition="transform 0.2s ease-out"
                  theme="tomato"
                >
                  <Image
                    src={toolTip}
                    alt="Tooltip"
                    mt={{ base: "0", md: "2rem" }}
                    ml={{ base: "0rem", md: "0.3rem" }}
                  />
                </Tippy>
              </Flex>
              {/* <Text fontWeight="300" color="#010048b3" fontSize="0.94rem">
                Select the method to add people to the segment
              </Text> */}

              <Stack
                mt={8}
                display={"flex"}
                flexDirection={!isMobile ? "row" : "column"}
                spacing={"2.13rem"}
                // mb={8}
                {...segmentTypeGroup}
                w={{ base: "100%", md: "44.12rem" }}
              >
                {segmentTypeOptions.map((option, index) => {
                  const radio = getSegmentTypeRadioProps({
                    value: option.type,
                  });
                  return (
                    <RadioCard
                      key={index}
                      isChecked={selectedSegmentType === option.type}
                      maxWidth="28.50rem"
                      borderRadius="0.63rem"
                      {...radio}
                    >
                      <Box
                        p={"1.5rem"}
                        bg={Colors.posPageDataBackground}
                        borderRadius="0.63rem"
                      >
                        <Flex flexDirection="row" alignItems="center" gap={2}>
                          <Box>{option.icon}</Box>
                          <Text
                            fontWeight={"500"}
                            fontSize={"md"}
                            color={Colors.posTextInfo}
                            textAlign="center"
                          >
                            {option.title}
                          </Text>
                        </Flex>
                        <Text
                          mt={"0.63rem"}
                          fontWeight={"300"}
                          fontSize={"md"}
                          color={Colors.posViewTextColor}
                        >
                          {option.body}
                        </Text>
                      </Box>
                    </RadioCard>
                  );
                })}
              </Stack>
              <Text color={Colors.errorColor} mb={8}>
                {selectedSegmentType == "" || selectedSegmentType == null
                  ? "Please select an Segment Type"
                  : null}
              </Text>
            </>
          ) : null}
        </Box>

        {selectedSegmentType == "MANUAL" ? (
          <Box
            mt={{ base: 2, lg: 2 }}
            mb={{ base: 4, lg: 4 }}
            ml={{ base: 4, lg: 14 }}
            mr={{ base: 4, lg: 14 }}
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
            p="8"
          >
            <Flex
              flexDirection={!isMobile ? "row" : "column"}
              alignItems="center"
              gap={2}
              color={"#010048"}
              fontWeight={500}
              fontSize={"1.13rem"}
            >
              <EditIcon style={{ fill: Colors.primeTaxInfo }} /> Manual Group
              Type
              <Spacer />
              {/* <Stack direction={"row"} alignSelf={"flex-end"}> */}
              <Flex flexDirection="column" alignItems="flex-end">
                <PosFormButton
                  buttonsubmit={"Add Users"}
                  SubmitButton={true}
                  onClick={getRewardCard}
                  // onClick={onOpen}
                />
                <Text
                  color={Colors.errorColor}
                  alignSelf={"flex-end"}
                  fontSize={"1rem"}
                  fontWeight={400}
                >
                  {viewSelectedRewardCards?.length == 0 ? (
                    <span>Please add users to the segment.</span>
                  ) : null}
                </Text>
              </Flex>
              {/* </Stack> */}
              <ThemeProvider theme={Mtheme}>
                <Dialog
                  open={isOpen}
                  // onClose={onClose}
                  fullWidth={true}
                  maxWidth={"lg"}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                      marginTop: "2.37rem",
                      fontSize: "1.5rem",
                      fontWeight: 600,
                      color: Colors.posTextInfo,
                    }}
                  >
                    {"Select Users"}
                    <ChakraProvider theme={theme}>
                      {/* <Box mt={3.5}>
                        <PosSearch
                          // mb={3.5}
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          onSearch={handleSearchList}
                          handleClear={handleClear}
                        />
                      </Box> */}
                      <Box mt={3.5}>
                        <Flex
                          minWidth="max-content"
                          gap="2"
                          mt={2}
                          mb={2}
                          alignItems="center"
                          flexDirection={{ base: "column", md: "row" }}
                        >
                          <PosSearch
                            value={searchQuery}
                            onChange={(e) => {
                              let value =
                                searchQuery.length > 0
                                  ? e.target.value
                                  : e.target.value.trim();
                              setSearchQuery(value);
                            }}
                            onSearch={handleSearchList}
                            handleClear={handleClear}
                          />
                          <Spacer />
                          <Flex flexDirection="column" alignItems="flex-end">
                            <ButtonGroup>
                              <PosFormButton
                                onClick={() => {
                                  setCheckedItems(checkRef.current);
                                  setSearchQuery("");
                                  setPaginationModel({
                                    page: 0,
                                    pageSize: 25,
                                  });
                                  setSortModel([
                                    {
                                      field: "id",
                                      sort: "desc",
                                    },
                                  ]);
                                  onClose();
                                }}
                                buttonText={"Cancel"}
                                CancelButton={true}
                              />
                              <PosFormButton
                                // isDisabled={
                                //   checkedItems.length == 0 ? true : false
                                // }
                                buttonsubmit={"Add Users"}
                                SubmitButton={true}
                                onClick={() => {
                                  handleSelectedRewardCardDisplay();
                                  // setCheckedItems(checkRef.current);
                                }}
                              />
                            </ButtonGroup>
                            {checkedItems?.length == 0 &&
                            selectUsersFromDialogError ? (
                              <Text
                                color={Colors.errorColor}
                                alignSelf={"flex-end"}
                                fontSize={"1rem"}
                                fontWeight={400}
                              >
                                {selectUsersFromDialogError.message}
                              </Text>
                            ) : null}
                          </Flex>
                        </Flex>
                      </Box>
                    </ChakraProvider>
                  </DialogTitle>
                  <DialogContent>
                    {loading ? (
                      <PosProgress />
                    ) : totalcount > 0 ? (
                      <PosDataGridTable
                        columns={rewardCardColumnNames}
                        rows={modifiedData}
                        totalcount={totalcount}
                        columnVisibilityModel={olColumnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                          setOlColumnVisibilityModel(newModel)
                        }
                        paginationModel={paginationModel}
                        paginationMode="server"
                        onPaginationModelChange={(data) => {
                          if (!_.isEqual(data, paginationModel)) {
                            effectPaginationRun.current = true;
                            setPaginationModel(data);
                          }
                        }}
                        sortModel={sortModel}
                        sortingMode={Constants.SORTING_AND_PAGINATION_MODE}
                        onSortModelChange={(newSortModel) => {
                          if (
                            !_.isEqual(newSortModel, sortModel) &&
                            newSortModel.length > 0
                          ) {
                            sortRef.current = sortModel;
                            effectPaginationRun.current = true;
                            setSortModel(newSortModel);
                          }
                        }}
                        slots={{
                          toolbar: GridToolbar,
                        }}
                        fileName={Constants.SEGMENT_USER_EXPORT_FILENAME}
                      />
                    ) : (
                      <PosNoDataFound
                        title={Constants.SEGMENT_REWARD_CARD_NOT_FOUND}
                      />
                    )}
                  </DialogContent>
                  {/* <DialogActions>
                    <ChakraProvider theme={theme}>
                      <Flex minWidth="max-content" gap="2" mt={4} mb={4}>
                        <Spacer />
                        <ButtonGroup>
                          <PosFormButton
                            onClick={() => {
                              setCheckedItems(checkRef.current);
                              setSearchQuery("");
                              setPaginationModel({
                                page: 0,
                                pageSize: 25,
                              });
                              setSortModel([
                                {
                                  field: "id",
                                  sort: "desc",
                                },
                              ]);
                              onClose();
                            }}
                            buttonText={"Cancel"}
                            CancelButton={true}
                          />
                          <PosFormButton
                            isDisabled={checkedItems.length == 0 ? true : false}
                            buttonsubmit={"Add Users"}
                            SubmitButton={true}
                            onClick={() => {
                              handleSelectedRewardCardDisplay();
                              // setCheckedItems(checkRef.current);
                            }}
                          />
                        </ButtonGroup>
                      </Flex>
                    </ChakraProvider>
                  </DialogActions> */}
                </Dialog>
              </ThemeProvider>
            </Flex>
            <Box>
              {viewSelectedRewardCards.length > 0 ? (
                <Box
                  borderRadius="0.63rem"
                  mt={8}
                  bg={Colors.posPageDataBackground}
                  boxShadow={
                    "0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow
                  }
                  // p="5"
                >
                  <PosTable
                    columnNames={columnProductViewNames}
                    rawdata={modifiedViewData}
                    // totalcount={totalcount}
                    // noAction={true}
                    footerHide={true}
                    singleDeleteButton={true}
                    goSingleDelete={(id) => handleSelectedRewardCardDelete(id)}
                    // checkBoxTable={true}
                    // checkedItems={checkedItems}
                    // handleCheckboxChange={handleCheckedItemsChange}
                    // spliteOptions={options}
                    // DectivatedId={handleDeactive}
                    // onPageChange={handlePageChange}
                    // onRowsPerPageChange={handleRowsPerPageChange}
                    // handleSortColumn={handleSortColumn}
                    sortColumnName={sortColumn}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    order={sortType}
                    // goEdit={(id) => handleEditPage(id)}
                  />
                </Box>
              ) : null}
            </Box>
          </Box>
        ) : selectedSegmentType == "GROUP" ? (
          <Box
            mt={{ base: 2, lg: 2 }}
            mb={{ base: 4, lg: 4 }}
            ml={{ base: 4, lg: 14 }}
            mr={{ base: 4, lg: 14 }}
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
            p="8"
          >
            <Flex
              flexDirection="row"
              alignItems="center"
              gap={2}
              color={"#010048"}
              fontWeight={500}
              fontSize={"1.13rem"}
            >
              <Flex flexDirection={"row"}>
                <Heading
                  mt={8}
                  fontWeight="500"
                  fontSize="1.13rem"
                  color="#010048"
                >
                  <EditIcon style={{ fill: Colors.primeTaxInfo }} /> Smart Group
                  Type
                </Heading>
                <Tippy
                  content="You can either have predefined filters created by us or can create a custom Filter. With the use of different filters such as category, price, and purchased, you can find the customer best suited to your target."
                  interactive={true}
                  maxWidth={390}
                  placement="top"
                  animation="fade"
                  inertia={true}
                  moveTransition="transform 0.2s ease-out"
                  theme="tomato"
                >
                  <Image
                    src={toolTip}
                    alt="Tooltip"
                    mt={{ base: "0rem", md: "2.5rem" }}
                    ml={{ base: "0rem", md: "0.3rem" }}
                  />
                </Tippy>
              </Flex>
              <Spacer />
              {/* <PosFormButton
              isDisabled={
                name?.length <= 0 ||
                errors?.name?.length > 0 ||
                selectedLocation?.length == "" ||
                errors?.selectedLocation?.length > 0 ||
                selectedSegmentType?.length == "" ||
                errors?.selectedSegmentType?.length > 0 ||
                buttonDisable
                  ? true
                  : false
              }
              buttonsubmit={"Show Users"}
              SubmitButton={true}
              onClick={handleShowUsers}
            /> */}
            </Flex>

            <Flex
              flexDirection={!isMobile ? "row" : "column"}
              gap={2}
              // alignItems="center"
              mt={3}
            >
              {/* <Box
                borderRadius="0.63rem"
                mt={3}
                bg={Colors.posPageDataBackground}
                boxShadow={
                  "0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow
                }
                // p="5"
              > */}

              <Box className="card justify-content-center">
                <PosDropDown
                  id="selectedFilterType"
                  value={selectedFilterType}
                  // onChange={handleChangeFilterTypes}
                  onChange={(e) => {
                    handleChangeFilterTypes(e);
                    // Add validation check directly in onChange
                    handleAllDropdownValueChangeValidation(
                      e,
                      "selectedFilterType"
                    );
                  }}
                  onBlur={(e) =>
                    handleAllDropdownValueChangeValidation(
                      e,
                      "selectedFilterType"
                    )
                  }
                  options={filterType}
                  optionLabel={"name"}
                  className="w-full md:w-14rem"
                  width={"100%"}
                  placeholder={"Filter Type"}
                ></PosDropDown>
                {errors?.selectedFilterType?.length > 0 ? (
                  <Text color={Colors.errorColor}>
                    {errors?.selectedFilterType}
                  </Text>
                ) : null}
              </Box>
              {selectedFilterType?.code == "CUST" ? (
                <Box className="card justify-content-center">
                  <PosDropDown
                    id="selectedCustomFilters"
                    options={customFilters}
                    value={selectedCustomFilters}
                    onChange={(e) => {
                      handleAdvanceFilterChange(e);
                      handleAllDropdownValueChangeValidation(
                        e,
                        "selectedCustomFilters"
                      );
                    }}
                    onBlur={(e) => {
                      e.target.value = selectedCustomFilters;
                      handleAllDropdownValueChangeValidation(
                        e,
                        "selectedCustomFilters"
                      );
                    }}
                    multiSelect={true}
                    optionLabel="display_name"
                    placeholder="Advanced Filters"
                    lableAvailable={true}
                    width={"100%"}
                    height={"2.5rem"}
                    className="w-full "
                    // maxSelectedLabels={2}
                  />
                  {errors?.selectedCustomFilters?.length > 0 ? (
                    <Text color={Colors.errorColor}>
                      {errors?.selectedCustomFilters}
                    </Text>
                  ) : null}
                </Box>
              ) : selectedFilterType?.code == "PRE" ? (
                <Box>
                  <PosDropDown
                    id="selectedPredefinedFilters"
                    value={selectedPredefinedFilters}
                    onChange={(e) => {
                      setSelectedPredefinedFilters(e.value);
                      handleAllDropdownValueChangeValidation(
                        e,
                        "selectedPredefinedFilters"
                      );
                    }}
                    onBlur={(e) =>
                      handleAllDropdownValueChangeValidation(
                        e,
                        "selectedPredefinedFilters"
                      )
                    }
                    options={predefinedFilters}
                    optionLabel={"display_name"}
                    className="w-full md:w-20rem"
                    width={"100%"}
                    placeholder={"Advanced Filters"}
                  ></PosDropDown>
                  {errors?.selectedPredefinedFilters?.length > 0 ? (
                    <Text color={Colors.errorColor}>
                      {errors?.selectedPredefinedFilters}
                    </Text>
                  ) : null}
                </Box>
              ) : null}
              {/* </Box> */}
            </Flex>
            {selectedCustomFilters?.length > 0 &&
            selectedFilterType?.code == "CUST" ? (
              <Flex flexDirection="column" gap={2} alignItems="center" mt={3}>
                <Divider mt={1} mb={1} />
                <Box
                  w={"100%"}
                  color={"#010048"}
                  fontSize={"0.94rem"}
                  fontWeight={500}
                >
                  Custom Filter
                </Box>
                <Box w={"100%"}>
                  {selectedCustomFilters.map((item, index) => {
                    return (
                      <Box key={index}>
                        <Flex
                          // key={index}
                          flexDirection={!isMobile ? "row" : "column"} // Change flex direction based on mobile status
                          gap={5}
                          flexShrink={0}
                          p={"2rem"}
                          justifyContent={
                            !isMobile ? "space-between" : "flex-start"
                          } // Set justifyContent based on mobile status
                          // alignItems="center" // Align items vertically centered
                        >
                          <Flex
                            flexDirection={!isMobile ? "row" : "column"}
                            gap={5}
                          >
                            <Text>Where</Text>
                            <Text>{item.display_name}</Text>
                            {item.name == "category" ? (
                              <>
                                <Box>
                                  <PosDropDown
                                    id="selectCatFilter"
                                    value={selectCatFilter}
                                    onChange={(e) => {
                                      setSelectCatFilter(e.value);
                                      setSelectedCategory([]);
                                      handleAllDropdownValueChangeValidation(
                                        e,
                                        "selectCatFilter"
                                      );
                                    }}
                                    onBlur={(e) =>
                                      handleAllDropdownValueChangeValidation(
                                        e,
                                        "selectCatFilter"
                                      )
                                    }
                                    options={categoryFilters}
                                    optionLabel={"display_name"}
                                    className="w-full md:w-14rem"
                                    width={"100%"}
                                    placeholder={"Select a Category Filter"}
                                  ></PosDropDown>
                                  {errors?.selectCatFilter?.length > 0 ? (
                                    <Text color={Colors.errorColor}>
                                      {errors?.selectCatFilter}
                                    </Text>
                                  ) : null}
                                </Box>
                                {null != selectCatFilter &&
                                "" != selectCatFilter ? (
                                  <Box>
                                    <PosDropDown
                                      id="selectedCategory"
                                      options={categories}
                                      value={selectedCategory}
                                      onChange={(e) => {
                                        setSelectedCategory(e.value);
                                        handleAllDropdownValueChangeValidation(
                                          e,
                                          "selectedCategory"
                                        );
                                      }}
                                      onBlur={(e) => {
                                        e.target.value = selectedCategory;
                                        handleAllDropdownValueChangeValidation(
                                          e,
                                          "selectedCategory"
                                        );
                                      }}
                                      multiSelect={true}
                                      optionLabel="name"
                                      placeholder="Select Categories"
                                      lableAvailable={true}
                                      width={"100%"}
                                      height={"2.5rem"}
                                      className="w-full md:w-20rem"
                                      display={"chip"}
                                      maxSelectedLabels={3}
                                    ></PosDropDown>
                                    {errors?.selectedCategory?.length > 0 ? (
                                      <Text color={Colors.errorColor}>
                                        {errors?.selectedCategory}
                                      </Text>
                                    ) : null}
                                  </Box>
                                ) : null}
                              </>
                            ) : item.name == "date" ? (
                              <>
                                <Box>
                                  <PosDropDown
                                    id="selectedPurchaseDate"
                                    value={selectedPurchaseDate}
                                    onChange={(e) => {
                                      setSelectedPurchaseDate(e.value);
                                      setSelectedDateRange([]);
                                      handleAllDropdownValueChangeValidation(
                                        e,
                                        "selectedPurchaseDate"
                                      );
                                    }}
                                    onBlur={(e) =>
                                      handleAllDropdownValueChangeValidation(
                                        e,
                                        "selectedPurchaseDate"
                                      )
                                    }
                                    options={selectedPurchaseDates}
                                    optionLabel={"display_name"}
                                    className="w-full md:w-20rem"
                                    width={"100%"}
                                    placeholder={
                                      "Select a Purchase Date Filter"
                                    }
                                  ></PosDropDown>
                                  {errors?.selectedPurchaseDate?.length > 0 ? (
                                    <Text color={Colors.errorColor}>
                                      {errors?.selectedPurchaseDate}
                                    </Text>
                                  ) : null}
                                </Box>
                                {null != selectedPurchaseDate &&
                                "" != selectedPurchaseDate ? (
                                  <Box>
                                    <PosDropDown
                                      id="selectedDateRange"
                                      value={selectedDateRange}
                                      // onChange={handleDateTypeChange}
                                      onChange={(e) => {
                                        handleDateTypeChange(e);
                                        handleAllDropdownValueChangeValidation(
                                          e,
                                          "selectedDateRange"
                                        );
                                      }}
                                      onBlur={(e) =>
                                        handleAllDropdownValueChangeValidation(
                                          e,
                                          "selectedDateRange"
                                        )
                                      }
                                      options={selectedDateRanges}
                                      optionLabel={"display_name"}
                                      className="w-full md:w-20rem"
                                      width={"100%"}
                                      placeholder={"Select a Date Type"}
                                    ></PosDropDown>
                                    {errors?.selectedDateRange?.length > 0 ? (
                                      <Text color={Colors.errorColor}>
                                        {errors?.selectedDateRange}
                                      </Text>
                                    ) : null}
                                  </Box>
                                ) : null}
                                {null != selectedDateRange &&
                                "" != selectedDateRange &&
                                selectedDateRange.name == "date_range" ? (
                                  <>
                                    {/* <Text>In the past:</Text> */}
                                    <Box>
                                      <PosInput
                                        id="purchaseNumber"
                                        placeholder={"Enter number"}
                                        handleInputChange={
                                          handleAllInputValueChangeValidation
                                        }
                                        handleBlur={
                                          handleAllInputValueChangeValidation
                                        }
                                        inputValue={purchaseNumber || ""}
                                        posNumberInput={true}
                                        inputType={"text"}
                                        darkPlaceholder={true}
                                      />
                                      {errors.purchaseNumber.length > 0 ? (
                                        <Text color={Colors.errorColor}>
                                          {errors.purchaseNumber}
                                        </Text>
                                      ) : null}
                                    </Box>
                                    <Box>
                                      <PosDropDown
                                        id="selectedDate"
                                        value={selectedDate}
                                        onChange={(e) => {
                                          setSelectedDate(e.value);
                                          handleAllDropdownValueChangeValidation(
                                            e,
                                            "selectedDate"
                                          );
                                        }}
                                        onBlur={(e) =>
                                          handleAllDropdownValueChangeValidation(
                                            e,
                                            "selectedDate"
                                          )
                                        }
                                        options={selectedDates}
                                        optionLabel={"display_name"}
                                        className="w-full md:w-20rem"
                                        width={"100%"}
                                        placeholder={"Select Duration"}
                                      ></PosDropDown>
                                      {errors?.selectedDate?.length > 0 ? (
                                        <Text color={Colors.errorColor}>
                                          {errors?.selectedDate}
                                        </Text>
                                      ) : null}
                                    </Box>
                                  </>
                                ) : null != selectedDateRange &&
                                  "" != selectedDateRange &&
                                  selectedDateRange.name == "on_date" ? (
                                  <Box>
                                    <PosDatePicker
                                      id={"start_date"}
                                      ref={calendarRef}
                                      value={onDateView}
                                      label={"On Date"}
                                      onFocus={() => {
                                        // formik.setFieldTouched(
                                        //   "start_date",
                                        //   true
                                        // );
                                      }}
                                      handleSelectData={hadleOnDateSelect}
                                      yearRange={"2000:2030"}
                                      // maxDate={dateTimeValue}
                                      viewDate={onDateView}
                                      showButtonBar={true}
                                    />
                                    {onDateView?.length == 0 ? (
                                      <Text color={Colors.errorColor}>
                                        Please Select on date.
                                      </Text>
                                    ) : null}
                                    {/* <Text color={Colors.errorColor}>
                                  {formik.touched.start_date && formik.errors.start_date ? (
                                    <span>{formik.errors.start_date}</span>
                                  ) : null}
                                </Text> */}
                                  </Box>
                                ) : null != selectedDateRange &&
                                  "" != selectedDateRange &&
                                  selectedDateRange.name == "between_date" ? (
                                  <>
                                    <Box>
                                      <PosDatePicker
                                        id={"fromDate"}
                                        ref={fromCalendarRef}
                                        value={startFromBetweenDate}
                                        label={"From Date"}
                                        onFocus={() => {
                                          // if (
                                          //   null !== toDateCalendarRef.current &&
                                          //   toDateCalendarRef.current
                                          // ) {
                                          //   toDateCalendarRef.current.hide();
                                          // }
                                          // setToDateFocus(false);
                                          // setTimeout(() => {
                                          //   setFromDateFocus(true);
                                          // }, 1000);
                                          // formik.setFieldTouched("fromDate", true);
                                        }}
                                        handleSelectData={hadleStartDateSelect}
                                        yearRange={"2000:2030"}
                                        // maxDate={dateTimeValue}
                                        viewDate={startFromBetweenDate}
                                        showButtonBar={true}
                                        showTime={true}
                                      />
                                      {startFromBetweenDate?.length == 0 ? (
                                        <Text color={Colors.errorColor}>
                                          Please Select from date.
                                        </Text>
                                      ) : null}
                                    </Box>
                                    {/* <Text color={Colors.errorColor}>
                                    {formik.touched.fromDate &&
                                    formik.errors.fromDate ? (
                                      <span>{formik.errors.fromDate}</span>
                                    ) : null}
                                  </Text> */}
                                    <Box>
                                      <PosDatePicker
                                        id={"toDate"}
                                        ref={toCalendarRef}
                                        value={startToBetweenDate}
                                        label={"To Date"}
                                        onFocus={() => {
                                          //   if (
                                          //     null !== calendarRef.current &&
                                          //     calendarRef.current
                                          //   ) {
                                          //     calendarRef.current.hide();
                                          //   }
                                          //   setFromDateFocus(false);
                                          //   setTimeout(() => {
                                          //     setToDateFocus(true);
                                          //   }, 1000);
                                          //   formik.setFieldTouched("toDate", true);
                                        }}
                                        handleSelectData={hadleToDateSelect}
                                        yearRange={"2000:2030"}
                                        // minDate={
                                        //   null !== formik.values.fromDate &&
                                        //   "" !== formik.values.fromDate
                                        //     ? formik.values.fromDate
                                        //     : dateTimeValue
                                        // }
                                        // maxDate={dateTimeValue}
                                        minDate={startFromBetweenDate}
                                        viewDate={startToBetweenDate}
                                        showButtonBar={true}
                                        showTime={true}
                                      />
                                      {startToBetweenDate?.length == 0 ? (
                                        <Text color={Colors.errorColor}>
                                          Please Select to date.
                                        </Text>
                                      ) : null}
                                    </Box>

                                    {/* <Text color={Colors.errorColor}>
                                    {formik.touched.toDate &&
                                    formik.errors.toDate ? (
                                      <span>{formik.errors.toDate}</span>
                                    ) : null}
                                  </Text> */}
                                  </>
                                ) : null}
                              </>
                            ) : item.name == "item" ? (
                              <>
                                <Box>
                                  <PosDropDown
                                    id="selectItemFilter"
                                    value={selectItemFilter}
                                    onChange={(e) => {
                                      setSelectItemFilter(e.value);
                                      setSelectedProducts([]);
                                      handleAllDropdownValueChangeValidation(
                                        e,
                                        "selectItemFilter"
                                      );
                                    }}
                                    onBlur={(e) => {
                                      e.target.value = selectItemFilter;
                                      handleAllDropdownValueChangeValidation(
                                        e,
                                        "selectItemFilter"
                                      );
                                    }}
                                    options={selectItemFilters}
                                    optionLabel={"display_name"}
                                    className="w-full md:w-20rem"
                                    width={"100%"}
                                    placeholder={
                                      "Select an Item Purchase Filter"
                                    }
                                  ></PosDropDown>
                                  {errors?.selectItemFilter?.length > 0 ? (
                                    <Text color={Colors.errorColor}>
                                      {errors?.selectItemFilter}
                                    </Text>
                                  ) : null}
                                </Box>
                                {null != selectItemFilter &&
                                "" != selectItemFilter ? (
                                  <Box>
                                    <AutoComplete
                                      // style={{ background:"red"}}
                                      // inputStyle={{
                                      //   padding: "0%",
                                      //   width: "100%",
                                      //   backgroundColor:
                                      //     "rgba(88, 129, 254, 0.05)",
                                      //   borderRadius: "10px",
                                      //   fontSize: "0.94rem",
                                      //   fontWeight: 300,
                                      //   color: "rgb(1, 0, 72)",
                                      //   height: "2.5rem",
                                      //   border:
                                      //     "1px solid rgba(183, 183, 183, 0.12)",
                                      // }}
                                      // showEmptyMessage="true"
                                      id="selectedProducts"
                                      field="name"
                                      multiple
                                      value={selectedProducts}
                                      suggestions={filteredProducts}
                                      completeMethod={search}
                                      placeholder="Search Multiple Products"
                                      // onChange={(e) =>
                                      //   setSelectedProducts(e.value)
                                      // }
                                      // emptyMessage={"No results found."}
                                      onChange={(e) => {
                                        setSelectedProducts(e.value);
                                        handleAllDropdownValueChangeValidation(
                                          e,
                                          "selectedProducts"
                                        );
                                      }}
                                      onBlur={(e) =>
                                        handleAllDropdownValueChangeValidation(
                                          e,
                                          "selectedProducts"
                                        )
                                      }
                                      dropdown
                                    />
                                    {errors?.selectedProducts?.length > 0 ? (
                                      <Text color={Colors.errorColor}>
                                        {errors?.selectedProducts}
                                      </Text>
                                    ) : null}
                                  </Box>
                                ) : null}
                              </>
                            ) : item.name == "visit" ? (
                              <>
                                <Box>
                                  <PosInput
                                    id="visitNumber"
                                    placeholder={"Enter minimum visits"}
                                    handleInputChange={
                                      handleAllInputValueChangeValidation
                                    }
                                    inputValue={visitNumber || ""}
                                    handleBlur={
                                      handleAllInputValueChangeValidation
                                    }
                                    posNumberInput={true}
                                    inputType={"text"}
                                    darkPlaceholder={true}
                                  />
                                  {errors.visitNumber.length > 0 ? (
                                    <Text color={Colors.errorColor}>
                                      {errors.visitNumber}
                                    </Text>
                                  ) : null}
                                </Box>
                                <Box>
                                  <PosInput
                                    id="lastVisitNumber"
                                    placeholder={"Enter last visit"}
                                    handleInputChange={
                                      handleAllInputValueChangeValidation
                                    }
                                    handleBlur={
                                      handleAllInputValueChangeValidation
                                    }
                                    inputValue={lastVisitNumber || ""}
                                    posNumberInput={true}
                                    inputType={"text"}
                                    darkPlaceholder={true}
                                  />
                                  {errors.lastVisitNumber.length > 0 ? (
                                    <Text color={Colors.errorColor}>
                                      {errors.lastVisitNumber}
                                    </Text>
                                  ) : null}
                                </Box>
                                <Box>
                                  <PosDropDown
                                    id="selectedVisitDate"
                                    value={selectedVisitDate}
                                    onChange={(e) => {
                                      setSelectedVisitDate(e.value);
                                      handleAllDropdownValueChangeValidation(
                                        e,
                                        "selectedVisitDate"
                                      );
                                    }}
                                    onBlur={(e) =>
                                      handleAllDropdownValueChangeValidation(
                                        e,
                                        "selectedVisitDate"
                                      )
                                    }
                                    options={selectedVisitDates}
                                    optionLabel={"display_name"}
                                    className="w-full md:w-20rem"
                                    width={"100%"}
                                    placeholder={"Select Duration"}
                                  ></PosDropDown>
                                  {errors?.selectedVisitDate?.length > 0 ? (
                                    <Text color={Colors.errorColor}>
                                      {errors?.selectedVisitDate}
                                    </Text>
                                  ) : null}
                                </Box>

                                <Text>Ago</Text>
                              </>
                            ) : item.name == "spent" ? (
                              <>
                                <Box>
                                  <PosDropDown
                                    id="selectSpent"
                                    value={selectSpent}
                                    onChange={(e) => {
                                      setSelectSpent(e.value);
                                      setSpentGreaterNumber("");
                                      setSpentLessNumber("");
                                      setSpentStartNumber("");
                                      setSpentEndNumber("");
                                      setSpentEqualNumber("");
                                      handleAllDropdownValueChangeValidation(
                                        e,
                                        "selectSpent"
                                      );
                                    }}
                                    onBlur={(e) =>
                                      handleAllDropdownValueChangeValidation(
                                        e,
                                        "selectSpent"
                                      )
                                    }
                                    options={selectSpentFilters}
                                    optionLabel={"display_name"}
                                    className="w-full md:w-20rem"
                                    width={"100%"}
                                    placeholder={"Select a Spent Filter"}
                                  ></PosDropDown>
                                  {errors?.selectSpent?.length > 0 ? (
                                    <Text color={Colors.errorColor}>
                                      {errors?.selectSpent}
                                    </Text>
                                  ) : null}
                                </Box>
                                {null != selectSpent &&
                                "" != selectSpent &&
                                selectSpent.name == "greater_than" ? (
                                  <Box>
                                    <PosInput
                                      id="spentGreaterNumber"
                                      placeholder={"Enter an Amount"}
                                      inputValue={spentGreaterNumber || ""}
                                      darkPlaceholder={true}
                                      handleInputChange={(e) => {
                                        handleAllInputValueChangeValidation(e);
                                        setSpentGreaterNumber(e.target.value);
                                      }}
                                      handleBlur={(e) => {
                                        handleAllInputValueChangeValidation(e);
                                        setSpentGreaterNumber(
                                          twofix(e.target.value)
                                        );
                                      }}
                                      precision={2}
                                      posNumberInput={true}
                                      inputType={"text"}
                                    />
                                    {errors.spentGreaterNumber.length > 0 ? (
                                      <Text color={Colors.errorColor}>
                                        {errors.spentGreaterNumber}
                                      </Text>
                                    ) : null}
                                  </Box>
                                ) : null != selectSpent &&
                                  "" != selectSpent &&
                                  selectSpent.name == "less_than" ? (
                                  <Box>
                                    <PosInput
                                      id="spentLessNumber"
                                      placeholder={"Enter an Amount"}
                                      // handleInputChange={
                                      //   handleAllInputValueChangeValidation
                                      // }
                                      inputValue={spentLessNumber || ""}
                                      // handleBlur={
                                      //   handleAllInputValueChangeValidation
                                      // }
                                      darkPlaceholder={true}
                                      handleInputChange={(e) => {
                                        handleAllInputValueChangeValidation(e);
                                        setSpentLessNumber(e.target.value);
                                      }}
                                      handleBlur={(e) => {
                                        handleAllInputValueChangeValidation(e);
                                        setSpentLessNumber(
                                          twofix(e.target.value)
                                        );
                                      }}
                                      precision={2}
                                      posNumberInput={true}
                                      inputType={"text"}
                                    />
                                    {errors.spentLessNumber.length > 0 ? (
                                      <Text color={Colors.errorColor}>
                                        {errors.spentLessNumber}
                                      </Text>
                                    ) : null}
                                  </Box>
                                ) : null != selectSpent &&
                                  "" != selectSpent &&
                                  selectSpent.name == "between" ? (
                                  <>
                                    <Box>
                                      <PosInput
                                        id="spentStartNumber"
                                        placeholder={"Enter a Minimum Amount"}
                                        // handleInputChange={
                                        //   handleAllInputValueChangeValidation
                                        // }
                                        inputValue={spentStartNumber || ""}
                                        // handleBlur={
                                        //   handleAllInputValueChangeValidation
                                        // }
                                        darkPlaceholder={true}
                                        handleInputChange={(e) => {
                                          handleAllInputValueChangeValidation(
                                            e
                                          );
                                          setSpentStartNumber(e.target.value);
                                        }}
                                        handleBlur={(e) => {
                                          handleAllInputValueChangeValidation(
                                            e
                                          );
                                          setSpentStartNumber(
                                            twofix(e.target.value)
                                          );
                                        }}
                                        precision={2}
                                        posNumberInput={true}
                                        inputType={"text"}
                                      />
                                      {errors.spentStartNumber.length > 0 ? (
                                        <Text color={Colors.errorColor}>
                                          {errors.spentStartNumber}
                                        </Text>
                                      ) : null}
                                    </Box>
                                    <Text>and</Text>
                                    <Box>
                                      <PosInput
                                        id="spentEndNumber"
                                        placeholder={"Enter a Maximum Amount"}
                                        // handleInputChange={
                                        //   handleAllInputValueChangeValidation
                                        // }
                                        // handleBlur={
                                        //   handleAllInputValueChangeValidation
                                        // }
                                        darkPlaceholder={true}
                                        inputValue={spentEndNumber || ""}
                                        handleInputChange={(e) => {
                                          handleAllInputValueChangeValidation(
                                            e
                                          );
                                          setSpentEndNumber(e.target.value);
                                        }}
                                        handleBlur={(e) => {
                                          handleAllInputValueChangeValidation(
                                            e
                                          );
                                          setSpentEndNumber(
                                            twofix(e.target.value)
                                          );
                                        }}
                                        precision={2}
                                        posNumberInput={true}
                                        inputType={"text"}
                                      />
                                      {errors.spentEndNumber.length > 0 ? (
                                        <Text color={Colors.errorColor}>
                                          {errors.spentEndNumber}
                                        </Text>
                                      ) : null}
                                    </Box>
                                  </>
                                ) : null != selectSpent &&
                                  "" != selectSpent &&
                                  selectSpent.name == "equal_to" ? (
                                  <Box>
                                    <PosInput
                                      id="spentEqualNumber"
                                      placeholder={"Enter an Amount"}
                                      // handleInputChange={
                                      //   handleAllInputValueChangeValidation
                                      // }
                                      // handleBlur={
                                      //   handleAllInputValueChangeValidation
                                      // }
                                      darkPlaceholder={true}
                                      inputValue={spentEqualNumber || ""}
                                      handleInputChange={(e) => {
                                        handleAllInputValueChangeValidation(e);
                                        setSpentEqualNumber(e.target.value);
                                      }}
                                      handleBlur={(e) => {
                                        handleAllInputValueChangeValidation(e);
                                        setSpentEqualNumber(
                                          twofix(e.target.value)
                                        );
                                      }}
                                      precision={2}
                                      posNumberInput={true}
                                      inputType={"text"}
                                    />
                                    {errors.spentEqualNumber.length > 0 ? (
                                      <Text color={Colors.errorColor}>
                                        {errors.spentEqualNumber}
                                      </Text>
                                    ) : null}
                                  </Box>
                                ) : null}
                              </>
                            ) : item.name == "birth" ? (
                              <>
                                <Box>
                                  <PosDatePicker
                                    id={"birthFromDate"}
                                    ref={birthFromCalendarRef}
                                    value={birthStartFromBetweenDate}
                                    label={"From Date"}
                                    onFocus={() => {}}
                                    handleSelectData={hadleBirthStartDateSelect}
                                    // yearRange={"2000:2030"}
                                    viewDate={birthStartFromBetweenDate}
                                    showButtonBar={true}
                                    showTime={false}
                                    view="date"
                                  />
                                  {birthStartFromBetweenDate?.length == 0 ? (
                                    <Text color={Colors.errorColor}>
                                      Please Select from date.
                                    </Text>
                                  ) : null}
                                </Box>
                                <Box>
                                  <PosDatePicker
                                    id={"birthToDate"}
                                    ref={birthToCalendarRef}
                                    value={birthStartToBetweenDate}
                                    label={"To Date"}
                                    onFocus={() => {}}
                                    handleSelectData={hadleBirthToDateSelect}
                                    // yearRange={"2000:2030"}
                                    minDate={birthStartFromBetweenDate}
                                    viewDate={birthStartToBetweenDate}
                                    showButtonBar={true}
                                    showTime={false}
                                    view="date"
                                  />
                                  {birthStartToBetweenDate?.length == 0 ? (
                                    <Text color={Colors.errorColor}>
                                      Please Select to date.
                                    </Text>
                                  ) : null}
                                </Box>
                              </>
                            ) : null}
                          </Flex>
                          <Flex>
                            <DeleteIcon
                              bg={Colors.posCancleButtonRed}
                              onClick={() => handleDeleteFilters(item.name)}
                              sx={{ color: Colors.posCancleButtonRed }}
                              style={
                                {
                                  // display: "flex",
                                  // justifyContent: "center",
                                  // alignItems: "center",
                                  // gap: "0.31rem",
                                  // flexShrink: 0,
                                }
                              }
                            />
                          </Flex>
                        </Flex>

                        <Divider />
                        {/* {index !== selectedCustomFilters.length - 1 && <Divider />} */}
                      </Box>
                    );
                  })}
                </Box>
              </Flex>
            ) : null}
            <Box mt={{ base: 4, lg: 4 }}>
              <PosFormButton
                isDisabled={
                  name?.length <= 0 ||
                  errors?.name?.length > 0 ||
                  selectedLocation?.length == "" ||
                  errors?.selectedLocation?.length > 0 ||
                  selectedSegmentType?.length == "" ||
                  errors?.selectedSegmentType?.length > 0 ||
                  buttonDisable
                    ? true
                    : false
                }
                buttonsubmit={"Show Users"}
                SubmitButton={true}
                onClick={handleShowUsers}
              />
            </Box>
            {showUserTable ? (
              <Box mt={{ base: 4, lg: 4 }}>
                {showTable ? null : loading ? (
                  <PosProgress />
                ) : // (
                totalFilterCount > 0 ? (
                  <Flex direction={"column"} gap={4}>
                    <PosDataGridTable
                      columns={columnNames}
                      rows={modifiedShowUserData}
                      totalcount={totalFilterCount}
                      // noAction={true}
                      columnVisibilityModel={olColumnVisibilityModel}
                      onColumnVisibilityModelChange={(newModel) =>
                        setOlColumnVisibilityModel(newModel)
                      }
                      paginationModel={paginationShowUserModel}
                      paginationMode="server"
                      sortingMode="server"
                      onPaginationModelChange={setPaginationShowUserModel}
                      sortModel={sortShowUserModel}
                      onSortModelChange={(newSortModel) => {
                        sortShowUserRef.current = sortModel;
                        setSortShowUserModel(newSortModel);
                      }}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      fileName={Constants.SEGMENT_USER_EXPORT_FILENAME}
                    />
                  </Flex>
                ) : // )
                null
                //   <PosNoDataFound title={Constants.NO_DATA_FOUND} />
                }
              </Box>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </Flex>
  );
};

export default WithRouter(SegmentCreate);
